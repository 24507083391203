import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import { ObjectContext } from '@prisma-cms/front-editor/lib/components/App/components/public/Connectors/Connector/ListView';
import Icon from "material-ui-icons/Image";
import Typography from 'material-ui/Typography';



class Image extends EditorComponent {


  static defaultProps = {
    ...EditorComponent.defaultProps,
    name: undefined,
    type: 'thumb',
    style: {
      ...EditorComponent.defaultProps.style,
      maxWidth: "100%",
      maxHeight: undefined,
    },
    hide_wrapper_in_default_mode: true,
  }

  static Name = "Image"

  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        <Icon /> Object Image
    </div>);
  }


  getRootElement() {

    return "span";
  }


  renderMainView() {

    const {
      inEditMode,
    } = this.getEditorContext();

    if (!inEditMode) {
      return this.renderChildren();
    }

    // else
    return super.renderMainView();
  }


  renderChildren() {

    const {
      inEditMode,
    } = this.getEditorContext();

    let {
      name,
      ...other
    } = this.getComponentProps(this);


    return <ObjectContext.Consumer
      key="object_context"
    >
      {context => {

        const {
          object,
        } = context;

        if (!object) {
          return null;
        }

        if (!name) {

          if (inEditMode) {
            return <Typography
              color="error"
            >
              name property is required
            </Typography>
          }
          else {
            return null;
          }
        }



        const {
          [name]: src,
          alt,
        } = object;


        /**
          Если нет картинки, то смотрим на текущий режим.
          Если в режиме редактирования работаем, 
         */
        if (!src) {
          return null;
        }


        return <img
          {...other}
          src={src.startsWith('uploads/') ? `/images/middle/${src}` : src}
          alt={alt}
        >
        </img>;

      }}
    </ObjectContext.Consumer>
  }

}

export default Image;
