import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PrismaCmsComponent from '@prisma-cms/component';
import TextField from 'material-ui/TextField';
import Grid from 'material-ui/Grid';
import Button from 'material-ui/Button';
import gql from 'graphql-tag';

class Login extends PrismaCmsComponent {

  // static propTypes = {
  //   // client: PropTypes.object.isRequired,
  // };

  async login() {

    const {
      username,
      password,
    } = this.state;

    const mutation = gql`
      mutation signin(
        $where:UserWhereUniqueInput!
        $data:SigninDataInput!
      ){
        response: signin(
          where:$where
          data:$data
        ){
          success
          message
          errors{
            key
            message
          }
          token
          data{
            ...user
          }
        }
      }
      
      fragment user on User {
        id
        username
        email
        phone
        showEmail
        showPhone
        sudo
        hasEmail
        hasPhone
      }
    `;

    this.mutate({
      mutation,
      variables: {
        where: {
          username: username || "",
        },
        data: {
          password: password || "",
        }
      }
    })
      .then(r => {

        this.onAuth(r);

        return r;
      })
      .catch(console.error);

  }



  onAuth(result) {

    const {
      response,
    } = (result && result.data) || {};

    const {
      success,
      // message,
      // errors: responseErrors,
      token,
      data: user,
    } = response || {};


    if (success && token && user) {

      const {
        onAuthSuccess,
      } = this.context;

      onAuthSuccess({
        token,
        user,
      });

    }

    this.closeForm();

    return result;

  }



  onChange = event => {

    const {
      target: {
        name,
        value,
      },
    } = event;

    this.setState({
      [name]: value,
    });
  }

  render() {

    return super.render(
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >

        <Button
          variant="raised"
          size="large"
          color="primary"
        >
          Клуб 12
        </Button>

        <form
        >

          <div
            style={{
              padding: 4,
              maxWidth: 300,
            }}
          >
            <Grid
              container
              spacing={8}
            >


              <Grid
                item
                xs={12}
              >
                {this.renderField(<TextField
                  fullWidth
                  name="username"
                  label="Имя пользователя"
                  type="text"
                  autoComplete="Логин"
                  margin="normal"
                  onChange={this.onChange}
                />)}

              </Grid>

              <Grid
                item
                xs={12}
              >
                {this.renderField(<TextField
                  fullWidth
                  label="Пароль"
                  type="password"
                  name="password"
                  autoComplete="Пароль"
                  margin="normal"
                  onChange={this.onChange}
                />)}

              </Grid>

              <Grid
                item
                xs
              >
              </Grid>

              <Grid
                item
              >
                <Button
                  type="submit"
                  variant="raised"
                  color="primary"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.login();

                    // name.current.value = password.current.value = '';
                  }}
                >
                  Войти
                </Button>
              </Grid>

            </Grid>
          </div>

        </form>
      </div>
    );
  }
}


export default Login;