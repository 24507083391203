import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import { ConnectorContext } from '@prisma-cms/front-editor/lib/components/App/components/public/Connectors/Connector';
import UsersFilterView from './View';

export class UsersFilter extends EditorComponent {

  static Name = 'UsersFilter';

  static defaultProps = {
    ...EditorComponent.defaultProps,
  }


  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        UsersFilter
      </div>
    );
  }


  getRootElement() {

    return super.getRootElement();
  }


  canBeParent(parent) {

    return super.canBeParent(parent);
  }


  canBeChild(child) {

    return super.canBeChild(child);
  }


  onForwardOnlyChange = (setFilters, filters) => event => {

    const {
      target: {
        checked,
      },
    } = event;

    return setFilters({
      ...filters,
      forward_only: checked ? true : undefined,
    });

  }


  onChangeFilters = (setFilters, filters) => event => {

    const {
      target: {
        name,
        value,
      },
    } = event;

    return setFilters({
      ...filters,
      [name]: value ? value : undefined,
    });

  }


  /**
   * Сброс фильтров
   */
  resetFilters = (setFilters) => () => {

    return setFilters(null);
  }


  renderChildren() {

    // const {
    //   Grid,
    // } = this.context;

    // const {
    // } = this.getEditorContext();

    // const {
    //   ...other
    // } = this.getComponentProps(this);

    const children = super.renderChildren();


    return <ConnectorContext.Consumer>
      {context => {



        const {
          queryName,
          filters,
          setFilters,
        } = context;

        return <UsersFilterView
          queryName={queryName}
          filters={filters}
          setFilters={setFilters}
          onForwardOnlyChange={this.onForwardOnlyChange}
          onChangeFilters={this.onChangeFilters}
          resetFilters={this.resetFilters}
        >
          {children}
        </UsersFilterView>

      }}
    </ConnectorContext.Consumer>;


  }

}

export default UsersFilter;