import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Refetcher extends PureComponent {

  static propTypes = {
    refetch: PropTypes.func.isRequired,
  };


  componentDidMount() {

    this.refetchQueue();

    super.componentDidMount && super.componentDidMount();
  }


  componentWillUnmount() {

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    super.componentWillUnmount && super.componentWillUnmount();
  }


  refetchQueue = () => {

    const {
      refetch,
    } = this.props;

    this.intervalId = setInterval(() => {

      if (refetch) {

        refetch()
          .catch(console.error);

      }

    }, 5000);

  }


  render() {
    return (
      <div>

      </div>
    );
  }
}


export default Refetcher;