import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import Connector from '@prisma-cms/front-editor/lib/components/App/components/public/Connectors/Connector';
import { EditableObjectContext } from '@prisma-cms/front-editor/lib/components/App/context';
import Typography from 'material-ui/Typography';

export class PushNotificationPhonesConnector extends Connector {

  static Name = 'PushNotificationPhonesConnector';

  static defaultProps = {
    ...EditorComponent.defaultProps,
  }


  // renderPanelView(content) {

  //   const {
  //     classes,
  //   } = this.getEditorContext();

  //   return super.renderPanelView(
  //     content ||
  //     <div
  //       className={classes.panelButton}
  //     >
  //       PushNotificationPhonesConnector
  //     </div>
  //   );
  // }


  // getRootElement() {

  //   return super.getRootElement();
  // }


  // canBeParent(parent) {

  //   return super.canBeParent(parent);
  // }


  // canBeChild(child) {

  //   return super.canBeChild(child);
  // }


  getFilters() {

    const {
      name,
      // ...other
    } = this.getComponentProps(this);

    const {
      [name]: values,
    } = this;

    return {
      id_in: values,
    };
  }


  renderChildren() {

    // const {
    // } = this.context;

    const {
      inEditMode: editorInEditMode,
    } = this.getEditorContext();

    const {
      name,
      // ...other
    } = this.getComponentProps(this);

    if (!name) {

      if (editorInEditMode) {
        return <Typography
          color="error"
        >
          name property is required
        </Typography>
      }
      else {
        return null;
      }

    }


    return <EditableObjectContext.Consumer
      key="PushNotificationPhonesConnector"
    >

      {editableObjectContext => {

        const {
          getObjectWithMutations,
        } = editableObjectContext;

        if (!getObjectWithMutations) {
          return null;
        }

        const {
          [name]: values,
        } = getObjectWithMutations();

        if (!values || !values.length) {
          return null;
        }

        Object.assign(this, {
          [name]: values,
        });

        return super.renderChildren();

      }}

    </EditableObjectContext.Consumer>
  }

}

export default PushNotificationPhonesConnector;