import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import { ObjectContext } from '@prisma-cms/front-editor/lib/components/App/components/public/Connectors/Connector/ListView';
import { Link } from 'react-router-dom';

export class K12Phones extends EditorComponent {

  static Name = 'K12Phones';

  static defaultProps = {
    ...EditorComponent.defaultProps,
  }


  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        K12Phones
      </div>
    );
  }


  getRootElement() {

    return super.getRootElement();
  }


  canBeParent(parent) {

    return super.canBeParent(parent);
  }


  canBeChild(child) {

    // return super.canBeChild(child);
    return false;
  }


  renderChildren() {

    // const {
    // } = this.context;

    // const {
    // } = this.getEditorContext();

    // const {
    //   ...other
    // } = this.getComponentProps(this);

    // return super.renderChildren();

    return <ObjectContext.Consumer
      key="K12Phones"
    >
      {objectContext => {

        const {
          object,
        } = objectContext;

        if (!object) {
          return null;
        }

        const {
          k12Phones,
        } = object;

        return k12Phones && k12Phones.length ? k12Phones.map((n, index) => {

          const {
            id: phoneId,
            phone,
          } = n;

          return <span
            key={phoneId || index}
          >
            <Link
              to={`/phones/${phoneId}`}
            >
              {phone}
            </Link>
          </span>

        })
          .reduce((current, next) => [current, ", ", next])
          : null

      }}
    </ObjectContext.Consumer>
  }

}

export default K12Phones;