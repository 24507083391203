// THIS FILE HAS BEEN AUTO-GENERATED BY "graphql-cli-generate-fragments"
// DO NOT EDIT THIS FILE DIRECTLY

export const ArticlePreviousValuesFragment = `fragment ArticlePreviousValues on ArticlePreviousValues {
  id
  name
  description
  content
  preview
  published
  publishedAt
  createdAt
  updatedAt
}
`

export const VerificationSubscriptionPayloadFragment = `fragment VerificationSubscriptionPayload on VerificationSubscriptionPayload {
  mutation
  node {
    ...VerificationNoNesting
  }
  updatedFields
  previousValues {
    ...VerificationPreviousValuesNoNesting
  }
}
`

export const ArticleSubscriptionPayloadFragment = `fragment ArticleSubscriptionPayload on ArticleSubscriptionPayload {
  mutation
  node {
    ...ArticleNoNesting
  }
  updatedFields
  previousValues {
    ...ArticlePreviousValuesNoNesting
  }
}
`

export const ArticleFragment = `fragment Article on Article {
  id
  name
  description
  content
  preview
  segments {
    ...SegmentNoNesting
  }
  published
  publishedAt
  createdAt
  updatedAt
  thumb
}
`

export const BillingPreviousValuesFragment = `fragment BillingPreviousValues on BillingPreviousValues {
  id
  name
  points
  cash
  traffic
  minutes
  sms
  createdAt
  updatedAt
}
`

export const SegmentCriteriaFragment = `fragment SegmentCriteria on SegmentCriteria {
  id
  segment {
    ...SegmentNoNesting
  }
  type {
    ...SegmentCriteriaTypeNoNesting
  }
  from
  to
  partners {
    ...PartnerNoNesting
  }
}
`

export const BillingSubscriptionPayloadFragment = `fragment BillingSubscriptionPayload on BillingSubscriptionPayload {
  mutation
  node {
    ...BillingNoNesting
  }
  updatedFields
  previousValues {
    ...BillingPreviousValuesNoNesting
  }
}
`

export const PartnerFragment = `fragment Partner on Partner {
  id
  name
  logo
  address
  category {
    ...PartnerCategoryNoNesting
  }
  type {
    ...PartnerTypeNoNesting
  }
  promotions {
    ...PromotionNoNesting
  }
  coupons {
    ...CouponNoNesting
  }
  products {
    ...PartnerProductNoNesting
  }
  fns {
    ...PartnerFnNoNesting
  }
  active
  createdAt
  updatedAt
  logoThumb
  typeId
}
`

export const BotChatFragment = `fragment BotChat on BotChat {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  username
  type
  text
  entities
}
`

export const PartnerTypeFragment = `fragment PartnerType on PartnerType {
  id
  name
  daysToConfirm
  active
  createdAt
  updatedAt
}
`

export const BotChatConnectionFragment = `fragment BotChatConnection on BotChatConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...BotChatEdgeNoNesting
  }
  aggregate {
    ...AggregateBotChatNoNesting
  }
}
`

export const PartnerProductFragment = `fragment PartnerProduct on PartnerProduct {
  id
  name
  partner {
    ...PartnerNoNesting
  }
  createdAt
  updatedAt
}
`

export const BotChatEdgeFragment = `fragment BotChatEdge on BotChatEdge {
  node {
    ...BotChatNoNesting
  }
  cursor
}
`

export const PartnerFnFragment = `fragment PartnerFn on PartnerFn {
  id
  name
  partner {
    ...PartnerNoNesting
  }
  createdAt
  updatedAt
  Receipts {
    ...ReceiptNoNesting
  }
}
`

export const BotChatPreviousValuesFragment = `fragment BotChatPreviousValues on BotChatPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  username
  type
  text
  entities
}
`

export const ReceiptParserOperatorFragment = `fragment ReceiptParserOperator on ReceiptParserOperator {
  id
  createdAt
  updatedAt
  name
  code
  Logs {
    ...ReceiptParserLogNoNesting
  }
}
`

export const BotChatSubscriptionPayloadFragment = `fragment BotChatSubscriptionPayload on BotChatSubscriptionPayload {
  mutation
  node {
    ...BotChatNoNesting
  }
  updatedFields
  previousValues {
    ...BotChatPreviousValuesNoNesting
  }
}
`

export const TransactionFragment = `fragment Transaction on Transaction {
  id
  k12Phone {
    ...K12PhoneNoNesting
  }
  receipt {
    ...ReceiptNoNesting
  }
  points
  comment
  status
  createdAt
  updatedAt
  deletedAt
  TransactionPromotion {
    ...TransactionPromotionNoNesting
  }
  partner {
    ...PartnerNoNesting
  }
}
`

export const BotConnectionFragment = `fragment BotConnection on BotConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...BotEdgeNoNesting
  }
  aggregate {
    ...AggregateBotNoNesting
  }
}
`

export const UserFragment = `fragment User on User {
  id
  login
  email
  name
  firstName
  secondName
  lastName
  birthday
  k12Phones {
    ...K12PhoneNoNesting
  }
  pollResults {
    ...PollResultNoNesting
  }
  role
  device {
    ...DeviceNoNesting
  }
  notificationSettings {
    ...NotificationTypeNoNesting
  }
  status
  password
  loginAttempts
  blockedAt
  createdAt
  updatedAt
  deletedAt
  Registrations {
    ...RegistrationNoNesting
  }
  UserLogs {
    ...UserLogNoNesting
  }
  marketplaceToken
  username
  phone
  fullname
  image
  address
  hidden
  sudo
  showEmail
  showPhone
  NotificationTypes {
    ...NotificationTypeNoNesting
  }
  NotificationTypesCreated {
    ...NotificationTypeNoNesting
  }
  Groups {
    ...UserGroupNoNesting
  }
  k12Phone {
    ...K12PhoneNoNesting
  }
  planName
  fullName
  isFirstEntry
  hasEmail
  hasPhone
}
`

export const BotEdgeFragment = `fragment BotEdge on BotEdge {
  node {
    ...BotNoNesting
  }
  cursor
}
`

export const PollFragment = `fragment Poll on Poll {
  id
  name
  description
  content
  points
  leadTime
  secret
  startAt
  endAt
  questions {
    ...PollQuestionNoNesting
  }
  segments {
    ...SegmentNoNesting
  }
  status
  createdAt
  updatedAt
}
`

export const BotMailingMessageConnectionFragment = `fragment BotMailingMessageConnection on BotMailingMessageConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...BotMailingMessageEdgeNoNesting
  }
  aggregate {
    ...AggregateBotMailingMessageNoNesting
  }
}
`

export const PollQuestionItemFragment = `fragment PollQuestionItem on PollQuestionItem {
  id
  question {
    ...PollQuestionNoNesting
  }
  name
}
`

export const BotMailingMessageEdgeFragment = `fragment BotMailingMessageEdge on BotMailingMessageEdge {
  node {
    ...BotMailingMessageNoNesting
  }
  cursor
}
`

export const DeviceFragment = `fragment Device on Device {
  id
  token
  type
  pushErrors
  requests {
    ...RequestNoNesting
  }
  registrations {
    ...RegistrationNoNesting
  }
  createdAt
  updatedAt
  deletedAt
  Verifications {
    ...VerificationNoNesting
  }
  Client {
    ...ForwardClientNoNesting
  }
  Users {
    ...UserNoNesting
  }
  PushNotifications {
    ...PushNotificationNoNesting
  }
  K12Phones {
    ...K12PhoneNoNesting
  }
  request {
    ...RequestNoNesting
  }
  registration {
    ...RegistrationNoNesting
  }
}
`

export const BotMailingMessagePreviousValuesFragment = `fragment BotMailingMessagePreviousValues on BotMailingMessagePreviousValues {
  id
  createdAt
  updatedAt
  message
  sended
  error
}
`

export const PlanFragment = `fragment Plan on Plan {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const BotMailingMessageSubscriptionPayloadFragment = `fragment BotMailingMessageSubscriptionPayload on BotMailingMessageSubscriptionPayload {
  mutation
  node {
    ...BotMailingMessageNoNesting
  }
  updatedFields
  previousValues {
    ...BotMailingMessagePreviousValuesNoNesting
  }
}
`

export const VerificationFragment = `fragment Verification on Verification {
  id
  k12Phone {
    ...K12PhoneNoNesting
  }
  device {
    ...DeviceNoNesting
  }
  hash
  attempts
  refetchAttempts
  createdAt
  updatedAt
  refetchAt
  isVerify
}
`

export const BotMailingPreviousValuesFragment = `fragment BotMailingPreviousValues on BotMailingPreviousValues {
  id
  createdAt
  updatedAt
  name
  message
  status
}
`

export const ForwardClientContractFragment = `fragment ForwardClientContract on ForwardClientContract {
  id
  externalKey
  V_CHECK_CHILD
  signed
  Client {
    ...ForwardClientNoNesting
  }
  Services {
    ...ForwardContractServiceNoNesting
  }
  Offer {
    ...ForwardOfferNoNesting
  }
}
`

export const BotMailingSubscriptionPayloadFragment = `fragment BotMailingSubscriptionPayload on BotMailingSubscriptionPayload {
  mutation
  node {
    ...BotMailingNoNesting
  }
  updatedFields
  previousValues {
    ...BotMailingPreviousValuesNoNesting
  }
}
`

export const ForwardOfferFragment = `fragment ForwardOffer on ForwardOffer {
  id
  createdAt
  updatedAt
  V_PERS_SWITCH
  Contract {
    ...ForwardClientContractNoNesting
  }
}
`

export const BotPreviousValuesFragment = `fragment BotPreviousValues on BotPreviousValues {
  id
  createdAt
  updatedAt
  type
  name
}
`

export const PushNotificationQueueFragment = `fragment PushNotificationQueue on PushNotificationQueue {
  id
  createdAt
  updatedAt
  message
  PushNotifications {
    ...PushNotificationNoNesting
  }
}
`

export const BotProfilePreviousValuesFragment = `fragment BotProfilePreviousValues on BotProfilePreviousValues {
  id
  key
  createdAt
  updatedAt
  type
  login
  firstName
  secondName
  lastName
  email
  birthday
  is_bot
  language_code
}
`

export const UserLogFragment = `fragment UserLog on UserLog {
  id
  user {
    ...UserNoNesting
  }
  action
  value
}
`

export const BotProfileSubscriptionPayloadFragment = `fragment BotProfileSubscriptionPayload on BotProfileSubscriptionPayload {
  mutation
  node {
    ...BotProfileNoNesting
  }
  updatedFields
  previousValues {
    ...BotProfilePreviousValuesNoNesting
  }
}
`

export const ServiceFragment = `fragment Service on Service {
  id
  name
  description
  image
  priceInPoints
  priceInCash
  priceFeePoints
  priceFeeCash
  default
  active
  createdAt
  updatedAt
  thumb
}
`

export const BotSubscriptionPayloadFragment = `fragment BotSubscriptionPayload on BotSubscriptionPayload {
  mutation
  node {
    ...BotNoNesting
  }
  updatedFields
  previousValues {
    ...BotPreviousValuesNoNesting
  }
}
`

export const BotProfileFragment = `fragment BotProfile on BotProfile {
  id
  key
  createdAt
  updatedAt
  type
  login
  firstName
  secondName
  lastName
  email
  birthday
  Bot {
    ...BotNoNesting
  }
  k12Phone {
    ...K12PhoneNoNesting
  }
  Phone {
    ...K12PhoneNoNesting
  }
  is_bot
  language_code
  MailingMessages {
    ...BotMailingMessageNoNesting
  }
  fullname
}
`

export const ClientFragment = `fragment Client on Client {
  externalKey
}
`

export const BotMailingMessageFragment = `fragment BotMailingMessage on BotMailingMessage {
  id
  createdAt
  updatedAt
  message
  BotMailing {
    ...BotMailingNoNesting
  }
  BotProfile {
    ...BotProfileNoNesting
  }
  sended
  error
}
`

export const CouponPreviousValuesFragment = `fragment CouponPreviousValues on CouponPreviousValues {
  id
  createdAt
  updatedAt
  name
  conditions
  description
  content
  url
  preview
  canComplain
  startAt
  endAt
  published
}
`

export const PackageFragment = `fragment Package on Package {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const CouponSubscriptionPayloadFragment = `fragment CouponSubscriptionPayload on CouponSubscriptionPayload {
  mutation
  node {
    ...CouponNoNesting
  }
  updatedFields
  previousValues {
    ...CouponPreviousValuesNoNesting
  }
}
`

export const TransactionPromotionFragment = `fragment TransactionPromotion on TransactionPromotion {
  id
  createdAt
  updatedAt
  amount
  coefficient
  points
  Transaction {
    ...TransactionNoNesting
  }
  Promotion {
    ...PromotionNoNesting
  }
  K12Phone {
    ...K12PhoneNoNesting
  }
  CreatedBy {
    ...UserNoNesting
  }
}
`

export const DeviceConnectionFragment = `fragment DeviceConnection on DeviceConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...DeviceEdgeNoNesting
  }
  aggregate {
    ...AggregateDeviceNoNesting
  }
}
`

export const PageInfoFragment = `fragment PageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
`

export const DeviceEdgeFragment = `fragment DeviceEdge on DeviceEdge {
  node {
    ...DeviceNoNesting
  }
  cursor
}
`

export const AggregateArticleFragment = `fragment AggregateArticle on AggregateArticle {
  count
}
`

export const DevicePreviousValuesFragment = `fragment DevicePreviousValues on DevicePreviousValues {
  id
  token
  type
  pushErrors
  createdAt
  updatedAt
  deletedAt
}
`

export const CouponEdgeFragment = `fragment CouponEdge on CouponEdge {
  node {
    ...CouponNoNesting
  }
  cursor
}
`

export const DeviceSubscriptionPayloadFragment = `fragment DeviceSubscriptionPayload on DeviceSubscriptionPayload {
  mutation
  node {
    ...DeviceNoNesting
  }
  updatedFields
  previousValues {
    ...DevicePreviousValuesNoNesting
  }
}
`

export const PackageConnectionFragment = `fragment PackageConnection on PackageConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PackageEdgeNoNesting
  }
  aggregate {
    ...AggregatePackageNoNesting
  }
}
`

export const FilePreviousValuesFragment = `fragment FilePreviousValues on FilePreviousValues {
  id
  createdAt
  updatedAt
  path
  name
  filename
  mimetype
  encoding
  hash
  size
  rank
}
`

export const AggregatePackageFragment = `fragment AggregatePackage on AggregatePackage {
  count
}
`

export const FileResponseFragment = `fragment FileResponse on FileResponse {
  success
  message
  data {
    ...FileNoNesting
  }
}
`

export const PageConnectionFragment = `fragment PageConnection on PageConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PageEdgeNoNesting
  }
  aggregate {
    ...AggregatePageNoNesting
  }
}
`

export const FileSubscriptionPayloadFragment = `fragment FileSubscriptionPayload on FileSubscriptionPayload {
  mutation
  node {
    ...FileNoNesting
  }
  updatedFields
  previousValues {
    ...FilePreviousValuesNoNesting
  }
}
`

export const AggregatePageFragment = `fragment AggregatePage on AggregatePage {
  count
}
`

export const ForwardClientContractConnectionFragment = `fragment ForwardClientContractConnection on ForwardClientContractConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ForwardClientContractEdgeNoNesting
  }
  aggregate {
    ...AggregateForwardClientContractNoNesting
  }
}
`

export const PartnerEdgeFragment = `fragment PartnerEdge on PartnerEdge {
  node {
    ...PartnerNoNesting
  }
  cursor
}
`

export const ForwardClientContractEdgeFragment = `fragment ForwardClientContractEdge on ForwardClientContractEdge {
  node {
    ...ForwardClientContractNoNesting
  }
  cursor
}
`

export const PartnerTypeConnectionFragment = `fragment PartnerTypeConnection on PartnerTypeConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PartnerTypeEdgeNoNesting
  }
  aggregate {
    ...AggregatePartnerTypeNoNesting
  }
}
`

export const ForwardClientContractPreviousValuesFragment = `fragment ForwardClientContractPreviousValues on ForwardClientContractPreviousValues {
  id
  externalKey
  V_CHECK_CHILD
  signed
}
`

export const AggregatePartnerTypeFragment = `fragment AggregatePartnerType on AggregatePartnerType {
  count
}
`

export const ForwardClientContractSubscriptionPayloadFragment = `fragment ForwardClientContractSubscriptionPayload on ForwardClientContractSubscriptionPayload {
  mutation
  node {
    ...ForwardClientContractNoNesting
  }
  updatedFields
  previousValues {
    ...ForwardClientContractPreviousValuesNoNesting
  }
}
`

export const PromotionEdgeFragment = `fragment PromotionEdge on PromotionEdge {
  node {
    ...PromotionNoNesting
  }
  cursor
}
`

export const ForwardClientPreviousValuesFragment = `fragment ForwardClientPreviousValues on ForwardClientPreviousValues {
  id
  externalKey
  B_CHECK
  newPhotoRequested
  newPhotoRequestedMessage
}
`

export const PushNotificationConnectionFragment = `fragment PushNotificationConnection on PushNotificationConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PushNotificationEdgeNoNesting
  }
  aggregate {
    ...AggregatePushNotificationNoNesting
  }
}
`

export const ForwardClientSubscriptionPayloadFragment = `fragment ForwardClientSubscriptionPayload on ForwardClientSubscriptionPayload {
  mutation
  node {
    ...ForwardClientNoNesting
  }
  updatedFields
  previousValues {
    ...ForwardClientPreviousValuesNoNesting
  }
}
`

export const AggregatePushNotificationFragment = `fragment AggregatePushNotification on AggregatePushNotification {
  count
}
`

export const ForwardContractServiceConnectionFragment = `fragment ForwardContractServiceConnection on ForwardContractServiceConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ForwardContractServiceEdgeNoNesting
  }
  aggregate {
    ...AggregateForwardContractServiceNoNesting
  }
}
`

export const PushNotificationQueueEdgeFragment = `fragment PushNotificationQueueEdge on PushNotificationQueueEdge {
  node {
    ...PushNotificationQueueNoNesting
  }
  cursor
}
`

export const ForwardContractServiceEdgeFragment = `fragment ForwardContractServiceEdge on ForwardContractServiceEdge {
  node {
    ...ForwardContractServiceNoNesting
  }
  cursor
}
`

export const SettingFragment = `fragment Setting on Setting {
  id
  name
  key
  value
  group
}
`

export const ForwardContractServicePreviousValuesFragment = `fragment ForwardContractServicePreviousValues on ForwardContractServicePreviousValues {
  id
  externalKey
}
`

export const SettingConnectionFragment = `fragment SettingConnection on SettingConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SettingEdgeNoNesting
  }
  aggregate {
    ...AggregateSettingNoNesting
  }
}
`

export const ForwardContractServiceSubscriptionPayloadFragment = `fragment ForwardContractServiceSubscriptionPayload on ForwardContractServiceSubscriptionPayload {
  mutation
  node {
    ...ForwardContractServiceNoNesting
  }
  updatedFields
  previousValues {
    ...ForwardContractServicePreviousValuesNoNesting
  }
}
`

export const AggregateSettingFragment = `fragment AggregateSetting on AggregateSetting {
  count
}
`

export const ForwardOfferConnectionFragment = `fragment ForwardOfferConnection on ForwardOfferConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ForwardOfferEdgeNoNesting
  }
  aggregate {
    ...AggregateForwardOfferNoNesting
  }
}
`

export const SurveymonkeySurveyFragment = `fragment SurveymonkeySurvey on SurveymonkeySurvey {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  name
  nickname
  href
  custom_variables
  Collectors {
    ...SurveymonkeyCollectorNoNesting
  }
  Webhooks {
    ...SurveymonkeySurveyWebhookNoNesting
  }
  Responses {
    ...SurveymonkeyResponseNoNesting
  }
  active
  approximate_time
  points
  description
}
`

export const ForwardOfferEdgeFragment = `fragment ForwardOfferEdge on ForwardOfferEdge {
  node {
    ...ForwardOfferNoNesting
  }
  cursor
}
`

export const SurveymonkeyResponseFragment = `fragment SurveymonkeyResponse on SurveymonkeyResponse {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  collector_id
  recipient_id
  total_time
  custom_value
  edit_url
  analyze_url
  ip_address
  custom_variables
  logic_path
  metadata
  response_status
  collection_mode
  href
  respondent_id
  Survey {
    ...SurveymonkeySurveyNoNesting
  }
  Collector {
    ...SurveymonkeyCollectorNoNesting
  }
  SurveymonkeyUser {
    ...SurveymonkeyUserNoNesting
  }
  User {
    ...UserNoNesting
  }
  points
  points_applyed
}
`

export const ForwardOfferPreviousValuesFragment = `fragment ForwardOfferPreviousValues on ForwardOfferPreviousValues {
  id
  createdAt
  updatedAt
  V_PERS_SWITCH
}
`

export const SurveymonkeyCollectorConnectionFragment = `fragment SurveymonkeyCollectorConnection on SurveymonkeyCollectorConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SurveymonkeyCollectorEdgeNoNesting
  }
  aggregate {
    ...AggregateSurveymonkeyCollectorNoNesting
  }
}
`

export const ForwardOfferSubscriptionPayloadFragment = `fragment ForwardOfferSubscriptionPayload on ForwardOfferSubscriptionPayload {
  mutation
  node {
    ...ForwardOfferNoNesting
  }
  updatedFields
  previousValues {
    ...ForwardOfferPreviousValuesNoNesting
  }
}
`

export const AggregateSurveymonkeyCollectorFragment = `fragment AggregateSurveymonkeyCollector on AggregateSurveymonkeyCollector {
  count
}
`

export const K12PhonePreviousValuesFragment = `fragment K12PhonePreviousValues on K12PhonePreviousValues {
  id
  phone
  cash
  points
  freezePoints
  traffic
  minutes
  sms
  activatedAt
  createdAt
  updatedAt
  deletedAt
  promotional
  status
  IMSI
}
`

export const SurveymonkeySurveyEdgeFragment = `fragment SurveymonkeySurveyEdge on SurveymonkeySurveyEdge {
  node {
    ...SurveymonkeySurveyNoNesting
  }
  cursor
}
`

export const K12PhoneSubscriptionPayloadFragment = `fragment K12PhoneSubscriptionPayload on K12PhoneSubscriptionPayload {
  mutation
  node {
    ...K12PhoneNoNesting
  }
  updatedFields
  previousValues {
    ...K12PhonePreviousValuesNoNesting
  }
}
`

export const SurveymonkeyResponseConnectionFragment = `fragment SurveymonkeyResponseConnection on SurveymonkeyResponseConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SurveymonkeyResponseEdgeNoNesting
  }
  aggregate {
    ...AggregateSurveymonkeyResponseNoNesting
  }
}
`

export const LogFragment = `fragment Log on Log {
  id
  createdAt
  updatedAt
  level
  objectType
  message
  stack
}
`

export const AggregateSurveymonkeyResponseFragment = `fragment AggregateSurveymonkeyResponse on AggregateSurveymonkeyResponse {
  count
}
`

export const LogConnectionFragment = `fragment LogConnection on LogConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...LogEdgeNoNesting
  }
  aggregate {
    ...AggregateLogNoNesting
  }
}
`

export const BillingConnectionFragment = `fragment BillingConnection on BillingConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...BillingEdgeNoNesting
  }
  aggregate {
    ...AggregateBillingNoNesting
  }
}
`

export const LogEdgeFragment = `fragment LogEdge on LogEdge {
  node {
    ...LogNoNesting
  }
  cursor
}
`

export const AggregateBillingFragment = `fragment AggregateBilling on AggregateBilling {
  count
}
`

export const LogedInFragment = `fragment LogedIn on LogedIn {
  id
  createdAt
  fake
  User {
    ...UserNoNesting
  }
}
`

export const K12PhoneEdgeFragment = `fragment K12PhoneEdge on K12PhoneEdge {
  node {
    ...K12PhoneNoNesting
  }
  cursor
}
`

export const LogedInConnectionFragment = `fragment LogedInConnection on LogedInConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...LogedInEdgeNoNesting
  }
  aggregate {
    ...AggregateLogedInNoNesting
  }
}
`

export const NewsletterFragment = `fragment Newsletter on Newsletter {
  id
  name
  text
  preview
  segments {
    ...SegmentNoNesting
  }
  status
  startedAt
  createdAt
  updatedAt
}
`

export const LogedInEdgeFragment = `fragment LogedInEdge on LogedInEdge {
  node {
    ...LogedInNoNesting
  }
  cursor
}
`

export const NewsletterEdgeFragment = `fragment NewsletterEdge on NewsletterEdge {
  node {
    ...NewsletterNoNesting
  }
  cursor
}
`

export const LogedInPreviousValuesFragment = `fragment LogedInPreviousValues on LogedInPreviousValues {
  id
  createdAt
  fake
}
`

export const FileFragment = `fragment File on File {
  id
  createdAt
  updatedAt
  path
  name
  filename
  mimetype
  encoding
  CreatedBy {
    ...UserNoNesting
  }
  hash
  size
  rank
}
`

export const LogedInSubscriptionPayloadFragment = `fragment LogedInSubscriptionPayload on LogedInSubscriptionPayload {
  mutation
  node {
    ...LogedInNoNesting
  }
  updatedFields
  previousValues {
    ...LogedInPreviousValuesNoNesting
  }
}
`

export const FileEdgeFragment = `fragment FileEdge on FileEdge {
  node {
    ...FileNoNesting
  }
  cursor
}
`

export const LogPreviousValuesFragment = `fragment LogPreviousValues on LogPreviousValues {
  id
  createdAt
  updatedAt
  level
  objectType
  message
  stack
}
`

export const UserConnectionFragment = `fragment UserConnection on UserConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...UserEdgeNoNesting
  }
  aggregate {
    ...AggregateUserNoNesting
  }
}
`

export const LogSubscriptionPayloadFragment = `fragment LogSubscriptionPayload on LogSubscriptionPayload {
  mutation
  node {
    ...LogNoNesting
  }
  updatedFields
  previousValues {
    ...LogPreviousValuesNoNesting
  }
}
`

export const AggregateUserFragment = `fragment AggregateUser on AggregateUser {
  count
}
`

export const NewsletterJournalFragment = `fragment NewsletterJournal on NewsletterJournal {
  id
  newsletter {
    ...NewsletterNoNesting
  }
  user {
    ...UserNoNesting
  }
  attempts
  lastAttemptAt
  deliveryAt
}
`

export const ProjectFragment = `fragment Project on Project {
  id
  createdAt
  updatedAt
  name
  domain
  description
  url
  PrismaTemplates {
    ...TemplateNoNesting
  }
  PrismaUsers {
    ...UserNoNesting
  }
  CreatedBy {
    ...UserNoNesting
  }
}
`

export const NewsletterJournalConnectionFragment = `fragment NewsletterJournalConnection on NewsletterJournalConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...NewsletterJournalEdgeNoNesting
  }
  aggregate {
    ...AggregateNewsletterJournalNoNesting
  }
}
`

export const TemplateEdgeFragment = `fragment TemplateEdge on TemplateEdge {
  node {
    ...TemplateNoNesting
  }
  cursor
}
`

export const NewsletterJournalEdgeFragment = `fragment NewsletterJournalEdge on NewsletterJournalEdge {
  node {
    ...NewsletterJournalNoNesting
  }
  cursor
}
`

export const BotMailingConnectionFragment = `fragment BotMailingConnection on BotMailingConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...BotMailingEdgeNoNesting
  }
  aggregate {
    ...AggregateBotMailingNoNesting
  }
}
`

export const NewsletterJournalPreviousValuesFragment = `fragment NewsletterJournalPreviousValues on NewsletterJournalPreviousValues {
  id
  attempts
  lastAttemptAt
  deliveryAt
}
`

export const AggregateBotMailingFragment = `fragment AggregateBotMailing on AggregateBotMailing {
  count
}
`

export const NewsletterJournalSubscriptionPayloadFragment = `fragment NewsletterJournalSubscriptionPayload on NewsletterJournalSubscriptionPayload {
  mutation
  node {
    ...NewsletterJournalNoNesting
  }
  updatedFields
  previousValues {
    ...NewsletterJournalPreviousValuesNoNesting
  }
}
`

export const BotProfileEdgeFragment = `fragment BotProfileEdge on BotProfileEdge {
  node {
    ...BotProfileNoNesting
  }
  cursor
}
`

export const NewsletterPreviousValuesFragment = `fragment NewsletterPreviousValues on NewsletterPreviousValues {
  id
  name
  text
  preview
  status
  startedAt
  createdAt
  updatedAt
}
`

export const ChecksInfoFragment = `fragment ChecksInfo on ChecksInfo {
  total
}
`

export const NewsletterSubscriptionPayloadFragment = `fragment NewsletterSubscriptionPayload on NewsletterSubscriptionPayload {
  mutation
  node {
    ...NewsletterNoNesting
  }
  updatedFields
  previousValues {
    ...NewsletterPreviousValuesNoNesting
  }
}
`

export const CheckItemFragment = `fragment CheckItem on CheckItem {
  externalKey
  name
  count
  sum
}
`

export const NotificationTypeConnectionFragment = `fragment NotificationTypeConnection on NotificationTypeConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...NotificationTypeEdgeNoNesting
  }
  aggregate {
    ...AggregateNotificationTypeNoNesting
  }
}
`

export const ForwardClientEdgeFragment = `fragment ForwardClientEdge on ForwardClientEdge {
  node {
    ...ForwardClientNoNesting
  }
  cursor
}
`

export const NotificationTypeEdgeFragment = `fragment NotificationTypeEdge on NotificationTypeEdge {
  node {
    ...NotificationTypeNoNesting
  }
  cursor
}
`

export const TarifPlanFragment = `fragment TarifPlan on TarifPlan {
  ID_TARIFF_PLAN
  V_TARIFF_PLAN_NAME
  V_TARIFF_PLAN_DESCR
}
`

export const NotificationTypePreviousValuesFragment = `fragment NotificationTypePreviousValues on NotificationTypePreviousValues {
  id
  name
}
`

export const TarifInfoServiceFragment = `fragment TarifInfoService on TarifInfoService {
  ID_ADD_SRV
  V_ADD_SRV_NAME
  N_ADD_SRV_PRICE_RUB
  N_ADD_SRV_PRICE_BAL
  B_ADD_SRV_CONNECT
}
`

export const NotificationTypeSubscriptionPayloadFragment = `fragment NotificationTypeSubscriptionPayload on NotificationTypeSubscriptionPayload {
  mutation
  node {
    ...NotificationTypeNoNesting
  }
  updatedFields
  previousValues {
    ...NotificationTypePreviousValuesNoNesting
  }
}
`

export const SurveymonkeyResponseResponseFragment = `fragment SurveymonkeyResponseResponse on SurveymonkeyResponseResponse {
  success
  message
  data {
    ...SurveymonkeyResponseNoNesting
  }
}
`

export const PackagePreviousValuesFragment = `fragment PackagePreviousValues on PackagePreviousValues {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const SurveymonkeySurveyResponseFragment = `fragment SurveymonkeySurveyResponse on SurveymonkeySurveyResponse {
  success
  message
  data {
    ...SurveymonkeySurveyNoNesting
  }
}
`

export const PackageSubscriptionPayloadFragment = `fragment PackageSubscriptionPayload on PackageSubscriptionPayload {
  mutation
  node {
    ...PackageNoNesting
  }
  updatedFields
  previousValues {
    ...PackagePreviousValuesNoNesting
  }
}
`

export const ArticleResponseFragment = `fragment ArticleResponse on ArticleResponse {
  success
  message
  data {
    ...ArticleNoNesting
  }
}
`

export const PagePreviousValuesFragment = `fragment PagePreviousValues on PagePreviousValues {
  id
  key
  title
  content
}
`

export const PageSubscriptionPayloadFragment = `fragment PageSubscriptionPayload on PageSubscriptionPayload {
  mutation
  node {
    ...PageNoNesting
  }
  updatedFields
  previousValues {
    ...PagePreviousValuesNoNesting
  }
}
`

export const PageResponseFragment = `fragment PageResponse on PageResponse {
  success
  message
  data {
    ...PageNoNesting
  }
}
`

export const PartnerCategoryConnectionFragment = `fragment PartnerCategoryConnection on PartnerCategoryConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PartnerCategoryEdgeNoNesting
  }
  aggregate {
    ...AggregatePartnerCategoryNoNesting
  }
}
`

export const PartnerFnResponseFragment = `fragment PartnerFnResponse on PartnerFnResponse {
  success
  message
  data {
    ...PartnerFnNoNesting
  }
}
`

export const PartnerCategoryEdgeFragment = `fragment PartnerCategoryEdge on PartnerCategoryEdge {
  node {
    ...PartnerCategoryNoNesting
  }
  cursor
}
`

export const PartnerTypeResponseFragment = `fragment PartnerTypeResponse on PartnerTypeResponse {
  success
  message
  data {
    ...PartnerTypeNoNesting
  }
}
`

export const PartnerCategoryPreviousValuesFragment = `fragment PartnerCategoryPreviousValues on PartnerCategoryPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const PushNotificationQueueResponseFragment = `fragment PushNotificationQueueResponse on PushNotificationQueueResponse {
  success
  message
  data {
    ...PushNotificationQueueNoNesting
  }
}
`

export const PartnerCategorySubscriptionPayloadFragment = `fragment PartnerCategorySubscriptionPayload on PartnerCategorySubscriptionPayload {
  mutation
  node {
    ...PartnerCategoryNoNesting
  }
  updatedFields
  previousValues {
    ...PartnerCategoryPreviousValuesNoNesting
  }
}
`

export const SBOrderFragment = `fragment SBOrder on SBOrder {
  id
  createdAt
  updatedAt
  externalKey
  number
  orderStatus
  amount
  currency
  date
  paymentAmountInfo
  Payment {
    ...PaymentNoNesting
  }
}
`

export const PartnerFnConnectionFragment = `fragment PartnerFnConnection on PartnerFnConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PartnerFnEdgeNoNesting
  }
  aggregate {
    ...AggregatePartnerFnNoNesting
  }
}
`

export const SettingResponseFragment = `fragment SettingResponse on SettingResponse {
  success
  message
  data {
    ...SettingNoNesting
  }
}
`

export const PartnerFnEdgeFragment = `fragment PartnerFnEdge on PartnerFnEdge {
  node {
    ...PartnerFnNoNesting
  }
  cursor
}
`

export const AuthPayloadFragment = `fragment AuthPayload on AuthPayload {
  token
}
`

export const PartnerFnPreviousValuesFragment = `fragment PartnerFnPreviousValues on PartnerFnPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const PassportDataFragment = `fragment PassportData on PassportData {
  LastName
  FirstName
  MiddleName
  Sex
  DateOfBirth
  PlaceOfBirth
  IssuedBy
  DateOfIssue
  DepartmentCode
  Series
  Number
}
`

export const PartnerFnSubscriptionPayloadFragment = `fragment PartnerFnSubscriptionPayload on PartnerFnSubscriptionPayload {
  mutation
  node {
    ...PartnerFnNoNesting
  }
  updatedFields
  previousValues {
    ...PartnerFnPreviousValuesNoNesting
  }
}
`

export const BatchPayloadFragment = `fragment BatchPayload on BatchPayload {
  count
}
`

export const PartnerPreviousValuesFragment = `fragment PartnerPreviousValues on PartnerPreviousValues {
  id
  name
  logo
  address
  active
  createdAt
  updatedAt
}
`

export const TransactionResponseFragment = `fragment TransactionResponse on TransactionResponse {
  success
  message
  data {
    ...TransactionNoNesting
  }
}
`

export const PartnerProductConnectionFragment = `fragment PartnerProductConnection on PartnerProductConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PartnerProductEdgeNoNesting
  }
  aggregate {
    ...AggregatePartnerProductNoNesting
  }
}
`

export const SigninPayloadFragment = `fragment SigninPayload on SigninPayload {
  success
  message
  token
  data {
    ...UserNoNesting
  }
}
`

export const PartnerProductEdgeFragment = `fragment PartnerProductEdge on PartnerProductEdge {
  node {
    ...PartnerProductNoNesting
  }
  cursor
}
`

export const TemplateResponseFragment = `fragment TemplateResponse on TemplateResponse {
  success
  message
  data {
    ...TemplateNoNesting
  }
}
`

export const PartnerProductPreviousValuesFragment = `fragment PartnerProductPreviousValues on PartnerProductPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const ClientResponseFragment = `fragment ClientResponse on ClientResponse {
  success
  message
  data {
    ...ForwardClientNoNesting
  }
}
`

export const PartnerProductSubscriptionPayloadFragment = `fragment PartnerProductSubscriptionPayload on PartnerProductSubscriptionPayload {
  mutation
  node {
    ...PartnerProductNoNesting
  }
  updatedFields
  previousValues {
    ...PartnerProductPreviousValuesNoNesting
  }
}
`

export const ActivateContractResponseFragment = `fragment ActivateContractResponse on ActivateContractResponse {
  phone
}
`

export const PartnerSubscriptionPayloadFragment = `fragment PartnerSubscriptionPayload on PartnerSubscriptionPayload {
  mutation
  node {
    ...PartnerNoNesting
  }
  updatedFields
  previousValues {
    ...PartnerPreviousValuesNoNesting
  }
}
`

export const UserSubscriptionPayloadFragment = `fragment UserSubscriptionPayload on UserSubscriptionPayload {
  mutation
  node {
    ...UserNoNesting
  }
  updatedFields
  previousValues {
    ...UserPreviousValuesNoNesting
  }
}
`

export const PartnerTypePreviousValuesFragment = `fragment PartnerTypePreviousValues on PartnerTypePreviousValues {
  id
  name
  daysToConfirm
  active
  createdAt
  updatedAt
}
`

export const TemplateSubscriptionPayloadFragment = `fragment TemplateSubscriptionPayload on TemplateSubscriptionPayload {
  mutation
  node {
    ...TemplateNoNesting
  }
  updatedFields
  previousValues {
    ...TemplatePreviousValuesNoNesting
  }
}
`

export const PartnerTypeSubscriptionPayloadFragment = `fragment PartnerTypeSubscriptionPayload on PartnerTypeSubscriptionPayload {
  mutation
  node {
    ...PartnerTypeNoNesting
  }
  updatedFields
  previousValues {
    ...PartnerTypePreviousValuesNoNesting
  }
}
`

export const AggregateBotFragment = `fragment AggregateBot on AggregateBot {
  count
}
`

export const PaymentConnectionFragment = `fragment PaymentConnection on PaymentConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PaymentEdgeNoNesting
  }
  aggregate {
    ...AggregatePaymentNoNesting
  }
}
`

export const AggregateBotMailingMessageFragment = `fragment AggregateBotMailingMessage on AggregateBotMailingMessage {
  count
}
`

export const PaymentEdgeFragment = `fragment PaymentEdge on PaymentEdge {
  node {
    ...PaymentNoNesting
  }
  cursor
}
`

export const AggregateForwardClientContractFragment = `fragment AggregateForwardClientContract on AggregateForwardClientContract {
  count
}
`

export const PaymentPreviousValuesFragment = `fragment PaymentPreviousValues on PaymentPreviousValues {
  id
  createdAt
  updatedAt
  amount
  status
}
`

export const AggregateForwardOfferFragment = `fragment AggregateForwardOffer on AggregateForwardOffer {
  count
}
`

export const PaymentSubscriptionPayloadFragment = `fragment PaymentSubscriptionPayload on PaymentSubscriptionPayload {
  mutation
  node {
    ...PaymentNoNesting
  }
  updatedFields
  previousValues {
    ...PaymentPreviousValuesNoNesting
  }
}
`

export const AggregateLogedInFragment = `fragment AggregateLogedIn on AggregateLogedIn {
  count
}
`

export const PlanConnectionFragment = `fragment PlanConnection on PlanConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PlanEdgeNoNesting
  }
  aggregate {
    ...AggregatePlanNoNesting
  }
}
`

export const AggregateNotificationTypeFragment = `fragment AggregateNotificationType on AggregateNotificationType {
  count
}
`

export const PlanEdgeFragment = `fragment PlanEdge on PlanEdge {
  node {
    ...PlanNoNesting
  }
  cursor
}
`

export const AggregatePartnerFnFragment = `fragment AggregatePartnerFn on AggregatePartnerFn {
  count
}
`

export const PlanPreviousValuesFragment = `fragment PlanPreviousValues on PlanPreviousValues {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const AggregatePaymentFragment = `fragment AggregatePayment on AggregatePayment {
  count
}
`

export const PlanSubscriptionPayloadFragment = `fragment PlanSubscriptionPayload on PlanSubscriptionPayload {
  mutation
  node {
    ...PlanNoNesting
  }
  updatedFields
  previousValues {
    ...PlanPreviousValuesNoNesting
  }
}
`

export const AggregatePollFragment = `fragment AggregatePoll on AggregatePoll {
  count
}
`

export const PollConnectionFragment = `fragment PollConnection on PollConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PollEdgeNoNesting
  }
  aggregate {
    ...AggregatePollNoNesting
  }
}
`

export const AggregatePollQuestionItemFragment = `fragment AggregatePollQuestionItem on AggregatePollQuestionItem {
  count
}
`

export const PollEdgeFragment = `fragment PollEdge on PollEdge {
  node {
    ...PollNoNesting
  }
  cursor
}
`

export const AggregatePollResultAnswerFragment = `fragment AggregatePollResultAnswer on AggregatePollResultAnswer {
  count
}
`

export const PollPreviousValuesFragment = `fragment PollPreviousValues on PollPreviousValues {
  id
  name
  description
  content
  points
  leadTime
  secret
  startAt
  endAt
  status
  createdAt
  updatedAt
}
`

export const AggregateReceiptFragment = `fragment AggregateReceipt on AggregateReceipt {
  count
}
`

export const PollQuestionConnectionFragment = `fragment PollQuestionConnection on PollQuestionConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PollQuestionEdgeNoNesting
  }
  aggregate {
    ...AggregatePollQuestionNoNesting
  }
}
`

export const AggregateReceiptParserOperatorFragment = `fragment AggregateReceiptParserOperator on AggregateReceiptParserOperator {
  count
}
`

export const PollQuestionEdgeFragment = `fragment PollQuestionEdge on PollQuestionEdge {
  node {
    ...PollQuestionNoNesting
  }
  cursor
}
`

export const AggregateRegistrationFragment = `fragment AggregateRegistration on AggregateRegistration {
  count
}
`

export const PollQuestionItemConnectionFragment = `fragment PollQuestionItemConnection on PollQuestionItemConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PollQuestionItemEdgeNoNesting
  }
  aggregate {
    ...AggregatePollQuestionItemNoNesting
  }
}
`

export const AggregateResetPasswordFragment = `fragment AggregateResetPassword on AggregateResetPassword {
  count
}
`

export const PollQuestionItemEdgeFragment = `fragment PollQuestionItemEdge on PollQuestionItemEdge {
  node {
    ...PollQuestionItemNoNesting
  }
  cursor
}
`

export const AggregateSegmentFragment = `fragment AggregateSegment on AggregateSegment {
  count
}
`

export const PollQuestionItemPreviousValuesFragment = `fragment PollQuestionItemPreviousValues on PollQuestionItemPreviousValues {
  id
  name
}
`

export const AggregateSegmentCriteriaTypeFragment = `fragment AggregateSegmentCriteriaType on AggregateSegmentCriteriaType {
  count
}
`

export const PollQuestionItemSubscriptionPayloadFragment = `fragment PollQuestionItemSubscriptionPayload on PollQuestionItemSubscriptionPayload {
  mutation
  node {
    ...PollQuestionItemNoNesting
  }
  updatedFields
  previousValues {
    ...PollQuestionItemPreviousValuesNoNesting
  }
}
`

export const AggregateSurveymonkeySurveyWebhookFragment = `fragment AggregateSurveymonkeySurveyWebhook on AggregateSurveymonkeySurveyWebhook {
  count
}
`

export const PollQuestionPreviousValuesFragment = `fragment PollQuestionPreviousValues on PollQuestionPreviousValues {
  id
  name
  type
}
`

export const AggregateTransactionFragment = `fragment AggregateTransaction on AggregateTransaction {
  count
}
`

export const PollQuestionSubscriptionPayloadFragment = `fragment PollQuestionSubscriptionPayload on PollQuestionSubscriptionPayload {
  mutation
  node {
    ...PollQuestionNoNesting
  }
  updatedFields
  previousValues {
    ...PollQuestionPreviousValuesNoNesting
  }
}
`

export const AggregateUserGroupFragment = `fragment AggregateUserGroup on AggregateUserGroup {
  count
}
`

export const PollResultAnswerConnectionFragment = `fragment PollResultAnswerConnection on PollResultAnswerConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PollResultAnswerEdgeNoNesting
  }
  aggregate {
    ...AggregatePollResultAnswerNoNesting
  }
}
`

export const AggregateVerificationFragment = `fragment AggregateVerification on AggregateVerification {
  count
}
`

export const PollResultAnswerEdgeFragment = `fragment PollResultAnswerEdge on PollResultAnswerEdge {
  node {
    ...PollResultAnswerNoNesting
  }
  cursor
}
`

export const SegmentCriteriaTypeFragment = `fragment SegmentCriteriaType on SegmentCriteriaType {
  id
  name
  isBetween
  isAttachPartners
}
`

export const PollResultAnswerPreviousValuesFragment = `fragment PollResultAnswerPreviousValues on PollResultAnswerPreviousValues {
  id
}
`

export const PromotionFragment = `fragment Promotion on Promotion {
  id
  name
  description
  partner {
    ...PartnerNoNesting
  }
  preview
  text
  bonus
  checkInText
  checkInPoints
  maxProducts
  active
  startAt
  endAt
  products {
    ...PartnerProductNoNesting
  }
  createdAt
  updatedAt
  type
  partnerName
  partnerId
  thumb
}
`

export const PollResultAnswerSubscriptionPayloadFragment = `fragment PollResultAnswerSubscriptionPayload on PollResultAnswerSubscriptionPayload {
  mutation
  node {
    ...PollResultAnswerNoNesting
  }
  updatedFields
  previousValues {
    ...PollResultAnswerPreviousValuesNoNesting
  }
}
`

export const ReceiptFragment = `fragment Receipt on Receipt {
  id
  partnerFn {
    ...PartnerFnNoNesting
  }
  hash
  photo
  s
  fn
  i
  fp
  t
  n
  ParserOperator {
    ...ReceiptParserOperatorNoNesting
  }
  ParserLogs {
    ...ReceiptParserLogNoNesting
  }
  parserAttempts
  parserStatus
  parserData
  createdAt
  updatedAt
  Transaction {
    ...TransactionNoNesting
  }
  externalKey
}
`

export const PollResultConnectionFragment = `fragment PollResultConnection on PollResultConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PollResultEdgeNoNesting
  }
  aggregate {
    ...AggregatePollResultNoNesting
  }
}
`

export const K12PhoneFragment = `fragment K12Phone on K12Phone {
  id
  phone
  cash
  points
  freezePoints
  traffic
  minutes
  sms
  activatedAt
  createdAt
  updatedAt
  deletedAt
  promotional
  user {
    ...UserNoNesting
  }
  status
  services {
    ...ServiceNoNesting
  }
  Verifications {
    ...VerificationNoNesting
  }
  Registrations {
    ...RegistrationNoNesting
  }
  Billings {
    ...BillingNoNesting
  }
  BotProfiles {
    ...BotProfileNoNesting
  }
  IMSI
  ForwardClient {
    ...ForwardClientNoNesting
  }
  Device {
    ...DeviceNoNesting
  }
  PushNotifications {
    ...PushNotificationNoNesting
  }
  allPoints
  plan {
    ...PlanNoNesting
  }
  ID_CLIENT_INST
  ID_CONTRACT_INST
  ID_SERVICE_INST
}
`

export const PollResultEdgeFragment = `fragment PollResultEdge on PollResultEdge {
  node {
    ...PollResultNoNesting
  }
  cursor
}
`

export const PollQuestionFragment = `fragment PollQuestion on PollQuestion {
  id
  name
  type
  items {
    ...PollQuestionItemNoNesting
  }
}
`

export const PollResultPreviousValuesFragment = `fragment PollResultPreviousValues on PollResultPreviousValues {
  id
  startedAt
  endedAt
  status
  createdAt
  updatedAt
}
`

export const RequestFragment = `fragment Request on Request {
  id
  name
  phone
  device {
    ...DeviceNoNesting
  }
  plan {
    ...PlanNoNesting
  }
  status
  createdAt
  updatedAt
  deletedAt
}
`

export const PollResultSubscriptionPayloadFragment = `fragment PollResultSubscriptionPayload on PollResultSubscriptionPayload {
  mutation
  node {
    ...PollResultNoNesting
  }
  updatedFields
  previousValues {
    ...PollResultPreviousValuesNoNesting
  }
}
`

export const ForwardClientFragment = `fragment ForwardClient on ForwardClient {
  id
  externalKey
  B_CHECK
  newPhotoRequested
  newPhotoRequestedMessage
  K12Phone {
    ...K12PhoneNoNesting
  }
  Contract {
    ...ForwardClientContractNoNesting
  }
  Device {
    ...DeviceNoNesting
  }
}
`

export const PollSubscriptionPayloadFragment = `fragment PollSubscriptionPayload on PollSubscriptionPayload {
  mutation
  node {
    ...PollNoNesting
  }
  updatedFields
  previousValues {
    ...PollPreviousValuesNoNesting
  }
}
`

export const PushNotificationFragment = `fragment PushNotification on PushNotification {
  id
  createdAt
  updatedAt
  ticketId
  message
  hasErrors
  ticketError
  receiptError
  Device {
    ...DeviceNoNesting
  }
  K12Phone {
    ...K12PhoneNoNesting
  }
  PushNotificationQueue {
    ...PushNotificationQueueNoNesting
  }
}
`

export const ProjectConnectionFragment = `fragment ProjectConnection on ProjectConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ProjectEdgeNoNesting
  }
  aggregate {
    ...AggregateProjectNoNesting
  }
}
`

export const UserGroupFragment = `fragment UserGroup on UserGroup {
  id
  createdAt
  updatedAt
  name
  Users {
    ...UserNoNesting
  }
}
`

export const ProjectEdgeFragment = `fragment ProjectEdge on ProjectEdge {
  node {
    ...ProjectNoNesting
  }
  cursor
}
`

export const BotFragment = `fragment Bot on Bot {
  id
  createdAt
  updatedAt
  type
  name
  Profiles {
    ...BotProfileNoNesting
  }
}
`

export const ProjectPreviousValuesFragment = `fragment ProjectPreviousValues on ProjectPreviousValues {
  id
  createdAt
  updatedAt
  name
  domain
  description
  url
}
`

export const CheckInfoFragment = `fragment CheckInfo on CheckInfo {
  externalKey
  sum
  status
  points
}
`

export const ProjectSubscriptionPayloadFragment = `fragment ProjectSubscriptionPayload on ProjectSubscriptionPayload {
  mutation
  node {
    ...ProjectNoNesting
  }
  updatedFields
  previousValues {
    ...ProjectPreviousValuesNoNesting
  }
}
`

export const ArticleEdgeFragment = `fragment ArticleEdge on ArticleEdge {
  node {
    ...ArticleNoNesting
  }
  cursor
}
`

export const PromotionPreviousValuesFragment = `fragment PromotionPreviousValues on PromotionPreviousValues {
  id
  name
  description
  preview
  text
  bonus
  checkInText
  checkInPoints
  maxProducts
  active
  startAt
  endAt
  createdAt
  updatedAt
}
`

export const AggregateCouponFragment = `fragment AggregateCoupon on AggregateCoupon {
  count
}
`

export const PromotionSubscriptionPayloadFragment = `fragment PromotionSubscriptionPayload on PromotionSubscriptionPayload {
  mutation
  node {
    ...PromotionNoNesting
  }
  updatedFields
  previousValues {
    ...PromotionPreviousValuesNoNesting
  }
}
`

export const PageFragment = `fragment Page on Page {
  id
  key
  title
  content
}
`

export const PushNotificationPreviousValuesFragment = `fragment PushNotificationPreviousValues on PushNotificationPreviousValues {
  id
  createdAt
  updatedAt
  ticketId
  message
  hasErrors
  ticketError
  receiptError
}
`

export const PartnerConnectionFragment = `fragment PartnerConnection on PartnerConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PartnerEdgeNoNesting
  }
  aggregate {
    ...AggregatePartnerNoNesting
  }
}
`

export const PushNotificationQueuePreviousValuesFragment = `fragment PushNotificationQueuePreviousValues on PushNotificationQueuePreviousValues {
  id
  createdAt
  updatedAt
  message
}
`

export const PartnerTypeEdgeFragment = `fragment PartnerTypeEdge on PartnerTypeEdge {
  node {
    ...PartnerTypeNoNesting
  }
  cursor
}
`

export const PushNotificationQueueSubscriptionPayloadFragment = `fragment PushNotificationQueueSubscriptionPayload on PushNotificationQueueSubscriptionPayload {
  mutation
  node {
    ...PushNotificationQueueNoNesting
  }
  updatedFields
  previousValues {
    ...PushNotificationQueuePreviousValuesNoNesting
  }
}
`

export const AggregatePromotionFragment = `fragment AggregatePromotion on AggregatePromotion {
  count
}
`

export const PushNotificationSubscriptionPayloadFragment = `fragment PushNotificationSubscriptionPayload on PushNotificationSubscriptionPayload {
  mutation
  node {
    ...PushNotificationNoNesting
  }
  updatedFields
  previousValues {
    ...PushNotificationPreviousValuesNoNesting
  }
}
`

export const PushNotificationQueueConnectionFragment = `fragment PushNotificationQueueConnection on PushNotificationQueueConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PushNotificationQueueEdgeNoNesting
  }
  aggregate {
    ...AggregatePushNotificationQueueNoNesting
  }
}
`

export const ReceiptConnectionFragment = `fragment ReceiptConnection on ReceiptConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ReceiptEdgeNoNesting
  }
  aggregate {
    ...AggregateReceiptNoNesting
  }
}
`

export const SettingsFragment = `fragment Settings on Settings {
  supportPhone
  livechatLicense
  telegram
  facebook
  viber
  politics
  rules
  siteUrl
  botGreetingsMessage
  fnsAttempts
  fnsDelay
  ofdAttempts
  ofdDelay
}
`

export const ReceiptEdgeFragment = `fragment ReceiptEdge on ReceiptEdge {
  node {
    ...ReceiptNoNesting
  }
  cursor
}
`

export const SurveymonkeyCollectorFragment = `fragment SurveymonkeyCollector on SurveymonkeyCollector {
  id
  createdAt
  updatedAt
  externalKey
  name
  hash
  url
  href
  status
  type
  Survey {
    ...SurveymonkeySurveyNoNesting
  }
  Responses {
    ...SurveymonkeyResponseNoNesting
  }
}
`

export const ReceiptParserLogConnectionFragment = `fragment ReceiptParserLogConnection on ReceiptParserLogConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ReceiptParserLogEdgeNoNesting
  }
  aggregate {
    ...AggregateReceiptParserLogNoNesting
  }
}
`

export const SurveymonkeyUserFragment = `fragment SurveymonkeyUser on SurveymonkeyUser {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  email_address
  Responses {
    ...SurveymonkeyResponseNoNesting
  }
}
`

export const ReceiptParserLogEdgeFragment = `fragment ReceiptParserLogEdge on ReceiptParserLogEdge {
  node {
    ...ReceiptParserLogNoNesting
  }
  cursor
}
`

export const SurveymonkeySurveyConnectionFragment = `fragment SurveymonkeySurveyConnection on SurveymonkeySurveyConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SurveymonkeySurveyEdgeNoNesting
  }
  aggregate {
    ...AggregateSurveymonkeySurveyNoNesting
  }
}
`

export const ReceiptParserLogPreviousValuesFragment = `fragment ReceiptParserLogPreviousValues on ReceiptParserLogPreviousValues {
  id
  createdAt
}
`

export const SurveymonkeyResponseEdgeFragment = `fragment SurveymonkeyResponseEdge on SurveymonkeyResponseEdge {
  node {
    ...SurveymonkeyResponseNoNesting
  }
  cursor
}
`

export const ReceiptParserLogSubscriptionPayloadFragment = `fragment ReceiptParserLogSubscriptionPayload on ReceiptParserLogSubscriptionPayload {
  mutation
  node {
    ...ReceiptParserLogNoNesting
  }
  updatedFields
  previousValues {
    ...ReceiptParserLogPreviousValuesNoNesting
  }
}
`

export const BillingEdgeFragment = `fragment BillingEdge on BillingEdge {
  node {
    ...BillingNoNesting
  }
  cursor
}
`

export const ReceiptParserOperatorConnectionFragment = `fragment ReceiptParserOperatorConnection on ReceiptParserOperatorConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ReceiptParserOperatorEdgeNoNesting
  }
  aggregate {
    ...AggregateReceiptParserOperatorNoNesting
  }
}
`

export const AggregateK12PhoneFragment = `fragment AggregateK12Phone on AggregateK12Phone {
  count
}
`

export const ReceiptParserOperatorEdgeFragment = `fragment ReceiptParserOperatorEdge on ReceiptParserOperatorEdge {
  node {
    ...ReceiptParserOperatorNoNesting
  }
  cursor
}
`

export const AggregateNewsletterFragment = `fragment AggregateNewsletter on AggregateNewsletter {
  count
}
`

export const ReceiptParserOperatorPreviousValuesFragment = `fragment ReceiptParserOperatorPreviousValues on ReceiptParserOperatorPreviousValues {
  id
  createdAt
  updatedAt
  name
  code
}
`

export const AggregateFileFragment = `fragment AggregateFile on AggregateFile {
  count
}
`

export const ReceiptParserOperatorSubscriptionPayloadFragment = `fragment ReceiptParserOperatorSubscriptionPayload on ReceiptParserOperatorSubscriptionPayload {
  mutation
  node {
    ...ReceiptParserOperatorNoNesting
  }
  updatedFields
  previousValues {
    ...ReceiptParserOperatorPreviousValuesNoNesting
  }
}
`

export const TemplateFragment = `fragment Template on Template {
  id
  createdAt
  updatedAt
  externalKey
  name
  description
  component
  props
  components
  vars
  rank
  Parent {
    ...TemplateNoNesting
  }
  CreatedBy {
    ...UserNoNesting
  }
  Project {
    ...ProjectNoNesting
  }
  PrismaProject {
    ...ProjectNoNesting
  }
}
`

export const ReceiptParserResultFragment = `fragment ReceiptParserResult on ReceiptParserResult {
  id
  createdAt
  updatedAt
}
`

export const AggregateTemplateFragment = `fragment AggregateTemplate on AggregateTemplate {
  count
}
`

export const ReceiptParserResultConnectionFragment = `fragment ReceiptParserResultConnection on ReceiptParserResultConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ReceiptParserResultEdgeNoNesting
  }
  aggregate {
    ...AggregateReceiptParserResultNoNesting
  }
}
`

export const BotProfileConnectionFragment = `fragment BotProfileConnection on BotProfileConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...BotProfileEdgeNoNesting
  }
  aggregate {
    ...AggregateBotProfileNoNesting
  }
}
`

export const ReceiptParserResultEdgeFragment = `fragment ReceiptParserResultEdge on ReceiptParserResultEdge {
  node {
    ...ReceiptParserResultNoNesting
  }
  cursor
}
`

export const CheckFragment = `fragment Check on Check {
  externalKey
  sum
}
`

export const ReceiptParserResultPreviousValuesFragment = `fragment ReceiptParserResultPreviousValues on ReceiptParserResultPreviousValues {
  id
  createdAt
  updatedAt
}
`

export const AggregateForwardClientFragment = `fragment AggregateForwardClient on AggregateForwardClient {
  count
}
`

export const ReceiptParserResultSubscriptionPayloadFragment = `fragment ReceiptParserResultSubscriptionPayload on ReceiptParserResultSubscriptionPayload {
  mutation
  node {
    ...ReceiptParserResultNoNesting
  }
  updatedFields
  previousValues {
    ...ReceiptParserResultPreviousValuesNoNesting
  }
}
`

export const TarifInfoExtensionFragment = `fragment TarifInfoExtension on TarifInfoExtension {
  ID_EXTENSION
  V_EXTENSION_NAME
  N_EXTENSION_PRICE_RUB
  N_EXTENSION_PRICE_BAL
  B_EXTENSION_CONNECT
}
`

export const ReceiptPreviousValuesFragment = `fragment ReceiptPreviousValues on ReceiptPreviousValues {
  id
  hash
  photo
  s
  fn
  i
  fp
  t
  n
  parserAttempts
  parserStatus
  parserData
  createdAt
  updatedAt
  externalKey
}
`

export const SurveymonkeyImportSurveysResponseFragment = `fragment SurveymonkeyImportSurveysResponse on SurveymonkeyImportSurveysResponse {
  created
  updated
}
`

export const ReceiptSubscriptionPayloadFragment = `fragment ReceiptSubscriptionPayload on ReceiptSubscriptionPayload {
  mutation
  node {
    ...ReceiptNoNesting
  }
  updatedFields
  previousValues {
    ...ReceiptPreviousValuesNoNesting
  }
}
`

export const BuyExtensionResultFragment = `fragment BuyExtensionResult on BuyExtensionResult {
  N_ERROR
  V_ERROR
}
`

export const RegistrationConnectionFragment = `fragment RegistrationConnection on RegistrationConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...RegistrationEdgeNoNesting
  }
  aggregate {
    ...AggregateRegistrationNoNesting
  }
}
`

export const PartnerProductResponseFragment = `fragment PartnerProductResponse on PartnerProductResponse {
  success
  message
  data {
    ...PartnerProductNoNesting
  }
}
`

export const RegistrationEdgeFragment = `fragment RegistrationEdge on RegistrationEdge {
  node {
    ...RegistrationNoNesting
  }
  cursor
}
`

export const SberbankRegisterFragment = `fragment SberbankRegister on SberbankRegister {
  orderId
  formUrl
}
`

export const RegistrationPreviousValuesFragment = `fragment RegistrationPreviousValues on RegistrationPreviousValues {
  id
  firstName
  secondName
  lastName
  name
  email
  birthday
  isUnderage
  photoPassport
  photoRegistration
  photoSelfie
  status
  comment
  isLoginAttempt
  createdAt
  updatedAt
}
`

export const LoginPayloadFragment = `fragment LoginPayload on LoginPayload {
  token
  user {
    ...UserNoNesting
  }
}
`

export const RegistrationSubscriptionPayloadFragment = `fragment RegistrationSubscriptionPayload on RegistrationSubscriptionPayload {
  mutation
  node {
    ...RegistrationNoNesting
  }
  updatedFields
  previousValues {
    ...RegistrationPreviousValuesNoNesting
  }
}
`

export const K12PhoneResponseFragment = `fragment K12PhoneResponse on K12PhoneResponse {
  success
  message
  data {
    ...K12PhoneNoNesting
  }
}
`

export const RequestConnectionFragment = `fragment RequestConnection on RequestConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...RequestEdgeNoNesting
  }
  aggregate {
    ...AggregateRequestNoNesting
  }
}
`

export const SavedCheckFragment = `fragment SavedCheck on SavedCheck {
  ID_CHECK
}
`

export const RequestEdgeFragment = `fragment RequestEdge on RequestEdge {
  node {
    ...RequestNoNesting
  }
  cursor
}
`

export const BotMailingResponseFragment = `fragment BotMailingResponse on BotMailingResponse {
  success
  message
  data {
    ...BotMailingNoNesting
  }
}
`

export const RequestPreviousValuesFragment = `fragment RequestPreviousValues on RequestPreviousValues {
  id
  name
  phone
  status
  createdAt
  updatedAt
}
`

export const IMSIResponseFragment = `fragment IMSIResponse on IMSIResponse {
  success
  message
  data {
    ...K12PhoneNoNesting
  }
}
`

export const RequestSubscriptionPayloadFragment = `fragment RequestSubscriptionPayload on RequestSubscriptionPayload {
  mutation
  node {
    ...RequestNoNesting
  }
  updatedFields
  previousValues {
    ...RequestPreviousValuesNoNesting
  }
}
`

export const TemplatePreviousValuesFragment = `fragment TemplatePreviousValues on TemplatePreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  name
  description
  component
  props
  components
  vars
  rank
}
`

export const ResetPasswordFragment = `fragment ResetPassword on ResetPassword {
  id
  createdAt
  updatedAt
  code
  password
  validTill
  User {
    ...UserNoNesting
  }
}
`

export const AggregateDeviceFragment = `fragment AggregateDevice on AggregateDevice {
  count
}
`

export const ResetPasswordConnectionFragment = `fragment ResetPasswordConnection on ResetPasswordConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ResetPasswordEdgeNoNesting
  }
  aggregate {
    ...AggregateResetPasswordNoNesting
  }
}
`

export const AggregateLogFragment = `fragment AggregateLog on AggregateLog {
  count
}
`

export const ResetPasswordEdgeFragment = `fragment ResetPasswordEdge on ResetPasswordEdge {
  node {
    ...ResetPasswordNoNesting
  }
  cursor
}
`

export const AggregatePartnerCategoryFragment = `fragment AggregatePartnerCategory on AggregatePartnerCategory {
  count
}
`

export const ResetPasswordPreviousValuesFragment = `fragment ResetPasswordPreviousValues on ResetPasswordPreviousValues {
  id
  createdAt
  updatedAt
  code
  password
  validTill
}
`

export const AggregatePlanFragment = `fragment AggregatePlan on AggregatePlan {
  count
}
`

export const ResetPasswordSubscriptionPayloadFragment = `fragment ResetPasswordSubscriptionPayload on ResetPasswordSubscriptionPayload {
  mutation
  node {
    ...ResetPasswordNoNesting
  }
  updatedFields
  previousValues {
    ...ResetPasswordPreviousValuesNoNesting
  }
}
`

export const AggregatePollResultFragment = `fragment AggregatePollResult on AggregatePollResult {
  count
}
`

export const SBOrderConnectionFragment = `fragment SBOrderConnection on SBOrderConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SBOrderEdgeNoNesting
  }
  aggregate {
    ...AggregateSBOrderNoNesting
  }
}
`

export const AggregateReceiptParserLogFragment = `fragment AggregateReceiptParserLog on AggregateReceiptParserLog {
  count
}
`

export const SBOrderEdgeFragment = `fragment SBOrderEdge on SBOrderEdge {
  node {
    ...SBOrderNoNesting
  }
  cursor
}
`

export const AggregateRequestFragment = `fragment AggregateRequest on AggregateRequest {
  count
}
`

export const SBOrderPreviousValuesFragment = `fragment SBOrderPreviousValues on SBOrderPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  number
  orderStatus
  amount
  currency
  date
  paymentAmountInfo
}
`

export const AggregateSegmentCriteriaFragment = `fragment AggregateSegmentCriteria on AggregateSegmentCriteria {
  count
}
`

export const SBOrderSubscriptionPayloadFragment = `fragment SBOrderSubscriptionPayload on SBOrderSubscriptionPayload {
  mutation
  node {
    ...SBOrderNoNesting
  }
  updatedFields
  previousValues {
    ...SBOrderPreviousValuesNoNesting
  }
}
`

export const AggregateSurveymonkeyUserFragment = `fragment AggregateSurveymonkeyUser on AggregateSurveymonkeyUser {
  count
}
`

export const SegmentConnectionFragment = `fragment SegmentConnection on SegmentConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SegmentEdgeNoNesting
  }
  aggregate {
    ...AggregateSegmentNoNesting
  }
}
`

export const AggregateUserLogFragment = `fragment AggregateUserLog on AggregateUserLog {
  count
}
`

export const SegmentEdgeFragment = `fragment SegmentEdge on SegmentEdge {
  node {
    ...SegmentNoNesting
  }
  cursor
}
`

export const PartnerCategoryFragment = `fragment PartnerCategory on PartnerCategory {
  id
  name
  createdAt
  updatedAt
  level
}
`

export const SegmentCriteriaConnectionFragment = `fragment SegmentCriteriaConnection on SegmentCriteriaConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SegmentCriteriaEdgeNoNesting
  }
  aggregate {
    ...AggregateSegmentCriteriaNoNesting
  }
}
`

export const ReceiptParserLogFragment = `fragment ReceiptParserLog on ReceiptParserLog {
  id
  createdAt
  ParserOperator {
    ...ReceiptParserOperatorNoNesting
  }
  Receipt {
    ...ReceiptNoNesting
  }
}
`

export const SegmentCriteriaEdgeFragment = `fragment SegmentCriteriaEdge on SegmentCriteriaEdge {
  node {
    ...SegmentCriteriaNoNesting
  }
  cursor
}
`

export const PollResultAnswerFragment = `fragment PollResultAnswer on PollResultAnswer {
  id
  pollResult {
    ...PollResultNoNesting
  }
  answer {
    ...PollQuestionItemNoNesting
  }
}
`

export const SegmentCriteriaPreviousValuesFragment = `fragment SegmentCriteriaPreviousValues on SegmentCriteriaPreviousValues {
  id
  from
  to
}
`

export const ForwardContractServiceFragment = `fragment ForwardContractService on ForwardContractService {
  id
  externalKey
  Contract {
    ...ForwardClientContractNoNesting
  }
}
`

export const SegmentCriteriaSubscriptionPayloadFragment = `fragment SegmentCriteriaSubscriptionPayload on SegmentCriteriaSubscriptionPayload {
  mutation
  node {
    ...SegmentCriteriaNoNesting
  }
  updatedFields
  previousValues {
    ...SegmentCriteriaPreviousValuesNoNesting
  }
}
`

export const BillingFragment = `fragment Billing on Billing {
  id
  k12Phone {
    ...K12PhoneNoNesting
  }
  name
  points
  cash
  traffic
  minutes
  sms
  service {
    ...ServiceNoNesting
  }
  createdAt
  updatedAt
  type
}
`

export const SegmentCriteriaTypeConnectionFragment = `fragment SegmentCriteriaTypeConnection on SegmentCriteriaTypeConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SegmentCriteriaTypeEdgeNoNesting
  }
  aggregate {
    ...AggregateSegmentCriteriaTypeNoNesting
  }
}
`

export const ArticleConnectionFragment = `fragment ArticleConnection on ArticleConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ArticleEdgeNoNesting
  }
  aggregate {
    ...AggregateArticleNoNesting
  }
}
`

export const SegmentCriteriaTypeEdgeFragment = `fragment SegmentCriteriaTypeEdge on SegmentCriteriaTypeEdge {
  node {
    ...SegmentCriteriaTypeNoNesting
  }
  cursor
}
`

export const PackageEdgeFragment = `fragment PackageEdge on PackageEdge {
  node {
    ...PackageNoNesting
  }
  cursor
}
`

export const SegmentCriteriaTypePreviousValuesFragment = `fragment SegmentCriteriaTypePreviousValues on SegmentCriteriaTypePreviousValues {
  id
  name
  isBetween
  isAttachPartners
}
`

export const AggregatePartnerFragment = `fragment AggregatePartner on AggregatePartner {
  count
}
`

export const SegmentCriteriaTypeSubscriptionPayloadFragment = `fragment SegmentCriteriaTypeSubscriptionPayload on SegmentCriteriaTypeSubscriptionPayload {
  mutation
  node {
    ...SegmentCriteriaTypeNoNesting
  }
  updatedFields
  previousValues {
    ...SegmentCriteriaTypePreviousValuesNoNesting
  }
}
`

export const PushNotificationEdgeFragment = `fragment PushNotificationEdge on PushNotificationEdge {
  node {
    ...PushNotificationNoNesting
  }
  cursor
}
`

export const SegmentPreviousValuesFragment = `fragment SegmentPreviousValues on SegmentPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const SettingEdgeFragment = `fragment SettingEdge on SettingEdge {
  node {
    ...SettingNoNesting
  }
  cursor
}
`

export const SegmentSubscriptionPayloadFragment = `fragment SegmentSubscriptionPayload on SegmentSubscriptionPayload {
  mutation
  node {
    ...SegmentNoNesting
  }
  updatedFields
  previousValues {
    ...SegmentPreviousValuesNoNesting
  }
}
`

export const SurveymonkeyCollectorEdgeFragment = `fragment SurveymonkeyCollectorEdge on SurveymonkeyCollectorEdge {
  node {
    ...SurveymonkeyCollectorNoNesting
  }
  cursor
}
`

export const ServiceConnectionFragment = `fragment ServiceConnection on ServiceConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ServiceEdgeNoNesting
  }
  aggregate {
    ...AggregateServiceNoNesting
  }
}
`

export const SurveyFragment = `fragment Survey on Survey {
  id
  name
  description
  approximate_time
  points
  url
}
`

export const ServiceEdgeFragment = `fragment ServiceEdge on ServiceEdge {
  node {
    ...ServiceNoNesting
  }
  cursor
}
`

export const NewsletterConnectionFragment = `fragment NewsletterConnection on NewsletterConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...NewsletterEdgeNoNesting
  }
  aggregate {
    ...AggregateNewsletterNoNesting
  }
}
`

export const ServicePreviousValuesFragment = `fragment ServicePreviousValues on ServicePreviousValues {
  id
  name
  description
  image
  priceInPoints
  priceInCash
  priceFeePoints
  priceFeeCash
  default
  active
  createdAt
  updatedAt
}
`

export const UserEdgeFragment = `fragment UserEdge on UserEdge {
  node {
    ...UserNoNesting
  }
  cursor
}
`

export const ServiceSubscriptionPayloadFragment = `fragment ServiceSubscriptionPayload on ServiceSubscriptionPayload {
  mutation
  node {
    ...ServiceNoNesting
  }
  updatedFields
  previousValues {
    ...ServicePreviousValuesNoNesting
  }
}
`

export const BotMailingEdgeFragment = `fragment BotMailingEdge on BotMailingEdge {
  node {
    ...BotMailingNoNesting
  }
  cursor
}
`

export const SettingPreviousValuesFragment = `fragment SettingPreviousValues on SettingPreviousValues {
  id
  name
  key
  value
  group
}
`

export const ForwardClientConnectionFragment = `fragment ForwardClientConnection on ForwardClientConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...ForwardClientEdgeNoNesting
  }
  aggregate {
    ...AggregateForwardClientNoNesting
  }
}
`

export const SettingSubscriptionPayloadFragment = `fragment SettingSubscriptionPayload on SettingSubscriptionPayload {
  mutation
  node {
    ...SettingNoNesting
  }
  updatedFields
  previousValues {
    ...SettingPreviousValuesNoNesting
  }
}
`

export const ErrorFragment = `fragment Error on Error {
  key
  message
}
`

export const SurveymonkeyCollectorPreviousValuesFragment = `fragment SurveymonkeyCollectorPreviousValues on SurveymonkeyCollectorPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  name
  hash
  url
  href
  status
  type
}
`

export const PartnerResponseFragment = `fragment PartnerResponse on PartnerResponse {
  success
  message
  data {
    ...PartnerNoNesting
  }
}
`

export const SurveymonkeyCollectorResponseFragment = `fragment SurveymonkeyCollectorResponse on SurveymonkeyCollectorResponse {
  success
  message
  data {
    ...SurveymonkeyCollectorNoNesting
  }
}
`

export const PaymentFragment = `fragment Payment on Payment {
  id
  createdAt
  updatedAt
  amount
  status
  User {
    ...UserNoNesting
  }
  K12Phone {
    ...K12PhoneNoNesting
  }
  ForwardClient {
    ...ForwardClientNoNesting
  }
  ForwardClientContract {
    ...ForwardClientContractNoNesting
  }
  ForwardContractService {
    ...ForwardContractServiceNoNesting
  }
  SBOrder {
    ...SBOrderNoNesting
  }
}
`

export const SurveymonkeyCollectorSubscriptionPayloadFragment = `fragment SurveymonkeyCollectorSubscriptionPayload on SurveymonkeyCollectorSubscriptionPayload {
  mutation
  node {
    ...SurveymonkeyCollectorNoNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeyCollectorPreviousValuesNoNesting
  }
}
`

export const ForwardCheckFragment = `fragment ForwardCheck on ForwardCheck {
  ID_CHECK
}
`

export const SurveymonkeyResponsePreviousValuesFragment = `fragment SurveymonkeyResponsePreviousValues on SurveymonkeyResponsePreviousValues {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  collector_id
  recipient_id
  total_time
  custom_value
  edit_url
  analyze_url
  ip_address
  custom_variables
  logic_path
  metadata
  response_status
  collection_mode
  href
  respondent_id
  points
  points_applyed
}
`

export const SignContractResponseFragment = `fragment SignContractResponse on SignContractResponse {
  F_PDF_CONTRACT
}
`

export const SurveymonkeyResponseSubscriptionPayloadFragment = `fragment SurveymonkeyResponseSubscriptionPayload on SurveymonkeyResponseSubscriptionPayload {
  mutation
  node {
    ...SurveymonkeyResponseNoNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeyResponsePreviousValuesNoNesting
  }
}
`

export const AggregateBotChatFragment = `fragment AggregateBotChat on AggregateBotChat {
  count
}
`

export const SurveymonkeySurveyPreviousValuesFragment = `fragment SurveymonkeySurveyPreviousValues on SurveymonkeySurveyPreviousValues {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  name
  nickname
  href
  custom_variables
  active
  approximate_time
  points
  description
}
`

export const AggregateNewsletterJournalFragment = `fragment AggregateNewsletterJournal on AggregateNewsletterJournal {
  count
}
`

export const SurveymonkeySurveySubscriptionPayloadFragment = `fragment SurveymonkeySurveySubscriptionPayload on SurveymonkeySurveySubscriptionPayload {
  mutation
  node {
    ...SurveymonkeySurveyNoNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeySurveyPreviousValuesNoNesting
  }
}
`

export const AggregatePollQuestionFragment = `fragment AggregatePollQuestion on AggregatePollQuestion {
  count
}
`

export const SurveymonkeySurveyWebhookConnectionFragment = `fragment SurveymonkeySurveyWebhookConnection on SurveymonkeySurveyWebhookConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SurveymonkeySurveyWebhookEdgeNoNesting
  }
  aggregate {
    ...AggregateSurveymonkeySurveyWebhookNoNesting
  }
}
`

export const AggregateReceiptParserResultFragment = `fragment AggregateReceiptParserResult on AggregateReceiptParserResult {
  count
}
`

export const SurveymonkeySurveyWebhookEdgeFragment = `fragment SurveymonkeySurveyWebhookEdge on SurveymonkeySurveyWebhookEdge {
  node {
    ...SurveymonkeySurveyWebhookNoNesting
  }
  cursor
}
`

export const AggregateServiceFragment = `fragment AggregateService on AggregateService {
  count
}
`

export const SurveymonkeySurveyWebhookPreviousValuesFragment = `fragment SurveymonkeySurveyWebhookPreviousValues on SurveymonkeySurveyWebhookPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  name
  event_type
  subscription_url
  object_type
  href
}
`

export const SegmentFragment = `fragment Segment on Segment {
  id
  name
  criterias {
    ...SegmentCriteriaNoNesting
  }
  createdAt
  updatedAt
}
`

export const SurveymonkeySurveyWebhookSubscriptionPayloadFragment = `fragment SurveymonkeySurveyWebhookSubscriptionPayload on SurveymonkeySurveyWebhookSubscriptionPayload {
  mutation
  node {
    ...SurveymonkeySurveyWebhookNoNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeySurveyWebhookPreviousValuesNoNesting
  }
}
`

export const PollResultFragment = `fragment PollResult on PollResult {
  id
  poll {
    ...PollNoNesting
  }
  user {
    ...UserNoNesting
  }
  startedAt
  endedAt
  answers {
    ...PollResultAnswerNoNesting
  }
  status
  createdAt
  updatedAt
}
`

export const SurveymonkeyUserConnectionFragment = `fragment SurveymonkeyUserConnection on SurveymonkeyUserConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...SurveymonkeyUserEdgeNoNesting
  }
  aggregate {
    ...AggregateSurveymonkeyUserNoNesting
  }
}
`

export const NotificationTypeFragment = `fragment NotificationType on NotificationType {
  id
  name
}
`

export const SurveymonkeyUserEdgeFragment = `fragment SurveymonkeyUserEdge on SurveymonkeyUserEdge {
  node {
    ...SurveymonkeyUserNoNesting
  }
  cursor
}
`

export const CouponConnectionFragment = `fragment CouponConnection on CouponConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...CouponEdgeNoNesting
  }
  aggregate {
    ...AggregateCouponNoNesting
  }
}
`

export const SurveymonkeyUserPreviousValuesFragment = `fragment SurveymonkeyUserPreviousValues on SurveymonkeyUserPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  email_address
}
`

export const PromotionConnectionFragment = `fragment PromotionConnection on PromotionConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...PromotionEdgeNoNesting
  }
  aggregate {
    ...AggregatePromotionNoNesting
  }
}
`

export const SurveymonkeyUserSubscriptionPayloadFragment = `fragment SurveymonkeyUserSubscriptionPayload on SurveymonkeyUserSubscriptionPayload {
  mutation
  node {
    ...SurveymonkeyUserNoNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeyUserPreviousValuesNoNesting
  }
}
`

export const SurveymonkeySurveyWebhookFragment = `fragment SurveymonkeySurveyWebhook on SurveymonkeySurveyWebhook {
  id
  createdAt
  updatedAt
  externalKey
  name
  event_type
  subscription_url
  object_type
  href
  Surveys {
    ...SurveymonkeySurveyNoNesting
  }
}
`

export const TransactionConnectionFragment = `fragment TransactionConnection on TransactionConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...TransactionEdgeNoNesting
  }
  aggregate {
    ...AggregateTransactionNoNesting
  }
}
`

export const K12PhoneConnectionFragment = `fragment K12PhoneConnection on K12PhoneConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...K12PhoneEdgeNoNesting
  }
  aggregate {
    ...AggregateK12PhoneNoNesting
  }
}
`

export const TransactionEdgeFragment = `fragment TransactionEdge on TransactionEdge {
  node {
    ...TransactionNoNesting
  }
  cursor
}
`

export const TemplateConnectionFragment = `fragment TemplateConnection on TemplateConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...TemplateEdgeNoNesting
  }
  aggregate {
    ...AggregateTemplateNoNesting
  }
}
`

export const TransactionPreviousValuesFragment = `fragment TransactionPreviousValues on TransactionPreviousValues {
  id
  points
  comment
  status
  createdAt
  updatedAt
  deletedAt
}
`

export const TarifInfoFragment = `fragment TarifInfo on TarifInfo {
  ID_SERVICE
  V_SERVICE
  N_SERVICE_PRICE_RUB
  N_SERVICE_PRICE_BAL
  B_SERVICE_CONNECT
}
`

export const TransactionPromotionConnectionFragment = `fragment TransactionPromotionConnection on TransactionPromotionConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...TransactionPromotionEdgeNoNesting
  }
  aggregate {
    ...AggregateTransactionPromotionNoNesting
  }
}
`

export const PromotionResponseFragment = `fragment PromotionResponse on PromotionResponse {
  success
  message
  data {
    ...PromotionNoNesting
  }
}
`

export const TransactionPromotionEdgeFragment = `fragment TransactionPromotionEdge on TransactionPromotionEdge {
  node {
    ...TransactionPromotionNoNesting
  }
  cursor
}
`

export const UserResponseFragment = `fragment UserResponse on UserResponse {
  success
  message
  data {
    ...UserNoNesting
  }
}
`

export const TransactionPromotionPreviousValuesFragment = `fragment TransactionPromotionPreviousValues on TransactionPromotionPreviousValues {
  id
  createdAt
  updatedAt
  amount
  coefficient
  points
}
`

export const AggregateForwardContractServiceFragment = `fragment AggregateForwardContractService on AggregateForwardContractService {
  count
}
`

export const TransactionPromotionResponseFragment = `fragment TransactionPromotionResponse on TransactionPromotionResponse {
  success
  message
  data {
    ...TransactionPromotionNoNesting
  }
}
`

export const AggregateProjectFragment = `fragment AggregateProject on AggregateProject {
  count
}
`

export const TransactionPromotionSubscriptionPayloadFragment = `fragment TransactionPromotionSubscriptionPayload on TransactionPromotionSubscriptionPayload {
  mutation
  node {
    ...TransactionPromotionNoNesting
  }
  updatedFields
  previousValues {
    ...TransactionPromotionPreviousValuesNoNesting
  }
}
`

export const AggregateTransactionPromotionFragment = `fragment AggregateTransactionPromotion on AggregateTransactionPromotion {
  count
}
`

export const TransactionSubscriptionPayloadFragment = `fragment TransactionSubscriptionPayload on TransactionSubscriptionPayload {
  mutation
  node {
    ...TransactionNoNesting
  }
  updatedFields
  previousValues {
    ...TransactionPreviousValuesNoNesting
  }
}
`

export const RegistrationFragment = `fragment Registration on Registration {
  id
  device {
    ...DeviceNoNesting
  }
  k12Phone {
    ...K12PhoneNoNesting
  }
  user {
    ...UserNoNesting
  }
  firstName
  secondName
  lastName
  name
  email
  birthday
  isUnderage
  photoPassport
  photoRegistration
  photoSelfie
  status
  comment
  isLoginAttempt
  createdAt
  updatedAt
}
`

export const UserGroupConnectionFragment = `fragment UserGroupConnection on UserGroupConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...UserGroupEdgeNoNesting
  }
  aggregate {
    ...AggregateUserGroupNoNesting
  }
}
`

export const PageEdgeFragment = `fragment PageEdge on PageEdge {
  node {
    ...PageNoNesting
  }
  cursor
}
`

export const UserGroupEdgeFragment = `fragment UserGroupEdge on UserGroupEdge {
  node {
    ...UserGroupNoNesting
  }
  cursor
}
`

export const AggregateSurveymonkeySurveyFragment = `fragment AggregateSurveymonkeySurvey on AggregateSurveymonkeySurvey {
  count
}
`

export const UserGroupPreviousValuesFragment = `fragment UserGroupPreviousValues on UserGroupPreviousValues {
  id
  createdAt
  updatedAt
  name
}
`

export const AggregateBotProfileFragment = `fragment AggregateBotProfile on AggregateBotProfile {
  count
}
`

export const UserGroupSubscriptionPayloadFragment = `fragment UserGroupSubscriptionPayload on UserGroupSubscriptionPayload {
  mutation
  node {
    ...UserGroupNoNesting
  }
  updatedFields
  previousValues {
    ...UserGroupPreviousValuesNoNesting
  }
}
`

export const AuthDeviceFragment = `fragment AuthDevice on AuthDevice {
  token
}
`

export const UserLogConnectionFragment = `fragment UserLogConnection on UserLogConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...UserLogEdgeNoNesting
  }
  aggregate {
    ...AggregateUserLogNoNesting
  }
}
`

export const AggregatePartnerProductFragment = `fragment AggregatePartnerProduct on AggregatePartnerProduct {
  count
}
`

export const UserLogEdgeFragment = `fragment UserLogEdge on UserLogEdge {
  node {
    ...UserLogNoNesting
  }
  cursor
}
`

export const CouponFragment = `fragment Coupon on Coupon {
  id
  createdAt
  updatedAt
  name
  conditions
  description
  content
  url
  preview
  canComplain
  segments {
    ...SegmentNoNesting
  }
  startAt
  endAt
  published
  partner {
    ...PartnerNoNesting
  }
}
`

export const UserLogPreviousValuesFragment = `fragment UserLogPreviousValues on UserLogPreviousValues {
  id
  action
  value
}
`

export const AggregatePushNotificationQueueFragment = `fragment AggregatePushNotificationQueue on AggregatePushNotificationQueue {
  count
}
`

export const CouponResponseFragment = `fragment CouponResponse on CouponResponse {
  success
  message
  data {
    ...CouponNoNesting
  }
}
`

export const VerificationPreviousValuesFragment = `fragment VerificationPreviousValues on VerificationPreviousValues {
  id
  hash
  attempts
  refetchAttempts
  createdAt
  updatedAt
}
`

export const VerificationEdgeFragment = `fragment VerificationEdge on VerificationEdge {
  node {
    ...VerificationNoNesting
  }
  cursor
}
`

export const VerificationConnectionFragment = `fragment VerificationConnection on VerificationConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...VerificationEdgeNoNesting
  }
  aggregate {
    ...AggregateVerificationNoNesting
  }
}
`

export const UserLogSubscriptionPayloadFragment = `fragment UserLogSubscriptionPayload on UserLogSubscriptionPayload {
  mutation
  node {
    ...UserLogNoNesting
  }
  updatedFields
  previousValues {
    ...UserLogPreviousValuesNoNesting
  }
}
`

export const UserPreviousValuesFragment = `fragment UserPreviousValues on UserPreviousValues {
  id
  login
  email
  name
  firstName
  secondName
  lastName
  birthday
  role
  status
  password
  loginAttempts
  blockedAt
  createdAt
  updatedAt
  deletedAt
  marketplaceToken
  username
  phone
  fullname
  image
  address
  hidden
  sudo
  showEmail
  showPhone
}
`

export const FileConnectionFragment = `fragment FileConnection on FileConnection {
  pageInfo {
    ...PageInfoNoNesting
  }
  edges {
    ...FileEdgeNoNesting
  }
  aggregate {
    ...AggregateFileNoNesting
  }
}
`

export const BotMailingFragment = `fragment BotMailing on BotMailing {
  id
  createdAt
  updatedAt
  name
  message
  Messages {
    ...BotMailingMessageNoNesting
  }
  status
}
`

export const AggregateSBOrderFragment = `fragment AggregateSBOrder on AggregateSBOrder {
  count
}
`


export const ArticlePreviousValuesNoNestingFragment = `fragment ArticlePreviousValuesNoNesting on ArticlePreviousValues {
  id
  name
  description
  content
  preview
  published
  publishedAt
  createdAt
  updatedAt
}
`

export const VerificationSubscriptionPayloadNoNestingFragment = `fragment VerificationSubscriptionPayloadNoNesting on VerificationSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ArticleSubscriptionPayloadNoNestingFragment = `fragment ArticleSubscriptionPayloadNoNesting on ArticleSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ArticleNoNestingFragment = `fragment ArticleNoNesting on Article {
  id
  name
  description
  content
  preview
  published
  publishedAt
  createdAt
  updatedAt
  thumb
}
`

export const BillingPreviousValuesNoNestingFragment = `fragment BillingPreviousValuesNoNesting on BillingPreviousValues {
  id
  name
  points
  cash
  traffic
  minutes
  sms
  createdAt
  updatedAt
}
`

export const SegmentCriteriaNoNestingFragment = `fragment SegmentCriteriaNoNesting on SegmentCriteria {
  id
  from
  to
}
`

export const BillingSubscriptionPayloadNoNestingFragment = `fragment BillingSubscriptionPayloadNoNesting on BillingSubscriptionPayload {
  mutation
  updatedFields
}
`

export const PartnerNoNestingFragment = `fragment PartnerNoNesting on Partner {
  id
  name
  logo
  address
  active
  createdAt
  updatedAt
  logoThumb
  typeId
}
`

export const BotChatNoNestingFragment = `fragment BotChatNoNesting on BotChat {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  username
  type
  text
  entities
}
`

export const PartnerTypeNoNestingFragment = `fragment PartnerTypeNoNesting on PartnerType {
  id
  name
  daysToConfirm
  active
  createdAt
  updatedAt
}
`

export const PartnerProductNoNestingFragment = `fragment PartnerProductNoNesting on PartnerProduct {
  id
  name
  createdAt
  updatedAt
}
`

export const BotChatEdgeNoNestingFragment = `fragment BotChatEdgeNoNesting on BotChatEdge {
  cursor
}
`

export const PartnerFnNoNestingFragment = `fragment PartnerFnNoNesting on PartnerFn {
  id
  name
  createdAt
  updatedAt
}
`

export const BotChatPreviousValuesNoNestingFragment = `fragment BotChatPreviousValuesNoNesting on BotChatPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  username
  type
  text
  entities
}
`

export const ReceiptParserOperatorNoNestingFragment = `fragment ReceiptParserOperatorNoNesting on ReceiptParserOperator {
  id
  createdAt
  updatedAt
  name
  code
}
`

export const BotChatSubscriptionPayloadNoNestingFragment = `fragment BotChatSubscriptionPayloadNoNesting on BotChatSubscriptionPayload {
  mutation
  updatedFields
}
`

export const TransactionNoNestingFragment = `fragment TransactionNoNesting on Transaction {
  id
  points
  comment
  status
  createdAt
  updatedAt
  deletedAt
}
`

export const UserNoNestingFragment = `fragment UserNoNesting on User {
  id
  login
  email
  name
  firstName
  secondName
  lastName
  birthday
  role
  status
  password
  loginAttempts
  blockedAt
  createdAt
  updatedAt
  deletedAt
  marketplaceToken
  username
  phone
  fullname
  image
  address
  hidden
  sudo
  showEmail
  showPhone
  planName
  fullName
  isFirstEntry
  hasEmail
  hasPhone
}
`

export const BotEdgeNoNestingFragment = `fragment BotEdgeNoNesting on BotEdge {
  cursor
}
`

export const PollNoNestingFragment = `fragment PollNoNesting on Poll {
  id
  name
  description
  content
  points
  leadTime
  secret
  startAt
  endAt
  status
  createdAt
  updatedAt
}
`

export const PollQuestionItemNoNestingFragment = `fragment PollQuestionItemNoNesting on PollQuestionItem {
  id
  name
}
`

export const BotMailingMessageEdgeNoNestingFragment = `fragment BotMailingMessageEdgeNoNesting on BotMailingMessageEdge {
  cursor
}
`

export const DeviceNoNestingFragment = `fragment DeviceNoNesting on Device {
  id
  token
  type
  pushErrors
  createdAt
  updatedAt
  deletedAt
}
`

export const BotMailingMessagePreviousValuesNoNestingFragment = `fragment BotMailingMessagePreviousValuesNoNesting on BotMailingMessagePreviousValues {
  id
  createdAt
  updatedAt
  message
  sended
  error
}
`

export const PlanNoNestingFragment = `fragment PlanNoNesting on Plan {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const BotMailingMessageSubscriptionPayloadNoNestingFragment = `fragment BotMailingMessageSubscriptionPayloadNoNesting on BotMailingMessageSubscriptionPayload {
  mutation
  updatedFields
}
`

export const VerificationNoNestingFragment = `fragment VerificationNoNesting on Verification {
  id
  hash
  attempts
  refetchAttempts
  createdAt
  updatedAt
  refetchAt
  isVerify
}
`

export const BotMailingPreviousValuesNoNestingFragment = `fragment BotMailingPreviousValuesNoNesting on BotMailingPreviousValues {
  id
  createdAt
  updatedAt
  name
  message
  status
}
`

export const ForwardClientContractNoNestingFragment = `fragment ForwardClientContractNoNesting on ForwardClientContract {
  id
  externalKey
  V_CHECK_CHILD
  signed
}
`

export const BotMailingSubscriptionPayloadNoNestingFragment = `fragment BotMailingSubscriptionPayloadNoNesting on BotMailingSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ForwardOfferNoNestingFragment = `fragment ForwardOfferNoNesting on ForwardOffer {
  id
  createdAt
  updatedAt
  V_PERS_SWITCH
}
`

export const BotPreviousValuesNoNestingFragment = `fragment BotPreviousValuesNoNesting on BotPreviousValues {
  id
  createdAt
  updatedAt
  type
  name
}
`

export const PushNotificationQueueNoNestingFragment = `fragment PushNotificationQueueNoNesting on PushNotificationQueue {
  id
  createdAt
  updatedAt
  message
}
`

export const BotProfilePreviousValuesNoNestingFragment = `fragment BotProfilePreviousValuesNoNesting on BotProfilePreviousValues {
  id
  key
  createdAt
  updatedAt
  type
  login
  firstName
  secondName
  lastName
  email
  birthday
  is_bot
  language_code
}
`

export const UserLogNoNestingFragment = `fragment UserLogNoNesting on UserLog {
  id
  action
  value
}
`

export const BotProfileSubscriptionPayloadNoNestingFragment = `fragment BotProfileSubscriptionPayloadNoNesting on BotProfileSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ServiceNoNestingFragment = `fragment ServiceNoNesting on Service {
  id
  name
  description
  image
  priceInPoints
  priceInCash
  priceFeePoints
  priceFeeCash
  default
  active
  createdAt
  updatedAt
  thumb
}
`

export const BotSubscriptionPayloadNoNestingFragment = `fragment BotSubscriptionPayloadNoNesting on BotSubscriptionPayload {
  mutation
  updatedFields
}
`

export const BotProfileNoNestingFragment = `fragment BotProfileNoNesting on BotProfile {
  id
  key
  createdAt
  updatedAt
  type
  login
  firstName
  secondName
  lastName
  email
  birthday
  is_bot
  language_code
  fullname
}
`

export const ClientNoNestingFragment = `fragment ClientNoNesting on Client {
  externalKey
}
`

export const BotMailingMessageNoNestingFragment = `fragment BotMailingMessageNoNesting on BotMailingMessage {
  id
  createdAt
  updatedAt
  message
  sended
  error
}
`

export const CouponPreviousValuesNoNestingFragment = `fragment CouponPreviousValuesNoNesting on CouponPreviousValues {
  id
  createdAt
  updatedAt
  name
  conditions
  description
  content
  url
  preview
  canComplain
  startAt
  endAt
  published
}
`

export const PackageNoNestingFragment = `fragment PackageNoNesting on Package {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const CouponSubscriptionPayloadNoNestingFragment = `fragment CouponSubscriptionPayloadNoNesting on CouponSubscriptionPayload {
  mutation
  updatedFields
}
`

export const TransactionPromotionNoNestingFragment = `fragment TransactionPromotionNoNesting on TransactionPromotion {
  id
  createdAt
  updatedAt
  amount
  coefficient
  points
}
`

export const PageInfoNoNestingFragment = `fragment PageInfoNoNesting on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
`

export const DeviceEdgeNoNestingFragment = `fragment DeviceEdgeNoNesting on DeviceEdge {
  cursor
}
`

export const AggregateArticleNoNestingFragment = `fragment AggregateArticleNoNesting on AggregateArticle {
  count
}
`

export const DevicePreviousValuesNoNestingFragment = `fragment DevicePreviousValuesNoNesting on DevicePreviousValues {
  id
  token
  type
  pushErrors
  createdAt
  updatedAt
  deletedAt
}
`

export const CouponEdgeNoNestingFragment = `fragment CouponEdgeNoNesting on CouponEdge {
  cursor
}
`

export const DeviceSubscriptionPayloadNoNestingFragment = `fragment DeviceSubscriptionPayloadNoNesting on DeviceSubscriptionPayload {
  mutation
  updatedFields
}
`

export const FilePreviousValuesNoNestingFragment = `fragment FilePreviousValuesNoNesting on FilePreviousValues {
  id
  createdAt
  updatedAt
  path
  name
  filename
  mimetype
  encoding
  hash
  size
  rank
}
`

export const AggregatePackageNoNestingFragment = `fragment AggregatePackageNoNesting on AggregatePackage {
  count
}
`

export const FileResponseNoNestingFragment = `fragment FileResponseNoNesting on FileResponse {
  success
  message
}
`

export const FileSubscriptionPayloadNoNestingFragment = `fragment FileSubscriptionPayloadNoNesting on FileSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregatePageNoNestingFragment = `fragment AggregatePageNoNesting on AggregatePage {
  count
}
`

export const PartnerEdgeNoNestingFragment = `fragment PartnerEdgeNoNesting on PartnerEdge {
  cursor
}
`

export const ForwardClientContractEdgeNoNestingFragment = `fragment ForwardClientContractEdgeNoNesting on ForwardClientContractEdge {
  cursor
}
`

export const ForwardClientContractPreviousValuesNoNestingFragment = `fragment ForwardClientContractPreviousValuesNoNesting on ForwardClientContractPreviousValues {
  id
  externalKey
  V_CHECK_CHILD
  signed
}
`

export const AggregatePartnerTypeNoNestingFragment = `fragment AggregatePartnerTypeNoNesting on AggregatePartnerType {
  count
}
`

export const ForwardClientContractSubscriptionPayloadNoNestingFragment = `fragment ForwardClientContractSubscriptionPayloadNoNesting on ForwardClientContractSubscriptionPayload {
  mutation
  updatedFields
}
`

export const PromotionEdgeNoNestingFragment = `fragment PromotionEdgeNoNesting on PromotionEdge {
  cursor
}
`

export const ForwardClientPreviousValuesNoNestingFragment = `fragment ForwardClientPreviousValuesNoNesting on ForwardClientPreviousValues {
  id
  externalKey
  B_CHECK
  newPhotoRequested
  newPhotoRequestedMessage
}
`

export const ForwardClientSubscriptionPayloadNoNestingFragment = `fragment ForwardClientSubscriptionPayloadNoNesting on ForwardClientSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregatePushNotificationNoNestingFragment = `fragment AggregatePushNotificationNoNesting on AggregatePushNotification {
  count
}
`

export const PushNotificationQueueEdgeNoNestingFragment = `fragment PushNotificationQueueEdgeNoNesting on PushNotificationQueueEdge {
  cursor
}
`

export const ForwardContractServiceEdgeNoNestingFragment = `fragment ForwardContractServiceEdgeNoNesting on ForwardContractServiceEdge {
  cursor
}
`

export const SettingNoNestingFragment = `fragment SettingNoNesting on Setting {
  id
  name
  key
  value
  group
}
`

export const ForwardContractServicePreviousValuesNoNestingFragment = `fragment ForwardContractServicePreviousValuesNoNesting on ForwardContractServicePreviousValues {
  id
  externalKey
}
`

export const ForwardContractServiceSubscriptionPayloadNoNestingFragment = `fragment ForwardContractServiceSubscriptionPayloadNoNesting on ForwardContractServiceSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateSettingNoNestingFragment = `fragment AggregateSettingNoNesting on AggregateSetting {
  count
}
`

export const SurveymonkeySurveyNoNestingFragment = `fragment SurveymonkeySurveyNoNesting on SurveymonkeySurvey {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  name
  nickname
  href
  custom_variables
  active
  approximate_time
  points
  description
}
`

export const ForwardOfferEdgeNoNestingFragment = `fragment ForwardOfferEdgeNoNesting on ForwardOfferEdge {
  cursor
}
`

export const SurveymonkeyResponseNoNestingFragment = `fragment SurveymonkeyResponseNoNesting on SurveymonkeyResponse {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  collector_id
  recipient_id
  total_time
  custom_value
  edit_url
  analyze_url
  ip_address
  custom_variables
  logic_path
  metadata
  response_status
  collection_mode
  href
  respondent_id
  points
  points_applyed
}
`

export const ForwardOfferPreviousValuesNoNestingFragment = `fragment ForwardOfferPreviousValuesNoNesting on ForwardOfferPreviousValues {
  id
  createdAt
  updatedAt
  V_PERS_SWITCH
}
`

export const ForwardOfferSubscriptionPayloadNoNestingFragment = `fragment ForwardOfferSubscriptionPayloadNoNesting on ForwardOfferSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateSurveymonkeyCollectorNoNestingFragment = `fragment AggregateSurveymonkeyCollectorNoNesting on AggregateSurveymonkeyCollector {
  count
}
`

export const K12PhonePreviousValuesNoNestingFragment = `fragment K12PhonePreviousValuesNoNesting on K12PhonePreviousValues {
  id
  phone
  cash
  points
  freezePoints
  traffic
  minutes
  sms
  activatedAt
  createdAt
  updatedAt
  deletedAt
  promotional
  status
  IMSI
}
`

export const SurveymonkeySurveyEdgeNoNestingFragment = `fragment SurveymonkeySurveyEdgeNoNesting on SurveymonkeySurveyEdge {
  cursor
}
`

export const K12PhoneSubscriptionPayloadNoNestingFragment = `fragment K12PhoneSubscriptionPayloadNoNesting on K12PhoneSubscriptionPayload {
  mutation
  updatedFields
}
`

export const LogNoNestingFragment = `fragment LogNoNesting on Log {
  id
  createdAt
  updatedAt
  level
  objectType
  message
  stack
}
`

export const AggregateSurveymonkeyResponseNoNestingFragment = `fragment AggregateSurveymonkeyResponseNoNesting on AggregateSurveymonkeyResponse {
  count
}
`

export const LogEdgeNoNestingFragment = `fragment LogEdgeNoNesting on LogEdge {
  cursor
}
`

export const AggregateBillingNoNestingFragment = `fragment AggregateBillingNoNesting on AggregateBilling {
  count
}
`

export const LogedInNoNestingFragment = `fragment LogedInNoNesting on LogedIn {
  id
  createdAt
  fake
}
`

export const K12PhoneEdgeNoNestingFragment = `fragment K12PhoneEdgeNoNesting on K12PhoneEdge {
  cursor
}
`

export const NewsletterNoNestingFragment = `fragment NewsletterNoNesting on Newsletter {
  id
  name
  text
  preview
  status
  startedAt
  createdAt
  updatedAt
}
`

export const LogedInEdgeNoNestingFragment = `fragment LogedInEdgeNoNesting on LogedInEdge {
  cursor
}
`

export const NewsletterEdgeNoNestingFragment = `fragment NewsletterEdgeNoNesting on NewsletterEdge {
  cursor
}
`

export const LogedInPreviousValuesNoNestingFragment = `fragment LogedInPreviousValuesNoNesting on LogedInPreviousValues {
  id
  createdAt
  fake
}
`

export const FileNoNestingFragment = `fragment FileNoNesting on File {
  id
  createdAt
  updatedAt
  path
  name
  filename
  mimetype
  encoding
  hash
  size
  rank
}
`

export const LogedInSubscriptionPayloadNoNestingFragment = `fragment LogedInSubscriptionPayloadNoNesting on LogedInSubscriptionPayload {
  mutation
  updatedFields
}
`

export const FileEdgeNoNestingFragment = `fragment FileEdgeNoNesting on FileEdge {
  cursor
}
`

export const LogPreviousValuesNoNestingFragment = `fragment LogPreviousValuesNoNesting on LogPreviousValues {
  id
  createdAt
  updatedAt
  level
  objectType
  message
  stack
}
`

export const LogSubscriptionPayloadNoNestingFragment = `fragment LogSubscriptionPayloadNoNesting on LogSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateUserNoNestingFragment = `fragment AggregateUserNoNesting on AggregateUser {
  count
}
`

export const NewsletterJournalNoNestingFragment = `fragment NewsletterJournalNoNesting on NewsletterJournal {
  id
  attempts
  lastAttemptAt
  deliveryAt
}
`

export const ProjectNoNestingFragment = `fragment ProjectNoNesting on Project {
  id
  createdAt
  updatedAt
  name
  domain
  description
  url
}
`

export const TemplateEdgeNoNestingFragment = `fragment TemplateEdgeNoNesting on TemplateEdge {
  cursor
}
`

export const NewsletterJournalEdgeNoNestingFragment = `fragment NewsletterJournalEdgeNoNesting on NewsletterJournalEdge {
  cursor
}
`

export const NewsletterJournalPreviousValuesNoNestingFragment = `fragment NewsletterJournalPreviousValuesNoNesting on NewsletterJournalPreviousValues {
  id
  attempts
  lastAttemptAt
  deliveryAt
}
`

export const AggregateBotMailingNoNestingFragment = `fragment AggregateBotMailingNoNesting on AggregateBotMailing {
  count
}
`

export const NewsletterJournalSubscriptionPayloadNoNestingFragment = `fragment NewsletterJournalSubscriptionPayloadNoNesting on NewsletterJournalSubscriptionPayload {
  mutation
  updatedFields
}
`

export const BotProfileEdgeNoNestingFragment = `fragment BotProfileEdgeNoNesting on BotProfileEdge {
  cursor
}
`

export const NewsletterPreviousValuesNoNestingFragment = `fragment NewsletterPreviousValuesNoNesting on NewsletterPreviousValues {
  id
  name
  text
  preview
  status
  startedAt
  createdAt
  updatedAt
}
`

export const ChecksInfoNoNestingFragment = `fragment ChecksInfoNoNesting on ChecksInfo {
  total
}
`

export const NewsletterSubscriptionPayloadNoNestingFragment = `fragment NewsletterSubscriptionPayloadNoNesting on NewsletterSubscriptionPayload {
  mutation
  updatedFields
}
`

export const CheckItemNoNestingFragment = `fragment CheckItemNoNesting on CheckItem {
  externalKey
  name
  count
  sum
}
`

export const ForwardClientEdgeNoNestingFragment = `fragment ForwardClientEdgeNoNesting on ForwardClientEdge {
  cursor
}
`

export const NotificationTypeEdgeNoNestingFragment = `fragment NotificationTypeEdgeNoNesting on NotificationTypeEdge {
  cursor
}
`

export const TarifPlanNoNestingFragment = `fragment TarifPlanNoNesting on TarifPlan {
  ID_TARIFF_PLAN
  V_TARIFF_PLAN_NAME
  V_TARIFF_PLAN_DESCR
}
`

export const NotificationTypePreviousValuesNoNestingFragment = `fragment NotificationTypePreviousValuesNoNesting on NotificationTypePreviousValues {
  id
  name
}
`

export const TarifInfoServiceNoNestingFragment = `fragment TarifInfoServiceNoNesting on TarifInfoService {
  ID_ADD_SRV
  V_ADD_SRV_NAME
  N_ADD_SRV_PRICE_RUB
  N_ADD_SRV_PRICE_BAL
  B_ADD_SRV_CONNECT
}
`

export const NotificationTypeSubscriptionPayloadNoNestingFragment = `fragment NotificationTypeSubscriptionPayloadNoNesting on NotificationTypeSubscriptionPayload {
  mutation
  updatedFields
}
`

export const SurveymonkeyResponseResponseNoNestingFragment = `fragment SurveymonkeyResponseResponseNoNesting on SurveymonkeyResponseResponse {
  success
  message
}
`

export const PackagePreviousValuesNoNestingFragment = `fragment PackagePreviousValuesNoNesting on PackagePreviousValues {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const SurveymonkeySurveyResponseNoNestingFragment = `fragment SurveymonkeySurveyResponseNoNesting on SurveymonkeySurveyResponse {
  success
  message
}
`

export const PackageSubscriptionPayloadNoNestingFragment = `fragment PackageSubscriptionPayloadNoNesting on PackageSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ArticleResponseNoNestingFragment = `fragment ArticleResponseNoNesting on ArticleResponse {
  success
  message
}
`

export const PagePreviousValuesNoNestingFragment = `fragment PagePreviousValuesNoNesting on PagePreviousValues {
  id
  key
  title
  content
}
`

export const PageSubscriptionPayloadNoNestingFragment = `fragment PageSubscriptionPayloadNoNesting on PageSubscriptionPayload {
  mutation
  updatedFields
}
`

export const PageResponseNoNestingFragment = `fragment PageResponseNoNesting on PageResponse {
  success
  message
}
`

export const PartnerFnResponseNoNestingFragment = `fragment PartnerFnResponseNoNesting on PartnerFnResponse {
  success
  message
}
`

export const PartnerCategoryEdgeNoNestingFragment = `fragment PartnerCategoryEdgeNoNesting on PartnerCategoryEdge {
  cursor
}
`

export const PartnerTypeResponseNoNestingFragment = `fragment PartnerTypeResponseNoNesting on PartnerTypeResponse {
  success
  message
}
`

export const PartnerCategoryPreviousValuesNoNestingFragment = `fragment PartnerCategoryPreviousValuesNoNesting on PartnerCategoryPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const PushNotificationQueueResponseNoNestingFragment = `fragment PushNotificationQueueResponseNoNesting on PushNotificationQueueResponse {
  success
  message
}
`

export const PartnerCategorySubscriptionPayloadNoNestingFragment = `fragment PartnerCategorySubscriptionPayloadNoNesting on PartnerCategorySubscriptionPayload {
  mutation
  updatedFields
}
`

export const SBOrderNoNestingFragment = `fragment SBOrderNoNesting on SBOrder {
  id
  createdAt
  updatedAt
  externalKey
  number
  orderStatus
  amount
  currency
  date
  paymentAmountInfo
}
`

export const SettingResponseNoNestingFragment = `fragment SettingResponseNoNesting on SettingResponse {
  success
  message
}
`

export const PartnerFnEdgeNoNestingFragment = `fragment PartnerFnEdgeNoNesting on PartnerFnEdge {
  cursor
}
`

export const AuthPayloadNoNestingFragment = `fragment AuthPayloadNoNesting on AuthPayload {
  token
}
`

export const PartnerFnPreviousValuesNoNestingFragment = `fragment PartnerFnPreviousValuesNoNesting on PartnerFnPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const PassportDataNoNestingFragment = `fragment PassportDataNoNesting on PassportData {
  LastName
  FirstName
  MiddleName
  Sex
  DateOfBirth
  PlaceOfBirth
  IssuedBy
  DateOfIssue
  DepartmentCode
  Series
  Number
}
`

export const PartnerFnSubscriptionPayloadNoNestingFragment = `fragment PartnerFnSubscriptionPayloadNoNesting on PartnerFnSubscriptionPayload {
  mutation
  updatedFields
}
`

export const BatchPayloadNoNestingFragment = `fragment BatchPayloadNoNesting on BatchPayload {
  count
}
`

export const PartnerPreviousValuesNoNestingFragment = `fragment PartnerPreviousValuesNoNesting on PartnerPreviousValues {
  id
  name
  logo
  address
  active
  createdAt
  updatedAt
}
`

export const TransactionResponseNoNestingFragment = `fragment TransactionResponseNoNesting on TransactionResponse {
  success
  message
}
`

export const SigninPayloadNoNestingFragment = `fragment SigninPayloadNoNesting on SigninPayload {
  success
  message
  token
}
`

export const PartnerProductEdgeNoNestingFragment = `fragment PartnerProductEdgeNoNesting on PartnerProductEdge {
  cursor
}
`

export const TemplateResponseNoNestingFragment = `fragment TemplateResponseNoNesting on TemplateResponse {
  success
  message
}
`

export const PartnerProductPreviousValuesNoNestingFragment = `fragment PartnerProductPreviousValuesNoNesting on PartnerProductPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const ClientResponseNoNestingFragment = `fragment ClientResponseNoNesting on ClientResponse {
  success
  message
}
`

export const PartnerProductSubscriptionPayloadNoNestingFragment = `fragment PartnerProductSubscriptionPayloadNoNesting on PartnerProductSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ActivateContractResponseNoNestingFragment = `fragment ActivateContractResponseNoNesting on ActivateContractResponse {
  phone
}
`

export const PartnerSubscriptionPayloadNoNestingFragment = `fragment PartnerSubscriptionPayloadNoNesting on PartnerSubscriptionPayload {
  mutation
  updatedFields
}
`

export const UserSubscriptionPayloadNoNestingFragment = `fragment UserSubscriptionPayloadNoNesting on UserSubscriptionPayload {
  mutation
  updatedFields
}
`

export const PartnerTypePreviousValuesNoNestingFragment = `fragment PartnerTypePreviousValuesNoNesting on PartnerTypePreviousValues {
  id
  name
  daysToConfirm
  active
  createdAt
  updatedAt
}
`

export const TemplateSubscriptionPayloadNoNestingFragment = `fragment TemplateSubscriptionPayloadNoNesting on TemplateSubscriptionPayload {
  mutation
  updatedFields
}
`

export const PartnerTypeSubscriptionPayloadNoNestingFragment = `fragment PartnerTypeSubscriptionPayloadNoNesting on PartnerTypeSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateBotNoNestingFragment = `fragment AggregateBotNoNesting on AggregateBot {
  count
}
`

export const AggregateBotMailingMessageNoNestingFragment = `fragment AggregateBotMailingMessageNoNesting on AggregateBotMailingMessage {
  count
}
`

export const PaymentEdgeNoNestingFragment = `fragment PaymentEdgeNoNesting on PaymentEdge {
  cursor
}
`

export const AggregateForwardClientContractNoNestingFragment = `fragment AggregateForwardClientContractNoNesting on AggregateForwardClientContract {
  count
}
`

export const PaymentPreviousValuesNoNestingFragment = `fragment PaymentPreviousValuesNoNesting on PaymentPreviousValues {
  id
  createdAt
  updatedAt
  amount
  status
}
`

export const AggregateForwardOfferNoNestingFragment = `fragment AggregateForwardOfferNoNesting on AggregateForwardOffer {
  count
}
`

export const PaymentSubscriptionPayloadNoNestingFragment = `fragment PaymentSubscriptionPayloadNoNesting on PaymentSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateLogedInNoNestingFragment = `fragment AggregateLogedInNoNesting on AggregateLogedIn {
  count
}
`

export const AggregateNotificationTypeNoNestingFragment = `fragment AggregateNotificationTypeNoNesting on AggregateNotificationType {
  count
}
`

export const PlanEdgeNoNestingFragment = `fragment PlanEdgeNoNesting on PlanEdge {
  cursor
}
`

export const AggregatePartnerFnNoNestingFragment = `fragment AggregatePartnerFnNoNesting on AggregatePartnerFn {
  count
}
`

export const PlanPreviousValuesNoNestingFragment = `fragment PlanPreviousValuesNoNesting on PlanPreviousValues {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const AggregatePaymentNoNestingFragment = `fragment AggregatePaymentNoNesting on AggregatePayment {
  count
}
`

export const PlanSubscriptionPayloadNoNestingFragment = `fragment PlanSubscriptionPayloadNoNesting on PlanSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregatePollNoNestingFragment = `fragment AggregatePollNoNesting on AggregatePoll {
  count
}
`

export const AggregatePollQuestionItemNoNestingFragment = `fragment AggregatePollQuestionItemNoNesting on AggregatePollQuestionItem {
  count
}
`

export const PollEdgeNoNestingFragment = `fragment PollEdgeNoNesting on PollEdge {
  cursor
}
`

export const AggregatePollResultAnswerNoNestingFragment = `fragment AggregatePollResultAnswerNoNesting on AggregatePollResultAnswer {
  count
}
`

export const PollPreviousValuesNoNestingFragment = `fragment PollPreviousValuesNoNesting on PollPreviousValues {
  id
  name
  description
  content
  points
  leadTime
  secret
  startAt
  endAt
  status
  createdAt
  updatedAt
}
`

export const AggregateReceiptNoNestingFragment = `fragment AggregateReceiptNoNesting on AggregateReceipt {
  count
}
`

export const AggregateReceiptParserOperatorNoNestingFragment = `fragment AggregateReceiptParserOperatorNoNesting on AggregateReceiptParserOperator {
  count
}
`

export const PollQuestionEdgeNoNestingFragment = `fragment PollQuestionEdgeNoNesting on PollQuestionEdge {
  cursor
}
`

export const AggregateRegistrationNoNestingFragment = `fragment AggregateRegistrationNoNesting on AggregateRegistration {
  count
}
`

export const AggregateResetPasswordNoNestingFragment = `fragment AggregateResetPasswordNoNesting on AggregateResetPassword {
  count
}
`

export const PollQuestionItemEdgeNoNestingFragment = `fragment PollQuestionItemEdgeNoNesting on PollQuestionItemEdge {
  cursor
}
`

export const AggregateSegmentNoNestingFragment = `fragment AggregateSegmentNoNesting on AggregateSegment {
  count
}
`

export const PollQuestionItemPreviousValuesNoNestingFragment = `fragment PollQuestionItemPreviousValuesNoNesting on PollQuestionItemPreviousValues {
  id
  name
}
`

export const AggregateSegmentCriteriaTypeNoNestingFragment = `fragment AggregateSegmentCriteriaTypeNoNesting on AggregateSegmentCriteriaType {
  count
}
`

export const PollQuestionItemSubscriptionPayloadNoNestingFragment = `fragment PollQuestionItemSubscriptionPayloadNoNesting on PollQuestionItemSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateSurveymonkeySurveyWebhookNoNestingFragment = `fragment AggregateSurveymonkeySurveyWebhookNoNesting on AggregateSurveymonkeySurveyWebhook {
  count
}
`

export const PollQuestionPreviousValuesNoNestingFragment = `fragment PollQuestionPreviousValuesNoNesting on PollQuestionPreviousValues {
  id
  name
  type
}
`

export const AggregateTransactionNoNestingFragment = `fragment AggregateTransactionNoNesting on AggregateTransaction {
  count
}
`

export const PollQuestionSubscriptionPayloadNoNestingFragment = `fragment PollQuestionSubscriptionPayloadNoNesting on PollQuestionSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateUserGroupNoNestingFragment = `fragment AggregateUserGroupNoNesting on AggregateUserGroup {
  count
}
`

export const AggregateVerificationNoNestingFragment = `fragment AggregateVerificationNoNesting on AggregateVerification {
  count
}
`

export const PollResultAnswerEdgeNoNestingFragment = `fragment PollResultAnswerEdgeNoNesting on PollResultAnswerEdge {
  cursor
}
`

export const SegmentCriteriaTypeNoNestingFragment = `fragment SegmentCriteriaTypeNoNesting on SegmentCriteriaType {
  id
  name
  isBetween
  isAttachPartners
}
`

export const PollResultAnswerPreviousValuesNoNestingFragment = `fragment PollResultAnswerPreviousValuesNoNesting on PollResultAnswerPreviousValues {
  id
}
`

export const PromotionNoNestingFragment = `fragment PromotionNoNesting on Promotion {
  id
  name
  description
  preview
  text
  bonus
  checkInText
  checkInPoints
  maxProducts
  active
  startAt
  endAt
  createdAt
  updatedAt
  type
  partnerName
  partnerId
  thumb
}
`

export const PollResultAnswerSubscriptionPayloadNoNestingFragment = `fragment PollResultAnswerSubscriptionPayloadNoNesting on PollResultAnswerSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ReceiptNoNestingFragment = `fragment ReceiptNoNesting on Receipt {
  id
  hash
  photo
  s
  fn
  i
  fp
  t
  n
  parserAttempts
  parserStatus
  parserData
  createdAt
  updatedAt
  externalKey
}
`

export const K12PhoneNoNestingFragment = `fragment K12PhoneNoNesting on K12Phone {
  id
  phone
  cash
  points
  freezePoints
  traffic
  minutes
  sms
  activatedAt
  createdAt
  updatedAt
  deletedAt
  promotional
  status
  IMSI
  allPoints
  ID_CLIENT_INST
  ID_CONTRACT_INST
  ID_SERVICE_INST
}
`

export const PollResultEdgeNoNestingFragment = `fragment PollResultEdgeNoNesting on PollResultEdge {
  cursor
}
`

export const PollQuestionNoNestingFragment = `fragment PollQuestionNoNesting on PollQuestion {
  id
  name
  type
}
`

export const PollResultPreviousValuesNoNestingFragment = `fragment PollResultPreviousValuesNoNesting on PollResultPreviousValues {
  id
  startedAt
  endedAt
  status
  createdAt
  updatedAt
}
`

export const RequestNoNestingFragment = `fragment RequestNoNesting on Request {
  id
  name
  phone
  status
  createdAt
  updatedAt
  deletedAt
}
`

export const PollResultSubscriptionPayloadNoNestingFragment = `fragment PollResultSubscriptionPayloadNoNesting on PollResultSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ForwardClientNoNestingFragment = `fragment ForwardClientNoNesting on ForwardClient {
  id
  externalKey
  B_CHECK
  newPhotoRequested
  newPhotoRequestedMessage
}
`

export const PollSubscriptionPayloadNoNestingFragment = `fragment PollSubscriptionPayloadNoNesting on PollSubscriptionPayload {
  mutation
  updatedFields
}
`

export const PushNotificationNoNestingFragment = `fragment PushNotificationNoNesting on PushNotification {
  id
  createdAt
  updatedAt
  ticketId
  message
  hasErrors
  ticketError
  receiptError
}
`

export const UserGroupNoNestingFragment = `fragment UserGroupNoNesting on UserGroup {
  id
  createdAt
  updatedAt
  name
}
`

export const ProjectEdgeNoNestingFragment = `fragment ProjectEdgeNoNesting on ProjectEdge {
  cursor
}
`

export const BotNoNestingFragment = `fragment BotNoNesting on Bot {
  id
  createdAt
  updatedAt
  type
  name
}
`

export const ProjectPreviousValuesNoNestingFragment = `fragment ProjectPreviousValuesNoNesting on ProjectPreviousValues {
  id
  createdAt
  updatedAt
  name
  domain
  description
  url
}
`

export const CheckInfoNoNestingFragment = `fragment CheckInfoNoNesting on CheckInfo {
  externalKey
  sum
  status
  points
}
`

export const ProjectSubscriptionPayloadNoNestingFragment = `fragment ProjectSubscriptionPayloadNoNesting on ProjectSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ArticleEdgeNoNestingFragment = `fragment ArticleEdgeNoNesting on ArticleEdge {
  cursor
}
`

export const PromotionPreviousValuesNoNestingFragment = `fragment PromotionPreviousValuesNoNesting on PromotionPreviousValues {
  id
  name
  description
  preview
  text
  bonus
  checkInText
  checkInPoints
  maxProducts
  active
  startAt
  endAt
  createdAt
  updatedAt
}
`

export const AggregateCouponNoNestingFragment = `fragment AggregateCouponNoNesting on AggregateCoupon {
  count
}
`

export const PromotionSubscriptionPayloadNoNestingFragment = `fragment PromotionSubscriptionPayloadNoNesting on PromotionSubscriptionPayload {
  mutation
  updatedFields
}
`

export const PageNoNestingFragment = `fragment PageNoNesting on Page {
  id
  key
  title
  content
}
`

export const PushNotificationPreviousValuesNoNestingFragment = `fragment PushNotificationPreviousValuesNoNesting on PushNotificationPreviousValues {
  id
  createdAt
  updatedAt
  ticketId
  message
  hasErrors
  ticketError
  receiptError
}
`

export const PushNotificationQueuePreviousValuesNoNestingFragment = `fragment PushNotificationQueuePreviousValuesNoNesting on PushNotificationQueuePreviousValues {
  id
  createdAt
  updatedAt
  message
}
`

export const PartnerTypeEdgeNoNestingFragment = `fragment PartnerTypeEdgeNoNesting on PartnerTypeEdge {
  cursor
}
`

export const PushNotificationQueueSubscriptionPayloadNoNestingFragment = `fragment PushNotificationQueueSubscriptionPayloadNoNesting on PushNotificationQueueSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregatePromotionNoNestingFragment = `fragment AggregatePromotionNoNesting on AggregatePromotion {
  count
}
`

export const PushNotificationSubscriptionPayloadNoNestingFragment = `fragment PushNotificationSubscriptionPayloadNoNesting on PushNotificationSubscriptionPayload {
  mutation
  updatedFields
}
`

export const SettingsNoNestingFragment = `fragment SettingsNoNesting on Settings {
  supportPhone
  livechatLicense
  telegram
  facebook
  viber
  politics
  rules
  siteUrl
  botGreetingsMessage
  fnsAttempts
  fnsDelay
  ofdAttempts
  ofdDelay
}
`

export const ReceiptEdgeNoNestingFragment = `fragment ReceiptEdgeNoNesting on ReceiptEdge {
  cursor
}
`

export const SurveymonkeyCollectorNoNestingFragment = `fragment SurveymonkeyCollectorNoNesting on SurveymonkeyCollector {
  id
  createdAt
  updatedAt
  externalKey
  name
  hash
  url
  href
  status
  type
}
`

export const SurveymonkeyUserNoNestingFragment = `fragment SurveymonkeyUserNoNesting on SurveymonkeyUser {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  email_address
}
`

export const ReceiptParserLogEdgeNoNestingFragment = `fragment ReceiptParserLogEdgeNoNesting on ReceiptParserLogEdge {
  cursor
}
`

export const ReceiptParserLogPreviousValuesNoNestingFragment = `fragment ReceiptParserLogPreviousValuesNoNesting on ReceiptParserLogPreviousValues {
  id
  createdAt
}
`

export const SurveymonkeyResponseEdgeNoNestingFragment = `fragment SurveymonkeyResponseEdgeNoNesting on SurveymonkeyResponseEdge {
  cursor
}
`

export const ReceiptParserLogSubscriptionPayloadNoNestingFragment = `fragment ReceiptParserLogSubscriptionPayloadNoNesting on ReceiptParserLogSubscriptionPayload {
  mutation
  updatedFields
}
`

export const BillingEdgeNoNestingFragment = `fragment BillingEdgeNoNesting on BillingEdge {
  cursor
}
`

export const AggregateK12PhoneNoNestingFragment = `fragment AggregateK12PhoneNoNesting on AggregateK12Phone {
  count
}
`

export const ReceiptParserOperatorEdgeNoNestingFragment = `fragment ReceiptParserOperatorEdgeNoNesting on ReceiptParserOperatorEdge {
  cursor
}
`

export const AggregateNewsletterNoNestingFragment = `fragment AggregateNewsletterNoNesting on AggregateNewsletter {
  count
}
`

export const ReceiptParserOperatorPreviousValuesNoNestingFragment = `fragment ReceiptParserOperatorPreviousValuesNoNesting on ReceiptParserOperatorPreviousValues {
  id
  createdAt
  updatedAt
  name
  code
}
`

export const AggregateFileNoNestingFragment = `fragment AggregateFileNoNesting on AggregateFile {
  count
}
`

export const ReceiptParserOperatorSubscriptionPayloadNoNestingFragment = `fragment ReceiptParserOperatorSubscriptionPayloadNoNesting on ReceiptParserOperatorSubscriptionPayload {
  mutation
  updatedFields
}
`

export const TemplateNoNestingFragment = `fragment TemplateNoNesting on Template {
  id
  createdAt
  updatedAt
  externalKey
  name
  description
  component
  props
  components
  vars
  rank
}
`

export const ReceiptParserResultNoNestingFragment = `fragment ReceiptParserResultNoNesting on ReceiptParserResult {
  id
  createdAt
  updatedAt
}
`

export const AggregateTemplateNoNestingFragment = `fragment AggregateTemplateNoNesting on AggregateTemplate {
  count
}
`

export const ReceiptParserResultEdgeNoNestingFragment = `fragment ReceiptParserResultEdgeNoNesting on ReceiptParserResultEdge {
  cursor
}
`

export const CheckNoNestingFragment = `fragment CheckNoNesting on Check {
  externalKey
  sum
}
`

export const ReceiptParserResultPreviousValuesNoNestingFragment = `fragment ReceiptParserResultPreviousValuesNoNesting on ReceiptParserResultPreviousValues {
  id
  createdAt
  updatedAt
}
`

export const AggregateForwardClientNoNestingFragment = `fragment AggregateForwardClientNoNesting on AggregateForwardClient {
  count
}
`

export const ReceiptParserResultSubscriptionPayloadNoNestingFragment = `fragment ReceiptParserResultSubscriptionPayloadNoNesting on ReceiptParserResultSubscriptionPayload {
  mutation
  updatedFields
}
`

export const TarifInfoExtensionNoNestingFragment = `fragment TarifInfoExtensionNoNesting on TarifInfoExtension {
  ID_EXTENSION
  V_EXTENSION_NAME
  N_EXTENSION_PRICE_RUB
  N_EXTENSION_PRICE_BAL
  B_EXTENSION_CONNECT
}
`

export const ReceiptPreviousValuesNoNestingFragment = `fragment ReceiptPreviousValuesNoNesting on ReceiptPreviousValues {
  id
  hash
  photo
  s
  fn
  i
  fp
  t
  n
  parserAttempts
  parserStatus
  parserData
  createdAt
  updatedAt
  externalKey
}
`

export const SurveymonkeyImportSurveysResponseNoNestingFragment = `fragment SurveymonkeyImportSurveysResponseNoNesting on SurveymonkeyImportSurveysResponse {
  created
  updated
}
`

export const ReceiptSubscriptionPayloadNoNestingFragment = `fragment ReceiptSubscriptionPayloadNoNesting on ReceiptSubscriptionPayload {
  mutation
  updatedFields
}
`

export const BuyExtensionResultNoNestingFragment = `fragment BuyExtensionResultNoNesting on BuyExtensionResult {
  N_ERROR
  V_ERROR
}
`

export const PartnerProductResponseNoNestingFragment = `fragment PartnerProductResponseNoNesting on PartnerProductResponse {
  success
  message
}
`

export const RegistrationEdgeNoNestingFragment = `fragment RegistrationEdgeNoNesting on RegistrationEdge {
  cursor
}
`

export const SberbankRegisterNoNestingFragment = `fragment SberbankRegisterNoNesting on SberbankRegister {
  orderId
  formUrl
}
`

export const RegistrationPreviousValuesNoNestingFragment = `fragment RegistrationPreviousValuesNoNesting on RegistrationPreviousValues {
  id
  firstName
  secondName
  lastName
  name
  email
  birthday
  isUnderage
  photoPassport
  photoRegistration
  photoSelfie
  status
  comment
  isLoginAttempt
  createdAt
  updatedAt
}
`

export const LoginPayloadNoNestingFragment = `fragment LoginPayloadNoNesting on LoginPayload {
  token
}
`

export const RegistrationSubscriptionPayloadNoNestingFragment = `fragment RegistrationSubscriptionPayloadNoNesting on RegistrationSubscriptionPayload {
  mutation
  updatedFields
}
`

export const K12PhoneResponseNoNestingFragment = `fragment K12PhoneResponseNoNesting on K12PhoneResponse {
  success
  message
}
`

export const SavedCheckNoNestingFragment = `fragment SavedCheckNoNesting on SavedCheck {
  ID_CHECK
}
`

export const RequestEdgeNoNestingFragment = `fragment RequestEdgeNoNesting on RequestEdge {
  cursor
}
`

export const BotMailingResponseNoNestingFragment = `fragment BotMailingResponseNoNesting on BotMailingResponse {
  success
  message
}
`

export const RequestPreviousValuesNoNestingFragment = `fragment RequestPreviousValuesNoNesting on RequestPreviousValues {
  id
  name
  phone
  status
  createdAt
  updatedAt
}
`

export const IMSIResponseNoNestingFragment = `fragment IMSIResponseNoNesting on IMSIResponse {
  success
  message
}
`

export const RequestSubscriptionPayloadNoNestingFragment = `fragment RequestSubscriptionPayloadNoNesting on RequestSubscriptionPayload {
  mutation
  updatedFields
}
`

export const TemplatePreviousValuesNoNestingFragment = `fragment TemplatePreviousValuesNoNesting on TemplatePreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  name
  description
  component
  props
  components
  vars
  rank
}
`

export const ResetPasswordNoNestingFragment = `fragment ResetPasswordNoNesting on ResetPassword {
  id
  createdAt
  updatedAt
  code
  password
  validTill
}
`

export const AggregateDeviceNoNestingFragment = `fragment AggregateDeviceNoNesting on AggregateDevice {
  count
}
`

export const AggregateLogNoNestingFragment = `fragment AggregateLogNoNesting on AggregateLog {
  count
}
`

export const ResetPasswordEdgeNoNestingFragment = `fragment ResetPasswordEdgeNoNesting on ResetPasswordEdge {
  cursor
}
`

export const AggregatePartnerCategoryNoNestingFragment = `fragment AggregatePartnerCategoryNoNesting on AggregatePartnerCategory {
  count
}
`

export const ResetPasswordPreviousValuesNoNestingFragment = `fragment ResetPasswordPreviousValuesNoNesting on ResetPasswordPreviousValues {
  id
  createdAt
  updatedAt
  code
  password
  validTill
}
`

export const AggregatePlanNoNestingFragment = `fragment AggregatePlanNoNesting on AggregatePlan {
  count
}
`

export const ResetPasswordSubscriptionPayloadNoNestingFragment = `fragment ResetPasswordSubscriptionPayloadNoNesting on ResetPasswordSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregatePollResultNoNestingFragment = `fragment AggregatePollResultNoNesting on AggregatePollResult {
  count
}
`

export const AggregateReceiptParserLogNoNestingFragment = `fragment AggregateReceiptParserLogNoNesting on AggregateReceiptParserLog {
  count
}
`

export const SBOrderEdgeNoNestingFragment = `fragment SBOrderEdgeNoNesting on SBOrderEdge {
  cursor
}
`

export const AggregateRequestNoNestingFragment = `fragment AggregateRequestNoNesting on AggregateRequest {
  count
}
`

export const SBOrderPreviousValuesNoNestingFragment = `fragment SBOrderPreviousValuesNoNesting on SBOrderPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  number
  orderStatus
  amount
  currency
  date
  paymentAmountInfo
}
`

export const AggregateSegmentCriteriaNoNestingFragment = `fragment AggregateSegmentCriteriaNoNesting on AggregateSegmentCriteria {
  count
}
`

export const SBOrderSubscriptionPayloadNoNestingFragment = `fragment SBOrderSubscriptionPayloadNoNesting on SBOrderSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateSurveymonkeyUserNoNestingFragment = `fragment AggregateSurveymonkeyUserNoNesting on AggregateSurveymonkeyUser {
  count
}
`

export const AggregateUserLogNoNestingFragment = `fragment AggregateUserLogNoNesting on AggregateUserLog {
  count
}
`

export const SegmentEdgeNoNestingFragment = `fragment SegmentEdgeNoNesting on SegmentEdge {
  cursor
}
`

export const PartnerCategoryNoNestingFragment = `fragment PartnerCategoryNoNesting on PartnerCategory {
  id
  name
  createdAt
  updatedAt
  level
}
`

export const ReceiptParserLogNoNestingFragment = `fragment ReceiptParserLogNoNesting on ReceiptParserLog {
  id
  createdAt
}
`

export const SegmentCriteriaEdgeNoNestingFragment = `fragment SegmentCriteriaEdgeNoNesting on SegmentCriteriaEdge {
  cursor
}
`

export const PollResultAnswerNoNestingFragment = `fragment PollResultAnswerNoNesting on PollResultAnswer {
  id
}
`

export const SegmentCriteriaPreviousValuesNoNestingFragment = `fragment SegmentCriteriaPreviousValuesNoNesting on SegmentCriteriaPreviousValues {
  id
  from
  to
}
`

export const ForwardContractServiceNoNestingFragment = `fragment ForwardContractServiceNoNesting on ForwardContractService {
  id
  externalKey
}
`

export const SegmentCriteriaSubscriptionPayloadNoNestingFragment = `fragment SegmentCriteriaSubscriptionPayloadNoNesting on SegmentCriteriaSubscriptionPayload {
  mutation
  updatedFields
}
`

export const BillingNoNestingFragment = `fragment BillingNoNesting on Billing {
  id
  name
  points
  cash
  traffic
  minutes
  sms
  createdAt
  updatedAt
  type
}
`

export const SegmentCriteriaTypeEdgeNoNestingFragment = `fragment SegmentCriteriaTypeEdgeNoNesting on SegmentCriteriaTypeEdge {
  cursor
}
`

export const PackageEdgeNoNestingFragment = `fragment PackageEdgeNoNesting on PackageEdge {
  cursor
}
`

export const SegmentCriteriaTypePreviousValuesNoNestingFragment = `fragment SegmentCriteriaTypePreviousValuesNoNesting on SegmentCriteriaTypePreviousValues {
  id
  name
  isBetween
  isAttachPartners
}
`

export const AggregatePartnerNoNestingFragment = `fragment AggregatePartnerNoNesting on AggregatePartner {
  count
}
`

export const SegmentCriteriaTypeSubscriptionPayloadNoNestingFragment = `fragment SegmentCriteriaTypeSubscriptionPayloadNoNesting on SegmentCriteriaTypeSubscriptionPayload {
  mutation
  updatedFields
}
`

export const PushNotificationEdgeNoNestingFragment = `fragment PushNotificationEdgeNoNesting on PushNotificationEdge {
  cursor
}
`

export const SegmentPreviousValuesNoNestingFragment = `fragment SegmentPreviousValuesNoNesting on SegmentPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const SettingEdgeNoNestingFragment = `fragment SettingEdgeNoNesting on SettingEdge {
  cursor
}
`

export const SegmentSubscriptionPayloadNoNestingFragment = `fragment SegmentSubscriptionPayloadNoNesting on SegmentSubscriptionPayload {
  mutation
  updatedFields
}
`

export const SurveymonkeyCollectorEdgeNoNestingFragment = `fragment SurveymonkeyCollectorEdgeNoNesting on SurveymonkeyCollectorEdge {
  cursor
}
`

export const SurveyNoNestingFragment = `fragment SurveyNoNesting on Survey {
  id
  name
  description
  approximate_time
  points
  url
}
`

export const ServiceEdgeNoNestingFragment = `fragment ServiceEdgeNoNesting on ServiceEdge {
  cursor
}
`

export const ServicePreviousValuesNoNestingFragment = `fragment ServicePreviousValuesNoNesting on ServicePreviousValues {
  id
  name
  description
  image
  priceInPoints
  priceInCash
  priceFeePoints
  priceFeeCash
  default
  active
  createdAt
  updatedAt
}
`

export const UserEdgeNoNestingFragment = `fragment UserEdgeNoNesting on UserEdge {
  cursor
}
`

export const ServiceSubscriptionPayloadNoNestingFragment = `fragment ServiceSubscriptionPayloadNoNesting on ServiceSubscriptionPayload {
  mutation
  updatedFields
}
`

export const BotMailingEdgeNoNestingFragment = `fragment BotMailingEdgeNoNesting on BotMailingEdge {
  cursor
}
`

export const SettingPreviousValuesNoNestingFragment = `fragment SettingPreviousValuesNoNesting on SettingPreviousValues {
  id
  name
  key
  value
  group
}
`

export const SettingSubscriptionPayloadNoNestingFragment = `fragment SettingSubscriptionPayloadNoNesting on SettingSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ErrorNoNestingFragment = `fragment ErrorNoNesting on Error {
  key
  message
}
`

export const SurveymonkeyCollectorPreviousValuesNoNestingFragment = `fragment SurveymonkeyCollectorPreviousValuesNoNesting on SurveymonkeyCollectorPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  name
  hash
  url
  href
  status
  type
}
`

export const PartnerResponseNoNestingFragment = `fragment PartnerResponseNoNesting on PartnerResponse {
  success
  message
}
`

export const SurveymonkeyCollectorResponseNoNestingFragment = `fragment SurveymonkeyCollectorResponseNoNesting on SurveymonkeyCollectorResponse {
  success
  message
}
`

export const PaymentNoNestingFragment = `fragment PaymentNoNesting on Payment {
  id
  createdAt
  updatedAt
  amount
  status
}
`

export const SurveymonkeyCollectorSubscriptionPayloadNoNestingFragment = `fragment SurveymonkeyCollectorSubscriptionPayloadNoNesting on SurveymonkeyCollectorSubscriptionPayload {
  mutation
  updatedFields
}
`

export const ForwardCheckNoNestingFragment = `fragment ForwardCheckNoNesting on ForwardCheck {
  ID_CHECK
}
`

export const SurveymonkeyResponsePreviousValuesNoNestingFragment = `fragment SurveymonkeyResponsePreviousValuesNoNesting on SurveymonkeyResponsePreviousValues {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  collector_id
  recipient_id
  total_time
  custom_value
  edit_url
  analyze_url
  ip_address
  custom_variables
  logic_path
  metadata
  response_status
  collection_mode
  href
  respondent_id
  points
  points_applyed
}
`

export const SignContractResponseNoNestingFragment = `fragment SignContractResponseNoNesting on SignContractResponse {
  F_PDF_CONTRACT
}
`

export const SurveymonkeyResponseSubscriptionPayloadNoNestingFragment = `fragment SurveymonkeyResponseSubscriptionPayloadNoNesting on SurveymonkeyResponseSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateBotChatNoNestingFragment = `fragment AggregateBotChatNoNesting on AggregateBotChat {
  count
}
`

export const SurveymonkeySurveyPreviousValuesNoNestingFragment = `fragment SurveymonkeySurveyPreviousValuesNoNesting on SurveymonkeySurveyPreviousValues {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  name
  nickname
  href
  custom_variables
  active
  approximate_time
  points
  description
}
`

export const AggregateNewsletterJournalNoNestingFragment = `fragment AggregateNewsletterJournalNoNesting on AggregateNewsletterJournal {
  count
}
`

export const SurveymonkeySurveySubscriptionPayloadNoNestingFragment = `fragment SurveymonkeySurveySubscriptionPayloadNoNesting on SurveymonkeySurveySubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregatePollQuestionNoNestingFragment = `fragment AggregatePollQuestionNoNesting on AggregatePollQuestion {
  count
}
`

export const AggregateReceiptParserResultNoNestingFragment = `fragment AggregateReceiptParserResultNoNesting on AggregateReceiptParserResult {
  count
}
`

export const SurveymonkeySurveyWebhookEdgeNoNestingFragment = `fragment SurveymonkeySurveyWebhookEdgeNoNesting on SurveymonkeySurveyWebhookEdge {
  cursor
}
`

export const AggregateServiceNoNestingFragment = `fragment AggregateServiceNoNesting on AggregateService {
  count
}
`

export const SurveymonkeySurveyWebhookPreviousValuesNoNestingFragment = `fragment SurveymonkeySurveyWebhookPreviousValuesNoNesting on SurveymonkeySurveyWebhookPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  name
  event_type
  subscription_url
  object_type
  href
}
`

export const SegmentNoNestingFragment = `fragment SegmentNoNesting on Segment {
  id
  name
  createdAt
  updatedAt
}
`

export const SurveymonkeySurveyWebhookSubscriptionPayloadNoNestingFragment = `fragment SurveymonkeySurveyWebhookSubscriptionPayloadNoNesting on SurveymonkeySurveyWebhookSubscriptionPayload {
  mutation
  updatedFields
}
`

export const PollResultNoNestingFragment = `fragment PollResultNoNesting on PollResult {
  id
  startedAt
  endedAt
  status
  createdAt
  updatedAt
}
`

export const NotificationTypeNoNestingFragment = `fragment NotificationTypeNoNesting on NotificationType {
  id
  name
}
`

export const SurveymonkeyUserEdgeNoNestingFragment = `fragment SurveymonkeyUserEdgeNoNesting on SurveymonkeyUserEdge {
  cursor
}
`

export const SurveymonkeyUserPreviousValuesNoNestingFragment = `fragment SurveymonkeyUserPreviousValuesNoNesting on SurveymonkeyUserPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  email_address
}
`

export const SurveymonkeyUserSubscriptionPayloadNoNestingFragment = `fragment SurveymonkeyUserSubscriptionPayloadNoNesting on SurveymonkeyUserSubscriptionPayload {
  mutation
  updatedFields
}
`

export const SurveymonkeySurveyWebhookNoNestingFragment = `fragment SurveymonkeySurveyWebhookNoNesting on SurveymonkeySurveyWebhook {
  id
  createdAt
  updatedAt
  externalKey
  name
  event_type
  subscription_url
  object_type
  href
}
`

export const TransactionEdgeNoNestingFragment = `fragment TransactionEdgeNoNesting on TransactionEdge {
  cursor
}
`

export const TransactionPreviousValuesNoNestingFragment = `fragment TransactionPreviousValuesNoNesting on TransactionPreviousValues {
  id
  points
  comment
  status
  createdAt
  updatedAt
  deletedAt
}
`

export const TarifInfoNoNestingFragment = `fragment TarifInfoNoNesting on TarifInfo {
  ID_SERVICE
  V_SERVICE
  N_SERVICE_PRICE_RUB
  N_SERVICE_PRICE_BAL
  B_SERVICE_CONNECT
}
`

export const PromotionResponseNoNestingFragment = `fragment PromotionResponseNoNesting on PromotionResponse {
  success
  message
}
`

export const TransactionPromotionEdgeNoNestingFragment = `fragment TransactionPromotionEdgeNoNesting on TransactionPromotionEdge {
  cursor
}
`

export const UserResponseNoNestingFragment = `fragment UserResponseNoNesting on UserResponse {
  success
  message
}
`

export const TransactionPromotionPreviousValuesNoNestingFragment = `fragment TransactionPromotionPreviousValuesNoNesting on TransactionPromotionPreviousValues {
  id
  createdAt
  updatedAt
  amount
  coefficient
  points
}
`

export const AggregateForwardContractServiceNoNestingFragment = `fragment AggregateForwardContractServiceNoNesting on AggregateForwardContractService {
  count
}
`

export const TransactionPromotionResponseNoNestingFragment = `fragment TransactionPromotionResponseNoNesting on TransactionPromotionResponse {
  success
  message
}
`

export const AggregateProjectNoNestingFragment = `fragment AggregateProjectNoNesting on AggregateProject {
  count
}
`

export const TransactionPromotionSubscriptionPayloadNoNestingFragment = `fragment TransactionPromotionSubscriptionPayloadNoNesting on TransactionPromotionSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AggregateTransactionPromotionNoNestingFragment = `fragment AggregateTransactionPromotionNoNesting on AggregateTransactionPromotion {
  count
}
`

export const TransactionSubscriptionPayloadNoNestingFragment = `fragment TransactionSubscriptionPayloadNoNesting on TransactionSubscriptionPayload {
  mutation
  updatedFields
}
`

export const RegistrationNoNestingFragment = `fragment RegistrationNoNesting on Registration {
  id
  firstName
  secondName
  lastName
  name
  email
  birthday
  isUnderage
  photoPassport
  photoRegistration
  photoSelfie
  status
  comment
  isLoginAttempt
  createdAt
  updatedAt
}
`

export const PageEdgeNoNestingFragment = `fragment PageEdgeNoNesting on PageEdge {
  cursor
}
`

export const UserGroupEdgeNoNestingFragment = `fragment UserGroupEdgeNoNesting on UserGroupEdge {
  cursor
}
`

export const AggregateSurveymonkeySurveyNoNestingFragment = `fragment AggregateSurveymonkeySurveyNoNesting on AggregateSurveymonkeySurvey {
  count
}
`

export const UserGroupPreviousValuesNoNestingFragment = `fragment UserGroupPreviousValuesNoNesting on UserGroupPreviousValues {
  id
  createdAt
  updatedAt
  name
}
`

export const AggregateBotProfileNoNestingFragment = `fragment AggregateBotProfileNoNesting on AggregateBotProfile {
  count
}
`

export const UserGroupSubscriptionPayloadNoNestingFragment = `fragment UserGroupSubscriptionPayloadNoNesting on UserGroupSubscriptionPayload {
  mutation
  updatedFields
}
`

export const AuthDeviceNoNestingFragment = `fragment AuthDeviceNoNesting on AuthDevice {
  token
}
`

export const AggregatePartnerProductNoNestingFragment = `fragment AggregatePartnerProductNoNesting on AggregatePartnerProduct {
  count
}
`

export const UserLogEdgeNoNestingFragment = `fragment UserLogEdgeNoNesting on UserLogEdge {
  cursor
}
`

export const CouponNoNestingFragment = `fragment CouponNoNesting on Coupon {
  id
  createdAt
  updatedAt
  name
  conditions
  description
  content
  url
  preview
  canComplain
  startAt
  endAt
  published
}
`

export const UserLogPreviousValuesNoNestingFragment = `fragment UserLogPreviousValuesNoNesting on UserLogPreviousValues {
  id
  action
  value
}
`

export const AggregatePushNotificationQueueNoNestingFragment = `fragment AggregatePushNotificationQueueNoNesting on AggregatePushNotificationQueue {
  count
}
`

export const CouponResponseNoNestingFragment = `fragment CouponResponseNoNesting on CouponResponse {
  success
  message
}
`

export const VerificationPreviousValuesNoNestingFragment = `fragment VerificationPreviousValuesNoNesting on VerificationPreviousValues {
  id
  hash
  attempts
  refetchAttempts
  createdAt
  updatedAt
}
`

export const VerificationEdgeNoNestingFragment = `fragment VerificationEdgeNoNesting on VerificationEdge {
  cursor
}
`

export const UserLogSubscriptionPayloadNoNestingFragment = `fragment UserLogSubscriptionPayloadNoNesting on UserLogSubscriptionPayload {
  mutation
  updatedFields
}
`

export const UserPreviousValuesNoNestingFragment = `fragment UserPreviousValuesNoNesting on UserPreviousValues {
  id
  login
  email
  name
  firstName
  secondName
  lastName
  birthday
  role
  status
  password
  loginAttempts
  blockedAt
  createdAt
  updatedAt
  deletedAt
  marketplaceToken
  username
  phone
  fullname
  image
  address
  hidden
  sudo
  showEmail
  showPhone
}
`

export const BotMailingNoNestingFragment = `fragment BotMailingNoNesting on BotMailing {
  id
  createdAt
  updatedAt
  name
  message
  status
}
`

export const AggregateSBOrderNoNestingFragment = `fragment AggregateSBOrderNoNesting on AggregateSBOrder {
  count
}
`


export const ArticlePreviousValuesDeepNestingFragment = `fragment ArticlePreviousValuesDeepNesting on ArticlePreviousValues {
  id
  name
  description
  content
  preview
  published
  publishedAt
  createdAt
  updatedAt
}
`

export const VerificationSubscriptionPayloadDeepNestingFragment = `fragment VerificationSubscriptionPayloadDeepNesting on VerificationSubscriptionPayload {
  mutation
  node {
    ...VerificationDeepNesting
  }
  updatedFields
  previousValues {
    ...VerificationPreviousValuesDeepNesting
  }
}
`

export const ArticleSubscriptionPayloadDeepNestingFragment = `fragment ArticleSubscriptionPayloadDeepNesting on ArticleSubscriptionPayload {
  mutation
  node {
    ...ArticleDeepNesting
  }
  updatedFields
  previousValues {
    ...ArticlePreviousValuesDeepNesting
  }
}
`

export const ArticleDeepNestingFragment = `fragment ArticleDeepNesting on Article {
  id
  name
  description
  content
  preview
  segments {
    ...SegmentDeepNesting
  }
  published
  publishedAt
  createdAt
  updatedAt
  thumb
}
`

export const BillingPreviousValuesDeepNestingFragment = `fragment BillingPreviousValuesDeepNesting on BillingPreviousValues {
  id
  name
  points
  cash
  traffic
  minutes
  sms
  createdAt
  updatedAt
}
`

export const SegmentCriteriaDeepNestingFragment = `fragment SegmentCriteriaDeepNesting on SegmentCriteria {
  id
  segment {
    ...SegmentDeepNesting
  }
  type {
    ...SegmentCriteriaTypeDeepNesting
  }
  from
  to
  partners {
    ...PartnerDeepNesting
  }
}
`

export const BillingSubscriptionPayloadDeepNestingFragment = `fragment BillingSubscriptionPayloadDeepNesting on BillingSubscriptionPayload {
  mutation
  node {
    ...BillingDeepNesting
  }
  updatedFields
  previousValues {
    ...BillingPreviousValuesDeepNesting
  }
}
`

export const PartnerDeepNestingFragment = `fragment PartnerDeepNesting on Partner {
  id
  name
  logo
  address
  category {
    ...PartnerCategoryDeepNesting
  }
  type {
    ...PartnerTypeDeepNesting
  }
  promotions {
    ...PromotionDeepNesting
  }
  coupons {
    ...CouponDeepNesting
  }
  products {
    ...PartnerProductDeepNesting
  }
  fns {
    ...PartnerFnDeepNesting
  }
  active
  createdAt
  updatedAt
  logoThumb
  typeId
}
`

export const BotChatDeepNestingFragment = `fragment BotChatDeepNesting on BotChat {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  username
  type
  text
  entities
}
`

export const PartnerTypeDeepNestingFragment = `fragment PartnerTypeDeepNesting on PartnerType {
  id
  name
  daysToConfirm
  active
  createdAt
  updatedAt
}
`

export const BotChatConnectionDeepNestingFragment = `fragment BotChatConnectionDeepNesting on BotChatConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...BotChatEdgeDeepNesting
  }
  aggregate {
    ...AggregateBotChatDeepNesting
  }
}
`

export const PartnerProductDeepNestingFragment = `fragment PartnerProductDeepNesting on PartnerProduct {
  id
  name
  partner {
    ...PartnerDeepNesting
  }
  createdAt
  updatedAt
}
`

export const BotChatEdgeDeepNestingFragment = `fragment BotChatEdgeDeepNesting on BotChatEdge {
  node {
    ...BotChatDeepNesting
  }
  cursor
}
`

export const PartnerFnDeepNestingFragment = `fragment PartnerFnDeepNesting on PartnerFn {
  id
  name
  partner {
    ...PartnerDeepNesting
  }
  createdAt
  updatedAt
  Receipts {
    ...ReceiptDeepNesting
  }
}
`

export const BotChatPreviousValuesDeepNestingFragment = `fragment BotChatPreviousValuesDeepNesting on BotChatPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  username
  type
  text
  entities
}
`

export const ReceiptParserOperatorDeepNestingFragment = `fragment ReceiptParserOperatorDeepNesting on ReceiptParserOperator {
  id
  createdAt
  updatedAt
  name
  code
  Logs {
    ...ReceiptParserLogDeepNesting
  }
}
`

export const BotChatSubscriptionPayloadDeepNestingFragment = `fragment BotChatSubscriptionPayloadDeepNesting on BotChatSubscriptionPayload {
  mutation
  node {
    ...BotChatDeepNesting
  }
  updatedFields
  previousValues {
    ...BotChatPreviousValuesDeepNesting
  }
}
`

export const TransactionDeepNestingFragment = `fragment TransactionDeepNesting on Transaction {
  id
  k12Phone {
    ...K12PhoneDeepNesting
  }
  receipt {
    ...ReceiptDeepNesting
  }
  points
  comment
  status
  createdAt
  updatedAt
  deletedAt
  TransactionPromotion {
    ...TransactionPromotionDeepNesting
  }
  partner {
    ...PartnerDeepNesting
  }
}
`

export const BotConnectionDeepNestingFragment = `fragment BotConnectionDeepNesting on BotConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...BotEdgeDeepNesting
  }
  aggregate {
    ...AggregateBotDeepNesting
  }
}
`

export const UserDeepNestingFragment = `fragment UserDeepNesting on User {
  id
  login
  email
  name
  firstName
  secondName
  lastName
  birthday
  k12Phones {
    ...K12PhoneDeepNesting
  }
  pollResults {
    ...PollResultDeepNesting
  }
  role
  device {
    ...DeviceDeepNesting
  }
  notificationSettings {
    ...NotificationTypeDeepNesting
  }
  status
  password
  loginAttempts
  blockedAt
  createdAt
  updatedAt
  deletedAt
  Registrations {
    ...RegistrationDeepNesting
  }
  UserLogs {
    ...UserLogDeepNesting
  }
  marketplaceToken
  username
  phone
  fullname
  image
  address
  hidden
  sudo
  showEmail
  showPhone
  NotificationTypes {
    ...NotificationTypeDeepNesting
  }
  NotificationTypesCreated {
    ...NotificationTypeDeepNesting
  }
  Groups {
    ...UserGroupDeepNesting
  }
  k12Phone {
    ...K12PhoneDeepNesting
  }
  planName
  fullName
  isFirstEntry
  hasEmail
  hasPhone
}
`

export const BotEdgeDeepNestingFragment = `fragment BotEdgeDeepNesting on BotEdge {
  node {
    ...BotDeepNesting
  }
  cursor
}
`

export const PollDeepNestingFragment = `fragment PollDeepNesting on Poll {
  id
  name
  description
  content
  points
  leadTime
  secret
  startAt
  endAt
  questions {
    ...PollQuestionDeepNesting
  }
  segments {
    ...SegmentDeepNesting
  }
  status
  createdAt
  updatedAt
}
`

export const BotMailingMessageConnectionDeepNestingFragment = `fragment BotMailingMessageConnectionDeepNesting on BotMailingMessageConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...BotMailingMessageEdgeDeepNesting
  }
  aggregate {
    ...AggregateBotMailingMessageDeepNesting
  }
}
`

export const PollQuestionItemDeepNestingFragment = `fragment PollQuestionItemDeepNesting on PollQuestionItem {
  id
  question {
    ...PollQuestionDeepNesting
  }
  name
}
`

export const BotMailingMessageEdgeDeepNestingFragment = `fragment BotMailingMessageEdgeDeepNesting on BotMailingMessageEdge {
  node {
    ...BotMailingMessageDeepNesting
  }
  cursor
}
`

export const DeviceDeepNestingFragment = `fragment DeviceDeepNesting on Device {
  id
  token
  type
  pushErrors
  requests {
    ...RequestDeepNesting
  }
  registrations {
    ...RegistrationDeepNesting
  }
  createdAt
  updatedAt
  deletedAt
  Verifications {
    ...VerificationDeepNesting
  }
  Client {
    ...ForwardClientDeepNesting
  }
  Users {
    ...UserDeepNesting
  }
  PushNotifications {
    ...PushNotificationDeepNesting
  }
  K12Phones {
    ...K12PhoneDeepNesting
  }
  request {
    ...RequestDeepNesting
  }
  registration {
    ...RegistrationDeepNesting
  }
}
`

export const BotMailingMessagePreviousValuesDeepNestingFragment = `fragment BotMailingMessagePreviousValuesDeepNesting on BotMailingMessagePreviousValues {
  id
  createdAt
  updatedAt
  message
  sended
  error
}
`

export const PlanDeepNestingFragment = `fragment PlanDeepNesting on Plan {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const BotMailingMessageSubscriptionPayloadDeepNestingFragment = `fragment BotMailingMessageSubscriptionPayloadDeepNesting on BotMailingMessageSubscriptionPayload {
  mutation
  node {
    ...BotMailingMessageDeepNesting
  }
  updatedFields
  previousValues {
    ...BotMailingMessagePreviousValuesDeepNesting
  }
}
`

export const VerificationDeepNestingFragment = `fragment VerificationDeepNesting on Verification {
  id
  k12Phone {
    ...K12PhoneDeepNesting
  }
  device {
    ...DeviceDeepNesting
  }
  hash
  attempts
  refetchAttempts
  createdAt
  updatedAt
  refetchAt
  isVerify
}
`

export const BotMailingPreviousValuesDeepNestingFragment = `fragment BotMailingPreviousValuesDeepNesting on BotMailingPreviousValues {
  id
  createdAt
  updatedAt
  name
  message
  status
}
`

export const ForwardClientContractDeepNestingFragment = `fragment ForwardClientContractDeepNesting on ForwardClientContract {
  id
  externalKey
  V_CHECK_CHILD
  signed
  Client {
    ...ForwardClientDeepNesting
  }
  Services {
    ...ForwardContractServiceDeepNesting
  }
  Offer {
    ...ForwardOfferDeepNesting
  }
}
`

export const BotMailingSubscriptionPayloadDeepNestingFragment = `fragment BotMailingSubscriptionPayloadDeepNesting on BotMailingSubscriptionPayload {
  mutation
  node {
    ...BotMailingDeepNesting
  }
  updatedFields
  previousValues {
    ...BotMailingPreviousValuesDeepNesting
  }
}
`

export const ForwardOfferDeepNestingFragment = `fragment ForwardOfferDeepNesting on ForwardOffer {
  id
  createdAt
  updatedAt
  V_PERS_SWITCH
  Contract {
    ...ForwardClientContractDeepNesting
  }
}
`

export const BotPreviousValuesDeepNestingFragment = `fragment BotPreviousValuesDeepNesting on BotPreviousValues {
  id
  createdAt
  updatedAt
  type
  name
}
`

export const PushNotificationQueueDeepNestingFragment = `fragment PushNotificationQueueDeepNesting on PushNotificationQueue {
  id
  createdAt
  updatedAt
  message
  PushNotifications {
    ...PushNotificationDeepNesting
  }
}
`

export const BotProfilePreviousValuesDeepNestingFragment = `fragment BotProfilePreviousValuesDeepNesting on BotProfilePreviousValues {
  id
  key
  createdAt
  updatedAt
  type
  login
  firstName
  secondName
  lastName
  email
  birthday
  is_bot
  language_code
}
`

export const UserLogDeepNestingFragment = `fragment UserLogDeepNesting on UserLog {
  id
  user {
    ...UserDeepNesting
  }
  action
  value
}
`

export const BotProfileSubscriptionPayloadDeepNestingFragment = `fragment BotProfileSubscriptionPayloadDeepNesting on BotProfileSubscriptionPayload {
  mutation
  node {
    ...BotProfileDeepNesting
  }
  updatedFields
  previousValues {
    ...BotProfilePreviousValuesDeepNesting
  }
}
`

export const ServiceDeepNestingFragment = `fragment ServiceDeepNesting on Service {
  id
  name
  description
  image
  priceInPoints
  priceInCash
  priceFeePoints
  priceFeeCash
  default
  active
  createdAt
  updatedAt
  thumb
}
`

export const BotSubscriptionPayloadDeepNestingFragment = `fragment BotSubscriptionPayloadDeepNesting on BotSubscriptionPayload {
  mutation
  node {
    ...BotDeepNesting
  }
  updatedFields
  previousValues {
    ...BotPreviousValuesDeepNesting
  }
}
`

export const BotProfileDeepNestingFragment = `fragment BotProfileDeepNesting on BotProfile {
  id
  key
  createdAt
  updatedAt
  type
  login
  firstName
  secondName
  lastName
  email
  birthday
  Bot {
    ...BotDeepNesting
  }
  k12Phone {
    ...K12PhoneDeepNesting
  }
  Phone {
    ...K12PhoneDeepNesting
  }
  is_bot
  language_code
  MailingMessages {
    ...BotMailingMessageDeepNesting
  }
  fullname
}
`

export const ClientDeepNestingFragment = `fragment ClientDeepNesting on Client {
  externalKey
}
`

export const BotMailingMessageDeepNestingFragment = `fragment BotMailingMessageDeepNesting on BotMailingMessage {
  id
  createdAt
  updatedAt
  message
  BotMailing {
    ...BotMailingDeepNesting
  }
  BotProfile {
    ...BotProfileDeepNesting
  }
  sended
  error
}
`

export const CouponPreviousValuesDeepNestingFragment = `fragment CouponPreviousValuesDeepNesting on CouponPreviousValues {
  id
  createdAt
  updatedAt
  name
  conditions
  description
  content
  url
  preview
  canComplain
  startAt
  endAt
  published
}
`

export const PackageDeepNestingFragment = `fragment PackageDeepNesting on Package {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const CouponSubscriptionPayloadDeepNestingFragment = `fragment CouponSubscriptionPayloadDeepNesting on CouponSubscriptionPayload {
  mutation
  node {
    ...CouponDeepNesting
  }
  updatedFields
  previousValues {
    ...CouponPreviousValuesDeepNesting
  }
}
`

export const TransactionPromotionDeepNestingFragment = `fragment TransactionPromotionDeepNesting on TransactionPromotion {
  id
  createdAt
  updatedAt
  amount
  coefficient
  points
  Transaction {
    ...TransactionDeepNesting
  }
  Promotion {
    ...PromotionDeepNesting
  }
  K12Phone {
    ...K12PhoneDeepNesting
  }
  CreatedBy {
    ...UserDeepNesting
  }
}
`

export const DeviceConnectionDeepNestingFragment = `fragment DeviceConnectionDeepNesting on DeviceConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...DeviceEdgeDeepNesting
  }
  aggregate {
    ...AggregateDeviceDeepNesting
  }
}
`

export const PageInfoDeepNestingFragment = `fragment PageInfoDeepNesting on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
`

export const DeviceEdgeDeepNestingFragment = `fragment DeviceEdgeDeepNesting on DeviceEdge {
  node {
    ...DeviceDeepNesting
  }
  cursor
}
`

export const AggregateArticleDeepNestingFragment = `fragment AggregateArticleDeepNesting on AggregateArticle {
  count
}
`

export const DevicePreviousValuesDeepNestingFragment = `fragment DevicePreviousValuesDeepNesting on DevicePreviousValues {
  id
  token
  type
  pushErrors
  createdAt
  updatedAt
  deletedAt
}
`

export const CouponEdgeDeepNestingFragment = `fragment CouponEdgeDeepNesting on CouponEdge {
  node {
    ...CouponDeepNesting
  }
  cursor
}
`

export const DeviceSubscriptionPayloadDeepNestingFragment = `fragment DeviceSubscriptionPayloadDeepNesting on DeviceSubscriptionPayload {
  mutation
  node {
    ...DeviceDeepNesting
  }
  updatedFields
  previousValues {
    ...DevicePreviousValuesDeepNesting
  }
}
`

export const PackageConnectionDeepNestingFragment = `fragment PackageConnectionDeepNesting on PackageConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PackageEdgeDeepNesting
  }
  aggregate {
    ...AggregatePackageDeepNesting
  }
}
`

export const FilePreviousValuesDeepNestingFragment = `fragment FilePreviousValuesDeepNesting on FilePreviousValues {
  id
  createdAt
  updatedAt
  path
  name
  filename
  mimetype
  encoding
  hash
  size
  rank
}
`

export const AggregatePackageDeepNestingFragment = `fragment AggregatePackageDeepNesting on AggregatePackage {
  count
}
`

export const FileResponseDeepNestingFragment = `fragment FileResponseDeepNesting on FileResponse {
  success
  message
  data {
    ...FileDeepNesting
  }
}
`

export const PageConnectionDeepNestingFragment = `fragment PageConnectionDeepNesting on PageConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PageEdgeDeepNesting
  }
  aggregate {
    ...AggregatePageDeepNesting
  }
}
`

export const FileSubscriptionPayloadDeepNestingFragment = `fragment FileSubscriptionPayloadDeepNesting on FileSubscriptionPayload {
  mutation
  node {
    ...FileDeepNesting
  }
  updatedFields
  previousValues {
    ...FilePreviousValuesDeepNesting
  }
}
`

export const AggregatePageDeepNestingFragment = `fragment AggregatePageDeepNesting on AggregatePage {
  count
}
`

export const ForwardClientContractConnectionDeepNestingFragment = `fragment ForwardClientContractConnectionDeepNesting on ForwardClientContractConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ForwardClientContractEdgeDeepNesting
  }
  aggregate {
    ...AggregateForwardClientContractDeepNesting
  }
}
`

export const PartnerEdgeDeepNestingFragment = `fragment PartnerEdgeDeepNesting on PartnerEdge {
  node {
    ...PartnerDeepNesting
  }
  cursor
}
`

export const ForwardClientContractEdgeDeepNestingFragment = `fragment ForwardClientContractEdgeDeepNesting on ForwardClientContractEdge {
  node {
    ...ForwardClientContractDeepNesting
  }
  cursor
}
`

export const PartnerTypeConnectionDeepNestingFragment = `fragment PartnerTypeConnectionDeepNesting on PartnerTypeConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PartnerTypeEdgeDeepNesting
  }
  aggregate {
    ...AggregatePartnerTypeDeepNesting
  }
}
`

export const ForwardClientContractPreviousValuesDeepNestingFragment = `fragment ForwardClientContractPreviousValuesDeepNesting on ForwardClientContractPreviousValues {
  id
  externalKey
  V_CHECK_CHILD
  signed
}
`

export const AggregatePartnerTypeDeepNestingFragment = `fragment AggregatePartnerTypeDeepNesting on AggregatePartnerType {
  count
}
`

export const ForwardClientContractSubscriptionPayloadDeepNestingFragment = `fragment ForwardClientContractSubscriptionPayloadDeepNesting on ForwardClientContractSubscriptionPayload {
  mutation
  node {
    ...ForwardClientContractDeepNesting
  }
  updatedFields
  previousValues {
    ...ForwardClientContractPreviousValuesDeepNesting
  }
}
`

export const PromotionEdgeDeepNestingFragment = `fragment PromotionEdgeDeepNesting on PromotionEdge {
  node {
    ...PromotionDeepNesting
  }
  cursor
}
`

export const ForwardClientPreviousValuesDeepNestingFragment = `fragment ForwardClientPreviousValuesDeepNesting on ForwardClientPreviousValues {
  id
  externalKey
  B_CHECK
  newPhotoRequested
  newPhotoRequestedMessage
}
`

export const PushNotificationConnectionDeepNestingFragment = `fragment PushNotificationConnectionDeepNesting on PushNotificationConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PushNotificationEdgeDeepNesting
  }
  aggregate {
    ...AggregatePushNotificationDeepNesting
  }
}
`

export const ForwardClientSubscriptionPayloadDeepNestingFragment = `fragment ForwardClientSubscriptionPayloadDeepNesting on ForwardClientSubscriptionPayload {
  mutation
  node {
    ...ForwardClientDeepNesting
  }
  updatedFields
  previousValues {
    ...ForwardClientPreviousValuesDeepNesting
  }
}
`

export const AggregatePushNotificationDeepNestingFragment = `fragment AggregatePushNotificationDeepNesting on AggregatePushNotification {
  count
}
`

export const ForwardContractServiceConnectionDeepNestingFragment = `fragment ForwardContractServiceConnectionDeepNesting on ForwardContractServiceConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ForwardContractServiceEdgeDeepNesting
  }
  aggregate {
    ...AggregateForwardContractServiceDeepNesting
  }
}
`

export const PushNotificationQueueEdgeDeepNestingFragment = `fragment PushNotificationQueueEdgeDeepNesting on PushNotificationQueueEdge {
  node {
    ...PushNotificationQueueDeepNesting
  }
  cursor
}
`

export const ForwardContractServiceEdgeDeepNestingFragment = `fragment ForwardContractServiceEdgeDeepNesting on ForwardContractServiceEdge {
  node {
    ...ForwardContractServiceDeepNesting
  }
  cursor
}
`

export const SettingDeepNestingFragment = `fragment SettingDeepNesting on Setting {
  id
  name
  key
  value
  group
}
`

export const ForwardContractServicePreviousValuesDeepNestingFragment = `fragment ForwardContractServicePreviousValuesDeepNesting on ForwardContractServicePreviousValues {
  id
  externalKey
}
`

export const SettingConnectionDeepNestingFragment = `fragment SettingConnectionDeepNesting on SettingConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SettingEdgeDeepNesting
  }
  aggregate {
    ...AggregateSettingDeepNesting
  }
}
`

export const ForwardContractServiceSubscriptionPayloadDeepNestingFragment = `fragment ForwardContractServiceSubscriptionPayloadDeepNesting on ForwardContractServiceSubscriptionPayload {
  mutation
  node {
    ...ForwardContractServiceDeepNesting
  }
  updatedFields
  previousValues {
    ...ForwardContractServicePreviousValuesDeepNesting
  }
}
`

export const AggregateSettingDeepNestingFragment = `fragment AggregateSettingDeepNesting on AggregateSetting {
  count
}
`

export const ForwardOfferConnectionDeepNestingFragment = `fragment ForwardOfferConnectionDeepNesting on ForwardOfferConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ForwardOfferEdgeDeepNesting
  }
  aggregate {
    ...AggregateForwardOfferDeepNesting
  }
}
`

export const SurveymonkeySurveyDeepNestingFragment = `fragment SurveymonkeySurveyDeepNesting on SurveymonkeySurvey {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  name
  nickname
  href
  custom_variables
  Collectors {
    ...SurveymonkeyCollectorDeepNesting
  }
  Webhooks {
    ...SurveymonkeySurveyWebhookDeepNesting
  }
  Responses {
    ...SurveymonkeyResponseDeepNesting
  }
  active
  approximate_time
  points
  description
}
`

export const ForwardOfferEdgeDeepNestingFragment = `fragment ForwardOfferEdgeDeepNesting on ForwardOfferEdge {
  node {
    ...ForwardOfferDeepNesting
  }
  cursor
}
`

export const SurveymonkeyResponseDeepNestingFragment = `fragment SurveymonkeyResponseDeepNesting on SurveymonkeyResponse {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  collector_id
  recipient_id
  total_time
  custom_value
  edit_url
  analyze_url
  ip_address
  custom_variables
  logic_path
  metadata
  response_status
  collection_mode
  href
  respondent_id
  Survey {
    ...SurveymonkeySurveyDeepNesting
  }
  Collector {
    ...SurveymonkeyCollectorDeepNesting
  }
  SurveymonkeyUser {
    ...SurveymonkeyUserDeepNesting
  }
  User {
    ...UserDeepNesting
  }
  points
  points_applyed
}
`

export const ForwardOfferPreviousValuesDeepNestingFragment = `fragment ForwardOfferPreviousValuesDeepNesting on ForwardOfferPreviousValues {
  id
  createdAt
  updatedAt
  V_PERS_SWITCH
}
`

export const SurveymonkeyCollectorConnectionDeepNestingFragment = `fragment SurveymonkeyCollectorConnectionDeepNesting on SurveymonkeyCollectorConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SurveymonkeyCollectorEdgeDeepNesting
  }
  aggregate {
    ...AggregateSurveymonkeyCollectorDeepNesting
  }
}
`

export const ForwardOfferSubscriptionPayloadDeepNestingFragment = `fragment ForwardOfferSubscriptionPayloadDeepNesting on ForwardOfferSubscriptionPayload {
  mutation
  node {
    ...ForwardOfferDeepNesting
  }
  updatedFields
  previousValues {
    ...ForwardOfferPreviousValuesDeepNesting
  }
}
`

export const AggregateSurveymonkeyCollectorDeepNestingFragment = `fragment AggregateSurveymonkeyCollectorDeepNesting on AggregateSurveymonkeyCollector {
  count
}
`

export const K12PhonePreviousValuesDeepNestingFragment = `fragment K12PhonePreviousValuesDeepNesting on K12PhonePreviousValues {
  id
  phone
  cash
  points
  freezePoints
  traffic
  minutes
  sms
  activatedAt
  createdAt
  updatedAt
  deletedAt
  promotional
  status
  IMSI
}
`

export const SurveymonkeySurveyEdgeDeepNestingFragment = `fragment SurveymonkeySurveyEdgeDeepNesting on SurveymonkeySurveyEdge {
  node {
    ...SurveymonkeySurveyDeepNesting
  }
  cursor
}
`

export const K12PhoneSubscriptionPayloadDeepNestingFragment = `fragment K12PhoneSubscriptionPayloadDeepNesting on K12PhoneSubscriptionPayload {
  mutation
  node {
    ...K12PhoneDeepNesting
  }
  updatedFields
  previousValues {
    ...K12PhonePreviousValuesDeepNesting
  }
}
`

export const SurveymonkeyResponseConnectionDeepNestingFragment = `fragment SurveymonkeyResponseConnectionDeepNesting on SurveymonkeyResponseConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SurveymonkeyResponseEdgeDeepNesting
  }
  aggregate {
    ...AggregateSurveymonkeyResponseDeepNesting
  }
}
`

export const LogDeepNestingFragment = `fragment LogDeepNesting on Log {
  id
  createdAt
  updatedAt
  level
  objectType
  message
  stack
}
`

export const AggregateSurveymonkeyResponseDeepNestingFragment = `fragment AggregateSurveymonkeyResponseDeepNesting on AggregateSurveymonkeyResponse {
  count
}
`

export const LogConnectionDeepNestingFragment = `fragment LogConnectionDeepNesting on LogConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...LogEdgeDeepNesting
  }
  aggregate {
    ...AggregateLogDeepNesting
  }
}
`

export const BillingConnectionDeepNestingFragment = `fragment BillingConnectionDeepNesting on BillingConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...BillingEdgeDeepNesting
  }
  aggregate {
    ...AggregateBillingDeepNesting
  }
}
`

export const LogEdgeDeepNestingFragment = `fragment LogEdgeDeepNesting on LogEdge {
  node {
    ...LogDeepNesting
  }
  cursor
}
`

export const AggregateBillingDeepNestingFragment = `fragment AggregateBillingDeepNesting on AggregateBilling {
  count
}
`

export const LogedInDeepNestingFragment = `fragment LogedInDeepNesting on LogedIn {
  id
  createdAt
  fake
  User {
    ...UserDeepNesting
  }
}
`

export const K12PhoneEdgeDeepNestingFragment = `fragment K12PhoneEdgeDeepNesting on K12PhoneEdge {
  node {
    ...K12PhoneDeepNesting
  }
  cursor
}
`

export const LogedInConnectionDeepNestingFragment = `fragment LogedInConnectionDeepNesting on LogedInConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...LogedInEdgeDeepNesting
  }
  aggregate {
    ...AggregateLogedInDeepNesting
  }
}
`

export const NewsletterDeepNestingFragment = `fragment NewsletterDeepNesting on Newsletter {
  id
  name
  text
  preview
  segments {
    ...SegmentDeepNesting
  }
  status
  startedAt
  createdAt
  updatedAt
}
`

export const LogedInEdgeDeepNestingFragment = `fragment LogedInEdgeDeepNesting on LogedInEdge {
  node {
    ...LogedInDeepNesting
  }
  cursor
}
`

export const NewsletterEdgeDeepNestingFragment = `fragment NewsletterEdgeDeepNesting on NewsletterEdge {
  node {
    ...NewsletterDeepNesting
  }
  cursor
}
`

export const LogedInPreviousValuesDeepNestingFragment = `fragment LogedInPreviousValuesDeepNesting on LogedInPreviousValues {
  id
  createdAt
  fake
}
`

export const FileDeepNestingFragment = `fragment FileDeepNesting on File {
  id
  createdAt
  updatedAt
  path
  name
  filename
  mimetype
  encoding
  CreatedBy {
    ...UserDeepNesting
  }
  hash
  size
  rank
}
`

export const LogedInSubscriptionPayloadDeepNestingFragment = `fragment LogedInSubscriptionPayloadDeepNesting on LogedInSubscriptionPayload {
  mutation
  node {
    ...LogedInDeepNesting
  }
  updatedFields
  previousValues {
    ...LogedInPreviousValuesDeepNesting
  }
}
`

export const FileEdgeDeepNestingFragment = `fragment FileEdgeDeepNesting on FileEdge {
  node {
    ...FileDeepNesting
  }
  cursor
}
`

export const LogPreviousValuesDeepNestingFragment = `fragment LogPreviousValuesDeepNesting on LogPreviousValues {
  id
  createdAt
  updatedAt
  level
  objectType
  message
  stack
}
`

export const UserConnectionDeepNestingFragment = `fragment UserConnectionDeepNesting on UserConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...UserEdgeDeepNesting
  }
  aggregate {
    ...AggregateUserDeepNesting
  }
}
`

export const LogSubscriptionPayloadDeepNestingFragment = `fragment LogSubscriptionPayloadDeepNesting on LogSubscriptionPayload {
  mutation
  node {
    ...LogDeepNesting
  }
  updatedFields
  previousValues {
    ...LogPreviousValuesDeepNesting
  }
}
`

export const AggregateUserDeepNestingFragment = `fragment AggregateUserDeepNesting on AggregateUser {
  count
}
`

export const NewsletterJournalDeepNestingFragment = `fragment NewsletterJournalDeepNesting on NewsletterJournal {
  id
  newsletter {
    ...NewsletterDeepNesting
  }
  user {
    ...UserDeepNesting
  }
  attempts
  lastAttemptAt
  deliveryAt
}
`

export const ProjectDeepNestingFragment = `fragment ProjectDeepNesting on Project {
  id
  createdAt
  updatedAt
  name
  domain
  description
  url
  PrismaTemplates {
    ...TemplateDeepNesting
  }
  PrismaUsers {
    ...UserDeepNesting
  }
  CreatedBy {
    ...UserDeepNesting
  }
}
`

export const NewsletterJournalConnectionDeepNestingFragment = `fragment NewsletterJournalConnectionDeepNesting on NewsletterJournalConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...NewsletterJournalEdgeDeepNesting
  }
  aggregate {
    ...AggregateNewsletterJournalDeepNesting
  }
}
`

export const TemplateEdgeDeepNestingFragment = `fragment TemplateEdgeDeepNesting on TemplateEdge {
  node {
    ...TemplateDeepNesting
  }
  cursor
}
`

export const NewsletterJournalEdgeDeepNestingFragment = `fragment NewsletterJournalEdgeDeepNesting on NewsletterJournalEdge {
  node {
    ...NewsletterJournalDeepNesting
  }
  cursor
}
`

export const BotMailingConnectionDeepNestingFragment = `fragment BotMailingConnectionDeepNesting on BotMailingConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...BotMailingEdgeDeepNesting
  }
  aggregate {
    ...AggregateBotMailingDeepNesting
  }
}
`

export const NewsletterJournalPreviousValuesDeepNestingFragment = `fragment NewsletterJournalPreviousValuesDeepNesting on NewsletterJournalPreviousValues {
  id
  attempts
  lastAttemptAt
  deliveryAt
}
`

export const AggregateBotMailingDeepNestingFragment = `fragment AggregateBotMailingDeepNesting on AggregateBotMailing {
  count
}
`

export const NewsletterJournalSubscriptionPayloadDeepNestingFragment = `fragment NewsletterJournalSubscriptionPayloadDeepNesting on NewsletterJournalSubscriptionPayload {
  mutation
  node {
    ...NewsletterJournalDeepNesting
  }
  updatedFields
  previousValues {
    ...NewsletterJournalPreviousValuesDeepNesting
  }
}
`

export const BotProfileEdgeDeepNestingFragment = `fragment BotProfileEdgeDeepNesting on BotProfileEdge {
  node {
    ...BotProfileDeepNesting
  }
  cursor
}
`

export const NewsletterPreviousValuesDeepNestingFragment = `fragment NewsletterPreviousValuesDeepNesting on NewsletterPreviousValues {
  id
  name
  text
  preview
  status
  startedAt
  createdAt
  updatedAt
}
`

export const ChecksInfoDeepNestingFragment = `fragment ChecksInfoDeepNesting on ChecksInfo {
  total
}
`

export const NewsletterSubscriptionPayloadDeepNestingFragment = `fragment NewsletterSubscriptionPayloadDeepNesting on NewsletterSubscriptionPayload {
  mutation
  node {
    ...NewsletterDeepNesting
  }
  updatedFields
  previousValues {
    ...NewsletterPreviousValuesDeepNesting
  }
}
`

export const CheckItemDeepNestingFragment = `fragment CheckItemDeepNesting on CheckItem {
  externalKey
  name
  count
  sum
}
`

export const NotificationTypeConnectionDeepNestingFragment = `fragment NotificationTypeConnectionDeepNesting on NotificationTypeConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...NotificationTypeEdgeDeepNesting
  }
  aggregate {
    ...AggregateNotificationTypeDeepNesting
  }
}
`

export const ForwardClientEdgeDeepNestingFragment = `fragment ForwardClientEdgeDeepNesting on ForwardClientEdge {
  node {
    ...ForwardClientDeepNesting
  }
  cursor
}
`

export const NotificationTypeEdgeDeepNestingFragment = `fragment NotificationTypeEdgeDeepNesting on NotificationTypeEdge {
  node {
    ...NotificationTypeDeepNesting
  }
  cursor
}
`

export const TarifPlanDeepNestingFragment = `fragment TarifPlanDeepNesting on TarifPlan {
  ID_TARIFF_PLAN
  V_TARIFF_PLAN_NAME
  V_TARIFF_PLAN_DESCR
}
`

export const NotificationTypePreviousValuesDeepNestingFragment = `fragment NotificationTypePreviousValuesDeepNesting on NotificationTypePreviousValues {
  id
  name
}
`

export const TarifInfoServiceDeepNestingFragment = `fragment TarifInfoServiceDeepNesting on TarifInfoService {
  ID_ADD_SRV
  V_ADD_SRV_NAME
  N_ADD_SRV_PRICE_RUB
  N_ADD_SRV_PRICE_BAL
  B_ADD_SRV_CONNECT
}
`

export const NotificationTypeSubscriptionPayloadDeepNestingFragment = `fragment NotificationTypeSubscriptionPayloadDeepNesting on NotificationTypeSubscriptionPayload {
  mutation
  node {
    ...NotificationTypeDeepNesting
  }
  updatedFields
  previousValues {
    ...NotificationTypePreviousValuesDeepNesting
  }
}
`

export const SurveymonkeyResponseResponseDeepNestingFragment = `fragment SurveymonkeyResponseResponseDeepNesting on SurveymonkeyResponseResponse {
  success
  message
  data {
    ...SurveymonkeyResponseDeepNesting
  }
}
`

export const PackagePreviousValuesDeepNestingFragment = `fragment PackagePreviousValuesDeepNesting on PackagePreviousValues {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const SurveymonkeySurveyResponseDeepNestingFragment = `fragment SurveymonkeySurveyResponseDeepNesting on SurveymonkeySurveyResponse {
  success
  message
  data {
    ...SurveymonkeySurveyDeepNesting
  }
}
`

export const PackageSubscriptionPayloadDeepNestingFragment = `fragment PackageSubscriptionPayloadDeepNesting on PackageSubscriptionPayload {
  mutation
  node {
    ...PackageDeepNesting
  }
  updatedFields
  previousValues {
    ...PackagePreviousValuesDeepNesting
  }
}
`

export const ArticleResponseDeepNestingFragment = `fragment ArticleResponseDeepNesting on ArticleResponse {
  success
  message
  data {
    ...ArticleDeepNesting
  }
}
`

export const PagePreviousValuesDeepNestingFragment = `fragment PagePreviousValuesDeepNesting on PagePreviousValues {
  id
  key
  title
  content
}
`

export const PageSubscriptionPayloadDeepNestingFragment = `fragment PageSubscriptionPayloadDeepNesting on PageSubscriptionPayload {
  mutation
  node {
    ...PageDeepNesting
  }
  updatedFields
  previousValues {
    ...PagePreviousValuesDeepNesting
  }
}
`

export const PageResponseDeepNestingFragment = `fragment PageResponseDeepNesting on PageResponse {
  success
  message
  data {
    ...PageDeepNesting
  }
}
`

export const PartnerCategoryConnectionDeepNestingFragment = `fragment PartnerCategoryConnectionDeepNesting on PartnerCategoryConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PartnerCategoryEdgeDeepNesting
  }
  aggregate {
    ...AggregatePartnerCategoryDeepNesting
  }
}
`

export const PartnerFnResponseDeepNestingFragment = `fragment PartnerFnResponseDeepNesting on PartnerFnResponse {
  success
  message
  data {
    ...PartnerFnDeepNesting
  }
}
`

export const PartnerCategoryEdgeDeepNestingFragment = `fragment PartnerCategoryEdgeDeepNesting on PartnerCategoryEdge {
  node {
    ...PartnerCategoryDeepNesting
  }
  cursor
}
`

export const PartnerTypeResponseDeepNestingFragment = `fragment PartnerTypeResponseDeepNesting on PartnerTypeResponse {
  success
  message
  data {
    ...PartnerTypeDeepNesting
  }
}
`

export const PartnerCategoryPreviousValuesDeepNestingFragment = `fragment PartnerCategoryPreviousValuesDeepNesting on PartnerCategoryPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const PushNotificationQueueResponseDeepNestingFragment = `fragment PushNotificationQueueResponseDeepNesting on PushNotificationQueueResponse {
  success
  message
  data {
    ...PushNotificationQueueDeepNesting
  }
}
`

export const PartnerCategorySubscriptionPayloadDeepNestingFragment = `fragment PartnerCategorySubscriptionPayloadDeepNesting on PartnerCategorySubscriptionPayload {
  mutation
  node {
    ...PartnerCategoryDeepNesting
  }
  updatedFields
  previousValues {
    ...PartnerCategoryPreviousValuesDeepNesting
  }
}
`

export const SBOrderDeepNestingFragment = `fragment SBOrderDeepNesting on SBOrder {
  id
  createdAt
  updatedAt
  externalKey
  number
  orderStatus
  amount
  currency
  date
  paymentAmountInfo
  Payment {
    ...PaymentDeepNesting
  }
}
`

export const PartnerFnConnectionDeepNestingFragment = `fragment PartnerFnConnectionDeepNesting on PartnerFnConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PartnerFnEdgeDeepNesting
  }
  aggregate {
    ...AggregatePartnerFnDeepNesting
  }
}
`

export const SettingResponseDeepNestingFragment = `fragment SettingResponseDeepNesting on SettingResponse {
  success
  message
  data {
    ...SettingDeepNesting
  }
}
`

export const PartnerFnEdgeDeepNestingFragment = `fragment PartnerFnEdgeDeepNesting on PartnerFnEdge {
  node {
    ...PartnerFnDeepNesting
  }
  cursor
}
`

export const AuthPayloadDeepNestingFragment = `fragment AuthPayloadDeepNesting on AuthPayload {
  token
}
`

export const PartnerFnPreviousValuesDeepNestingFragment = `fragment PartnerFnPreviousValuesDeepNesting on PartnerFnPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const PassportDataDeepNestingFragment = `fragment PassportDataDeepNesting on PassportData {
  LastName
  FirstName
  MiddleName
  Sex
  DateOfBirth
  PlaceOfBirth
  IssuedBy
  DateOfIssue
  DepartmentCode
  Series
  Number
}
`

export const PartnerFnSubscriptionPayloadDeepNestingFragment = `fragment PartnerFnSubscriptionPayloadDeepNesting on PartnerFnSubscriptionPayload {
  mutation
  node {
    ...PartnerFnDeepNesting
  }
  updatedFields
  previousValues {
    ...PartnerFnPreviousValuesDeepNesting
  }
}
`

export const BatchPayloadDeepNestingFragment = `fragment BatchPayloadDeepNesting on BatchPayload {
  count
}
`

export const PartnerPreviousValuesDeepNestingFragment = `fragment PartnerPreviousValuesDeepNesting on PartnerPreviousValues {
  id
  name
  logo
  address
  active
  createdAt
  updatedAt
}
`

export const TransactionResponseDeepNestingFragment = `fragment TransactionResponseDeepNesting on TransactionResponse {
  success
  message
  data {
    ...TransactionDeepNesting
  }
}
`

export const PartnerProductConnectionDeepNestingFragment = `fragment PartnerProductConnectionDeepNesting on PartnerProductConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PartnerProductEdgeDeepNesting
  }
  aggregate {
    ...AggregatePartnerProductDeepNesting
  }
}
`

export const SigninPayloadDeepNestingFragment = `fragment SigninPayloadDeepNesting on SigninPayload {
  success
  message
  token
  data {
    ...UserDeepNesting
  }
}
`

export const PartnerProductEdgeDeepNestingFragment = `fragment PartnerProductEdgeDeepNesting on PartnerProductEdge {
  node {
    ...PartnerProductDeepNesting
  }
  cursor
}
`

export const TemplateResponseDeepNestingFragment = `fragment TemplateResponseDeepNesting on TemplateResponse {
  success
  message
  data {
    ...TemplateDeepNesting
  }
}
`

export const PartnerProductPreviousValuesDeepNestingFragment = `fragment PartnerProductPreviousValuesDeepNesting on PartnerProductPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const ClientResponseDeepNestingFragment = `fragment ClientResponseDeepNesting on ClientResponse {
  success
  message
  data {
    ...ForwardClientDeepNesting
  }
}
`

export const PartnerProductSubscriptionPayloadDeepNestingFragment = `fragment PartnerProductSubscriptionPayloadDeepNesting on PartnerProductSubscriptionPayload {
  mutation
  node {
    ...PartnerProductDeepNesting
  }
  updatedFields
  previousValues {
    ...PartnerProductPreviousValuesDeepNesting
  }
}
`

export const ActivateContractResponseDeepNestingFragment = `fragment ActivateContractResponseDeepNesting on ActivateContractResponse {
  phone
}
`

export const PartnerSubscriptionPayloadDeepNestingFragment = `fragment PartnerSubscriptionPayloadDeepNesting on PartnerSubscriptionPayload {
  mutation
  node {
    ...PartnerDeepNesting
  }
  updatedFields
  previousValues {
    ...PartnerPreviousValuesDeepNesting
  }
}
`

export const UserSubscriptionPayloadDeepNestingFragment = `fragment UserSubscriptionPayloadDeepNesting on UserSubscriptionPayload {
  mutation
  node {
    ...UserDeepNesting
  }
  updatedFields
  previousValues {
    ...UserPreviousValuesDeepNesting
  }
}
`

export const PartnerTypePreviousValuesDeepNestingFragment = `fragment PartnerTypePreviousValuesDeepNesting on PartnerTypePreviousValues {
  id
  name
  daysToConfirm
  active
  createdAt
  updatedAt
}
`

export const TemplateSubscriptionPayloadDeepNestingFragment = `fragment TemplateSubscriptionPayloadDeepNesting on TemplateSubscriptionPayload {
  mutation
  node {
    ...TemplateDeepNesting
  }
  updatedFields
  previousValues {
    ...TemplatePreviousValuesDeepNesting
  }
}
`

export const PartnerTypeSubscriptionPayloadDeepNestingFragment = `fragment PartnerTypeSubscriptionPayloadDeepNesting on PartnerTypeSubscriptionPayload {
  mutation
  node {
    ...PartnerTypeDeepNesting
  }
  updatedFields
  previousValues {
    ...PartnerTypePreviousValuesDeepNesting
  }
}
`

export const AggregateBotDeepNestingFragment = `fragment AggregateBotDeepNesting on AggregateBot {
  count
}
`

export const PaymentConnectionDeepNestingFragment = `fragment PaymentConnectionDeepNesting on PaymentConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PaymentEdgeDeepNesting
  }
  aggregate {
    ...AggregatePaymentDeepNesting
  }
}
`

export const AggregateBotMailingMessageDeepNestingFragment = `fragment AggregateBotMailingMessageDeepNesting on AggregateBotMailingMessage {
  count
}
`

export const PaymentEdgeDeepNestingFragment = `fragment PaymentEdgeDeepNesting on PaymentEdge {
  node {
    ...PaymentDeepNesting
  }
  cursor
}
`

export const AggregateForwardClientContractDeepNestingFragment = `fragment AggregateForwardClientContractDeepNesting on AggregateForwardClientContract {
  count
}
`

export const PaymentPreviousValuesDeepNestingFragment = `fragment PaymentPreviousValuesDeepNesting on PaymentPreviousValues {
  id
  createdAt
  updatedAt
  amount
  status
}
`

export const AggregateForwardOfferDeepNestingFragment = `fragment AggregateForwardOfferDeepNesting on AggregateForwardOffer {
  count
}
`

export const PaymentSubscriptionPayloadDeepNestingFragment = `fragment PaymentSubscriptionPayloadDeepNesting on PaymentSubscriptionPayload {
  mutation
  node {
    ...PaymentDeepNesting
  }
  updatedFields
  previousValues {
    ...PaymentPreviousValuesDeepNesting
  }
}
`

export const AggregateLogedInDeepNestingFragment = `fragment AggregateLogedInDeepNesting on AggregateLogedIn {
  count
}
`

export const PlanConnectionDeepNestingFragment = `fragment PlanConnectionDeepNesting on PlanConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PlanEdgeDeepNesting
  }
  aggregate {
    ...AggregatePlanDeepNesting
  }
}
`

export const AggregateNotificationTypeDeepNestingFragment = `fragment AggregateNotificationTypeDeepNesting on AggregateNotificationType {
  count
}
`

export const PlanEdgeDeepNestingFragment = `fragment PlanEdgeDeepNesting on PlanEdge {
  node {
    ...PlanDeepNesting
  }
  cursor
}
`

export const AggregatePartnerFnDeepNestingFragment = `fragment AggregatePartnerFnDeepNesting on AggregatePartnerFn {
  count
}
`

export const PlanPreviousValuesDeepNestingFragment = `fragment PlanPreviousValuesDeepNesting on PlanPreviousValues {
  id
  name
  traffic
  minutes
  sms
  points
  cash
  active
  createdAt
  updatedAt
}
`

export const AggregatePaymentDeepNestingFragment = `fragment AggregatePaymentDeepNesting on AggregatePayment {
  count
}
`

export const PlanSubscriptionPayloadDeepNestingFragment = `fragment PlanSubscriptionPayloadDeepNesting on PlanSubscriptionPayload {
  mutation
  node {
    ...PlanDeepNesting
  }
  updatedFields
  previousValues {
    ...PlanPreviousValuesDeepNesting
  }
}
`

export const AggregatePollDeepNestingFragment = `fragment AggregatePollDeepNesting on AggregatePoll {
  count
}
`

export const PollConnectionDeepNestingFragment = `fragment PollConnectionDeepNesting on PollConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PollEdgeDeepNesting
  }
  aggregate {
    ...AggregatePollDeepNesting
  }
}
`

export const AggregatePollQuestionItemDeepNestingFragment = `fragment AggregatePollQuestionItemDeepNesting on AggregatePollQuestionItem {
  count
}
`

export const PollEdgeDeepNestingFragment = `fragment PollEdgeDeepNesting on PollEdge {
  node {
    ...PollDeepNesting
  }
  cursor
}
`

export const AggregatePollResultAnswerDeepNestingFragment = `fragment AggregatePollResultAnswerDeepNesting on AggregatePollResultAnswer {
  count
}
`

export const PollPreviousValuesDeepNestingFragment = `fragment PollPreviousValuesDeepNesting on PollPreviousValues {
  id
  name
  description
  content
  points
  leadTime
  secret
  startAt
  endAt
  status
  createdAt
  updatedAt
}
`

export const AggregateReceiptDeepNestingFragment = `fragment AggregateReceiptDeepNesting on AggregateReceipt {
  count
}
`

export const PollQuestionConnectionDeepNestingFragment = `fragment PollQuestionConnectionDeepNesting on PollQuestionConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PollQuestionEdgeDeepNesting
  }
  aggregate {
    ...AggregatePollQuestionDeepNesting
  }
}
`

export const AggregateReceiptParserOperatorDeepNestingFragment = `fragment AggregateReceiptParserOperatorDeepNesting on AggregateReceiptParserOperator {
  count
}
`

export const PollQuestionEdgeDeepNestingFragment = `fragment PollQuestionEdgeDeepNesting on PollQuestionEdge {
  node {
    ...PollQuestionDeepNesting
  }
  cursor
}
`

export const AggregateRegistrationDeepNestingFragment = `fragment AggregateRegistrationDeepNesting on AggregateRegistration {
  count
}
`

export const PollQuestionItemConnectionDeepNestingFragment = `fragment PollQuestionItemConnectionDeepNesting on PollQuestionItemConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PollQuestionItemEdgeDeepNesting
  }
  aggregate {
    ...AggregatePollQuestionItemDeepNesting
  }
}
`

export const AggregateResetPasswordDeepNestingFragment = `fragment AggregateResetPasswordDeepNesting on AggregateResetPassword {
  count
}
`

export const PollQuestionItemEdgeDeepNestingFragment = `fragment PollQuestionItemEdgeDeepNesting on PollQuestionItemEdge {
  node {
    ...PollQuestionItemDeepNesting
  }
  cursor
}
`

export const AggregateSegmentDeepNestingFragment = `fragment AggregateSegmentDeepNesting on AggregateSegment {
  count
}
`

export const PollQuestionItemPreviousValuesDeepNestingFragment = `fragment PollQuestionItemPreviousValuesDeepNesting on PollQuestionItemPreviousValues {
  id
  name
}
`

export const AggregateSegmentCriteriaTypeDeepNestingFragment = `fragment AggregateSegmentCriteriaTypeDeepNesting on AggregateSegmentCriteriaType {
  count
}
`

export const PollQuestionItemSubscriptionPayloadDeepNestingFragment = `fragment PollQuestionItemSubscriptionPayloadDeepNesting on PollQuestionItemSubscriptionPayload {
  mutation
  node {
    ...PollQuestionItemDeepNesting
  }
  updatedFields
  previousValues {
    ...PollQuestionItemPreviousValuesDeepNesting
  }
}
`

export const AggregateSurveymonkeySurveyWebhookDeepNestingFragment = `fragment AggregateSurveymonkeySurveyWebhookDeepNesting on AggregateSurveymonkeySurveyWebhook {
  count
}
`

export const PollQuestionPreviousValuesDeepNestingFragment = `fragment PollQuestionPreviousValuesDeepNesting on PollQuestionPreviousValues {
  id
  name
  type
}
`

export const AggregateTransactionDeepNestingFragment = `fragment AggregateTransactionDeepNesting on AggregateTransaction {
  count
}
`

export const PollQuestionSubscriptionPayloadDeepNestingFragment = `fragment PollQuestionSubscriptionPayloadDeepNesting on PollQuestionSubscriptionPayload {
  mutation
  node {
    ...PollQuestionDeepNesting
  }
  updatedFields
  previousValues {
    ...PollQuestionPreviousValuesDeepNesting
  }
}
`

export const AggregateUserGroupDeepNestingFragment = `fragment AggregateUserGroupDeepNesting on AggregateUserGroup {
  count
}
`

export const PollResultAnswerConnectionDeepNestingFragment = `fragment PollResultAnswerConnectionDeepNesting on PollResultAnswerConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PollResultAnswerEdgeDeepNesting
  }
  aggregate {
    ...AggregatePollResultAnswerDeepNesting
  }
}
`

export const AggregateVerificationDeepNestingFragment = `fragment AggregateVerificationDeepNesting on AggregateVerification {
  count
}
`

export const PollResultAnswerEdgeDeepNestingFragment = `fragment PollResultAnswerEdgeDeepNesting on PollResultAnswerEdge {
  node {
    ...PollResultAnswerDeepNesting
  }
  cursor
}
`

export const SegmentCriteriaTypeDeepNestingFragment = `fragment SegmentCriteriaTypeDeepNesting on SegmentCriteriaType {
  id
  name
  isBetween
  isAttachPartners
}
`

export const PollResultAnswerPreviousValuesDeepNestingFragment = `fragment PollResultAnswerPreviousValuesDeepNesting on PollResultAnswerPreviousValues {
  id
}
`

export const PromotionDeepNestingFragment = `fragment PromotionDeepNesting on Promotion {
  id
  name
  description
  partner {
    ...PartnerDeepNesting
  }
  preview
  text
  bonus
  checkInText
  checkInPoints
  maxProducts
  active
  startAt
  endAt
  products {
    ...PartnerProductDeepNesting
  }
  createdAt
  updatedAt
  type
  partnerName
  partnerId
  thumb
}
`

export const PollResultAnswerSubscriptionPayloadDeepNestingFragment = `fragment PollResultAnswerSubscriptionPayloadDeepNesting on PollResultAnswerSubscriptionPayload {
  mutation
  node {
    ...PollResultAnswerDeepNesting
  }
  updatedFields
  previousValues {
    ...PollResultAnswerPreviousValuesDeepNesting
  }
}
`

export const ReceiptDeepNestingFragment = `fragment ReceiptDeepNesting on Receipt {
  id
  partnerFn {
    ...PartnerFnDeepNesting
  }
  hash
  photo
  s
  fn
  i
  fp
  t
  n
  ParserOperator {
    ...ReceiptParserOperatorDeepNesting
  }
  ParserLogs {
    ...ReceiptParserLogDeepNesting
  }
  parserAttempts
  parserStatus
  parserData
  createdAt
  updatedAt
  Transaction {
    ...TransactionDeepNesting
  }
  externalKey
}
`

export const PollResultConnectionDeepNestingFragment = `fragment PollResultConnectionDeepNesting on PollResultConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PollResultEdgeDeepNesting
  }
  aggregate {
    ...AggregatePollResultDeepNesting
  }
}
`

export const K12PhoneDeepNestingFragment = `fragment K12PhoneDeepNesting on K12Phone {
  id
  phone
  cash
  points
  freezePoints
  traffic
  minutes
  sms
  activatedAt
  createdAt
  updatedAt
  deletedAt
  promotional
  user {
    ...UserDeepNesting
  }
  status
  services {
    ...ServiceDeepNesting
  }
  Verifications {
    ...VerificationDeepNesting
  }
  Registrations {
    ...RegistrationDeepNesting
  }
  Billings {
    ...BillingDeepNesting
  }
  BotProfiles {
    ...BotProfileDeepNesting
  }
  IMSI
  ForwardClient {
    ...ForwardClientDeepNesting
  }
  Device {
    ...DeviceDeepNesting
  }
  PushNotifications {
    ...PushNotificationDeepNesting
  }
  allPoints
  plan {
    ...PlanDeepNesting
  }
  ID_CLIENT_INST
  ID_CONTRACT_INST
  ID_SERVICE_INST
}
`

export const PollResultEdgeDeepNestingFragment = `fragment PollResultEdgeDeepNesting on PollResultEdge {
  node {
    ...PollResultDeepNesting
  }
  cursor
}
`

export const PollQuestionDeepNestingFragment = `fragment PollQuestionDeepNesting on PollQuestion {
  id
  name
  type
  items {
    ...PollQuestionItemDeepNesting
  }
}
`

export const PollResultPreviousValuesDeepNestingFragment = `fragment PollResultPreviousValuesDeepNesting on PollResultPreviousValues {
  id
  startedAt
  endedAt
  status
  createdAt
  updatedAt
}
`

export const RequestDeepNestingFragment = `fragment RequestDeepNesting on Request {
  id
  name
  phone
  device {
    ...DeviceDeepNesting
  }
  plan {
    ...PlanDeepNesting
  }
  status
  createdAt
  updatedAt
  deletedAt
}
`

export const PollResultSubscriptionPayloadDeepNestingFragment = `fragment PollResultSubscriptionPayloadDeepNesting on PollResultSubscriptionPayload {
  mutation
  node {
    ...PollResultDeepNesting
  }
  updatedFields
  previousValues {
    ...PollResultPreviousValuesDeepNesting
  }
}
`

export const ForwardClientDeepNestingFragment = `fragment ForwardClientDeepNesting on ForwardClient {
  id
  externalKey
  B_CHECK
  newPhotoRequested
  newPhotoRequestedMessage
  K12Phone {
    ...K12PhoneDeepNesting
  }
  Contract {
    ...ForwardClientContractDeepNesting
  }
  Device {
    ...DeviceDeepNesting
  }
}
`

export const PollSubscriptionPayloadDeepNestingFragment = `fragment PollSubscriptionPayloadDeepNesting on PollSubscriptionPayload {
  mutation
  node {
    ...PollDeepNesting
  }
  updatedFields
  previousValues {
    ...PollPreviousValuesDeepNesting
  }
}
`

export const PushNotificationDeepNestingFragment = `fragment PushNotificationDeepNesting on PushNotification {
  id
  createdAt
  updatedAt
  ticketId
  message
  hasErrors
  ticketError
  receiptError
  Device {
    ...DeviceDeepNesting
  }
  K12Phone {
    ...K12PhoneDeepNesting
  }
  PushNotificationQueue {
    ...PushNotificationQueueDeepNesting
  }
}
`

export const ProjectConnectionDeepNestingFragment = `fragment ProjectConnectionDeepNesting on ProjectConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ProjectEdgeDeepNesting
  }
  aggregate {
    ...AggregateProjectDeepNesting
  }
}
`

export const UserGroupDeepNestingFragment = `fragment UserGroupDeepNesting on UserGroup {
  id
  createdAt
  updatedAt
  name
  Users {
    ...UserDeepNesting
  }
}
`

export const ProjectEdgeDeepNestingFragment = `fragment ProjectEdgeDeepNesting on ProjectEdge {
  node {
    ...ProjectDeepNesting
  }
  cursor
}
`

export const BotDeepNestingFragment = `fragment BotDeepNesting on Bot {
  id
  createdAt
  updatedAt
  type
  name
  Profiles {
    ...BotProfileDeepNesting
  }
}
`

export const ProjectPreviousValuesDeepNestingFragment = `fragment ProjectPreviousValuesDeepNesting on ProjectPreviousValues {
  id
  createdAt
  updatedAt
  name
  domain
  description
  url
}
`

export const CheckInfoDeepNestingFragment = `fragment CheckInfoDeepNesting on CheckInfo {
  externalKey
  sum
  status
  points
}
`

export const ProjectSubscriptionPayloadDeepNestingFragment = `fragment ProjectSubscriptionPayloadDeepNesting on ProjectSubscriptionPayload {
  mutation
  node {
    ...ProjectDeepNesting
  }
  updatedFields
  previousValues {
    ...ProjectPreviousValuesDeepNesting
  }
}
`

export const ArticleEdgeDeepNestingFragment = `fragment ArticleEdgeDeepNesting on ArticleEdge {
  node {
    ...ArticleDeepNesting
  }
  cursor
}
`

export const PromotionPreviousValuesDeepNestingFragment = `fragment PromotionPreviousValuesDeepNesting on PromotionPreviousValues {
  id
  name
  description
  preview
  text
  bonus
  checkInText
  checkInPoints
  maxProducts
  active
  startAt
  endAt
  createdAt
  updatedAt
}
`

export const AggregateCouponDeepNestingFragment = `fragment AggregateCouponDeepNesting on AggregateCoupon {
  count
}
`

export const PromotionSubscriptionPayloadDeepNestingFragment = `fragment PromotionSubscriptionPayloadDeepNesting on PromotionSubscriptionPayload {
  mutation
  node {
    ...PromotionDeepNesting
  }
  updatedFields
  previousValues {
    ...PromotionPreviousValuesDeepNesting
  }
}
`

export const PageDeepNestingFragment = `fragment PageDeepNesting on Page {
  id
  key
  title
  content
}
`

export const PushNotificationPreviousValuesDeepNestingFragment = `fragment PushNotificationPreviousValuesDeepNesting on PushNotificationPreviousValues {
  id
  createdAt
  updatedAt
  ticketId
  message
  hasErrors
  ticketError
  receiptError
}
`

export const PartnerConnectionDeepNestingFragment = `fragment PartnerConnectionDeepNesting on PartnerConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PartnerEdgeDeepNesting
  }
  aggregate {
    ...AggregatePartnerDeepNesting
  }
}
`

export const PushNotificationQueuePreviousValuesDeepNestingFragment = `fragment PushNotificationQueuePreviousValuesDeepNesting on PushNotificationQueuePreviousValues {
  id
  createdAt
  updatedAt
  message
}
`

export const PartnerTypeEdgeDeepNestingFragment = `fragment PartnerTypeEdgeDeepNesting on PartnerTypeEdge {
  node {
    ...PartnerTypeDeepNesting
  }
  cursor
}
`

export const PushNotificationQueueSubscriptionPayloadDeepNestingFragment = `fragment PushNotificationQueueSubscriptionPayloadDeepNesting on PushNotificationQueueSubscriptionPayload {
  mutation
  node {
    ...PushNotificationQueueDeepNesting
  }
  updatedFields
  previousValues {
    ...PushNotificationQueuePreviousValuesDeepNesting
  }
}
`

export const AggregatePromotionDeepNestingFragment = `fragment AggregatePromotionDeepNesting on AggregatePromotion {
  count
}
`

export const PushNotificationSubscriptionPayloadDeepNestingFragment = `fragment PushNotificationSubscriptionPayloadDeepNesting on PushNotificationSubscriptionPayload {
  mutation
  node {
    ...PushNotificationDeepNesting
  }
  updatedFields
  previousValues {
    ...PushNotificationPreviousValuesDeepNesting
  }
}
`

export const PushNotificationQueueConnectionDeepNestingFragment = `fragment PushNotificationQueueConnectionDeepNesting on PushNotificationQueueConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PushNotificationQueueEdgeDeepNesting
  }
  aggregate {
    ...AggregatePushNotificationQueueDeepNesting
  }
}
`

export const ReceiptConnectionDeepNestingFragment = `fragment ReceiptConnectionDeepNesting on ReceiptConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ReceiptEdgeDeepNesting
  }
  aggregate {
    ...AggregateReceiptDeepNesting
  }
}
`

export const SettingsDeepNestingFragment = `fragment SettingsDeepNesting on Settings {
  supportPhone
  livechatLicense
  telegram
  facebook
  viber
  politics
  rules
  siteUrl
  botGreetingsMessage
  fnsAttempts
  fnsDelay
  ofdAttempts
  ofdDelay
}
`

export const ReceiptEdgeDeepNestingFragment = `fragment ReceiptEdgeDeepNesting on ReceiptEdge {
  node {
    ...ReceiptDeepNesting
  }
  cursor
}
`

export const SurveymonkeyCollectorDeepNestingFragment = `fragment SurveymonkeyCollectorDeepNesting on SurveymonkeyCollector {
  id
  createdAt
  updatedAt
  externalKey
  name
  hash
  url
  href
  status
  type
  Survey {
    ...SurveymonkeySurveyDeepNesting
  }
  Responses {
    ...SurveymonkeyResponseDeepNesting
  }
}
`

export const ReceiptParserLogConnectionDeepNestingFragment = `fragment ReceiptParserLogConnectionDeepNesting on ReceiptParserLogConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ReceiptParserLogEdgeDeepNesting
  }
  aggregate {
    ...AggregateReceiptParserLogDeepNesting
  }
}
`

export const SurveymonkeyUserDeepNestingFragment = `fragment SurveymonkeyUserDeepNesting on SurveymonkeyUser {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  email_address
  Responses {
    ...SurveymonkeyResponseDeepNesting
  }
}
`

export const ReceiptParserLogEdgeDeepNestingFragment = `fragment ReceiptParserLogEdgeDeepNesting on ReceiptParserLogEdge {
  node {
    ...ReceiptParserLogDeepNesting
  }
  cursor
}
`

export const SurveymonkeySurveyConnectionDeepNestingFragment = `fragment SurveymonkeySurveyConnectionDeepNesting on SurveymonkeySurveyConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SurveymonkeySurveyEdgeDeepNesting
  }
  aggregate {
    ...AggregateSurveymonkeySurveyDeepNesting
  }
}
`

export const ReceiptParserLogPreviousValuesDeepNestingFragment = `fragment ReceiptParserLogPreviousValuesDeepNesting on ReceiptParserLogPreviousValues {
  id
  createdAt
}
`

export const SurveymonkeyResponseEdgeDeepNestingFragment = `fragment SurveymonkeyResponseEdgeDeepNesting on SurveymonkeyResponseEdge {
  node {
    ...SurveymonkeyResponseDeepNesting
  }
  cursor
}
`

export const ReceiptParserLogSubscriptionPayloadDeepNestingFragment = `fragment ReceiptParserLogSubscriptionPayloadDeepNesting on ReceiptParserLogSubscriptionPayload {
  mutation
  node {
    ...ReceiptParserLogDeepNesting
  }
  updatedFields
  previousValues {
    ...ReceiptParserLogPreviousValuesDeepNesting
  }
}
`

export const BillingEdgeDeepNestingFragment = `fragment BillingEdgeDeepNesting on BillingEdge {
  node {
    ...BillingDeepNesting
  }
  cursor
}
`

export const ReceiptParserOperatorConnectionDeepNestingFragment = `fragment ReceiptParserOperatorConnectionDeepNesting on ReceiptParserOperatorConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ReceiptParserOperatorEdgeDeepNesting
  }
  aggregate {
    ...AggregateReceiptParserOperatorDeepNesting
  }
}
`

export const AggregateK12PhoneDeepNestingFragment = `fragment AggregateK12PhoneDeepNesting on AggregateK12Phone {
  count
}
`

export const ReceiptParserOperatorEdgeDeepNestingFragment = `fragment ReceiptParserOperatorEdgeDeepNesting on ReceiptParserOperatorEdge {
  node {
    ...ReceiptParserOperatorDeepNesting
  }
  cursor
}
`

export const AggregateNewsletterDeepNestingFragment = `fragment AggregateNewsletterDeepNesting on AggregateNewsletter {
  count
}
`

export const ReceiptParserOperatorPreviousValuesDeepNestingFragment = `fragment ReceiptParserOperatorPreviousValuesDeepNesting on ReceiptParserOperatorPreviousValues {
  id
  createdAt
  updatedAt
  name
  code
}
`

export const AggregateFileDeepNestingFragment = `fragment AggregateFileDeepNesting on AggregateFile {
  count
}
`

export const ReceiptParserOperatorSubscriptionPayloadDeepNestingFragment = `fragment ReceiptParserOperatorSubscriptionPayloadDeepNesting on ReceiptParserOperatorSubscriptionPayload {
  mutation
  node {
    ...ReceiptParserOperatorDeepNesting
  }
  updatedFields
  previousValues {
    ...ReceiptParserOperatorPreviousValuesDeepNesting
  }
}
`

export const TemplateDeepNestingFragment = `fragment TemplateDeepNesting on Template {
  id
  createdAt
  updatedAt
  externalKey
  name
  description
  component
  props
  components
  vars
  rank
  Parent {
    ...TemplateDeepNesting
  }
  CreatedBy {
    ...UserDeepNesting
  }
  Project {
    ...ProjectDeepNesting
  }
  PrismaProject {
    ...ProjectDeepNesting
  }
}
`

export const ReceiptParserResultDeepNestingFragment = `fragment ReceiptParserResultDeepNesting on ReceiptParserResult {
  id
  createdAt
  updatedAt
}
`

export const AggregateTemplateDeepNestingFragment = `fragment AggregateTemplateDeepNesting on AggregateTemplate {
  count
}
`

export const ReceiptParserResultConnectionDeepNestingFragment = `fragment ReceiptParserResultConnectionDeepNesting on ReceiptParserResultConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ReceiptParserResultEdgeDeepNesting
  }
  aggregate {
    ...AggregateReceiptParserResultDeepNesting
  }
}
`

export const BotProfileConnectionDeepNestingFragment = `fragment BotProfileConnectionDeepNesting on BotProfileConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...BotProfileEdgeDeepNesting
  }
  aggregate {
    ...AggregateBotProfileDeepNesting
  }
}
`

export const ReceiptParserResultEdgeDeepNestingFragment = `fragment ReceiptParserResultEdgeDeepNesting on ReceiptParserResultEdge {
  node {
    ...ReceiptParserResultDeepNesting
  }
  cursor
}
`

export const CheckDeepNestingFragment = `fragment CheckDeepNesting on Check {
  externalKey
  sum
}
`

export const ReceiptParserResultPreviousValuesDeepNestingFragment = `fragment ReceiptParserResultPreviousValuesDeepNesting on ReceiptParserResultPreviousValues {
  id
  createdAt
  updatedAt
}
`

export const AggregateForwardClientDeepNestingFragment = `fragment AggregateForwardClientDeepNesting on AggregateForwardClient {
  count
}
`

export const ReceiptParserResultSubscriptionPayloadDeepNestingFragment = `fragment ReceiptParserResultSubscriptionPayloadDeepNesting on ReceiptParserResultSubscriptionPayload {
  mutation
  node {
    ...ReceiptParserResultDeepNesting
  }
  updatedFields
  previousValues {
    ...ReceiptParserResultPreviousValuesDeepNesting
  }
}
`

export const TarifInfoExtensionDeepNestingFragment = `fragment TarifInfoExtensionDeepNesting on TarifInfoExtension {
  ID_EXTENSION
  V_EXTENSION_NAME
  N_EXTENSION_PRICE_RUB
  N_EXTENSION_PRICE_BAL
  B_EXTENSION_CONNECT
}
`

export const ReceiptPreviousValuesDeepNestingFragment = `fragment ReceiptPreviousValuesDeepNesting on ReceiptPreviousValues {
  id
  hash
  photo
  s
  fn
  i
  fp
  t
  n
  parserAttempts
  parserStatus
  parserData
  createdAt
  updatedAt
  externalKey
}
`

export const SurveymonkeyImportSurveysResponseDeepNestingFragment = `fragment SurveymonkeyImportSurveysResponseDeepNesting on SurveymonkeyImportSurveysResponse {
  created
  updated
}
`

export const ReceiptSubscriptionPayloadDeepNestingFragment = `fragment ReceiptSubscriptionPayloadDeepNesting on ReceiptSubscriptionPayload {
  mutation
  node {
    ...ReceiptDeepNesting
  }
  updatedFields
  previousValues {
    ...ReceiptPreviousValuesDeepNesting
  }
}
`

export const BuyExtensionResultDeepNestingFragment = `fragment BuyExtensionResultDeepNesting on BuyExtensionResult {
  N_ERROR
  V_ERROR
}
`

export const RegistrationConnectionDeepNestingFragment = `fragment RegistrationConnectionDeepNesting on RegistrationConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...RegistrationEdgeDeepNesting
  }
  aggregate {
    ...AggregateRegistrationDeepNesting
  }
}
`

export const PartnerProductResponseDeepNestingFragment = `fragment PartnerProductResponseDeepNesting on PartnerProductResponse {
  success
  message
  data {
    ...PartnerProductDeepNesting
  }
}
`

export const RegistrationEdgeDeepNestingFragment = `fragment RegistrationEdgeDeepNesting on RegistrationEdge {
  node {
    ...RegistrationDeepNesting
  }
  cursor
}
`

export const SberbankRegisterDeepNestingFragment = `fragment SberbankRegisterDeepNesting on SberbankRegister {
  orderId
  formUrl
}
`

export const RegistrationPreviousValuesDeepNestingFragment = `fragment RegistrationPreviousValuesDeepNesting on RegistrationPreviousValues {
  id
  firstName
  secondName
  lastName
  name
  email
  birthday
  isUnderage
  photoPassport
  photoRegistration
  photoSelfie
  status
  comment
  isLoginAttempt
  createdAt
  updatedAt
}
`

export const LoginPayloadDeepNestingFragment = `fragment LoginPayloadDeepNesting on LoginPayload {
  token
  user {
    ...UserDeepNesting
  }
}
`

export const RegistrationSubscriptionPayloadDeepNestingFragment = `fragment RegistrationSubscriptionPayloadDeepNesting on RegistrationSubscriptionPayload {
  mutation
  node {
    ...RegistrationDeepNesting
  }
  updatedFields
  previousValues {
    ...RegistrationPreviousValuesDeepNesting
  }
}
`

export const K12PhoneResponseDeepNestingFragment = `fragment K12PhoneResponseDeepNesting on K12PhoneResponse {
  success
  message
  data {
    ...K12PhoneDeepNesting
  }
}
`

export const RequestConnectionDeepNestingFragment = `fragment RequestConnectionDeepNesting on RequestConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...RequestEdgeDeepNesting
  }
  aggregate {
    ...AggregateRequestDeepNesting
  }
}
`

export const SavedCheckDeepNestingFragment = `fragment SavedCheckDeepNesting on SavedCheck {
  ID_CHECK
}
`

export const RequestEdgeDeepNestingFragment = `fragment RequestEdgeDeepNesting on RequestEdge {
  node {
    ...RequestDeepNesting
  }
  cursor
}
`

export const BotMailingResponseDeepNestingFragment = `fragment BotMailingResponseDeepNesting on BotMailingResponse {
  success
  message
  data {
    ...BotMailingDeepNesting
  }
}
`

export const RequestPreviousValuesDeepNestingFragment = `fragment RequestPreviousValuesDeepNesting on RequestPreviousValues {
  id
  name
  phone
  status
  createdAt
  updatedAt
}
`

export const IMSIResponseDeepNestingFragment = `fragment IMSIResponseDeepNesting on IMSIResponse {
  success
  message
  data {
    ...K12PhoneDeepNesting
  }
}
`

export const RequestSubscriptionPayloadDeepNestingFragment = `fragment RequestSubscriptionPayloadDeepNesting on RequestSubscriptionPayload {
  mutation
  node {
    ...RequestDeepNesting
  }
  updatedFields
  previousValues {
    ...RequestPreviousValuesDeepNesting
  }
}
`

export const TemplatePreviousValuesDeepNestingFragment = `fragment TemplatePreviousValuesDeepNesting on TemplatePreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  name
  description
  component
  props
  components
  vars
  rank
}
`

export const ResetPasswordDeepNestingFragment = `fragment ResetPasswordDeepNesting on ResetPassword {
  id
  createdAt
  updatedAt
  code
  password
  validTill
  User {
    ...UserDeepNesting
  }
}
`

export const AggregateDeviceDeepNestingFragment = `fragment AggregateDeviceDeepNesting on AggregateDevice {
  count
}
`

export const ResetPasswordConnectionDeepNestingFragment = `fragment ResetPasswordConnectionDeepNesting on ResetPasswordConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ResetPasswordEdgeDeepNesting
  }
  aggregate {
    ...AggregateResetPasswordDeepNesting
  }
}
`

export const AggregateLogDeepNestingFragment = `fragment AggregateLogDeepNesting on AggregateLog {
  count
}
`

export const ResetPasswordEdgeDeepNestingFragment = `fragment ResetPasswordEdgeDeepNesting on ResetPasswordEdge {
  node {
    ...ResetPasswordDeepNesting
  }
  cursor
}
`

export const AggregatePartnerCategoryDeepNestingFragment = `fragment AggregatePartnerCategoryDeepNesting on AggregatePartnerCategory {
  count
}
`

export const ResetPasswordPreviousValuesDeepNestingFragment = `fragment ResetPasswordPreviousValuesDeepNesting on ResetPasswordPreviousValues {
  id
  createdAt
  updatedAt
  code
  password
  validTill
}
`

export const AggregatePlanDeepNestingFragment = `fragment AggregatePlanDeepNesting on AggregatePlan {
  count
}
`

export const ResetPasswordSubscriptionPayloadDeepNestingFragment = `fragment ResetPasswordSubscriptionPayloadDeepNesting on ResetPasswordSubscriptionPayload {
  mutation
  node {
    ...ResetPasswordDeepNesting
  }
  updatedFields
  previousValues {
    ...ResetPasswordPreviousValuesDeepNesting
  }
}
`

export const AggregatePollResultDeepNestingFragment = `fragment AggregatePollResultDeepNesting on AggregatePollResult {
  count
}
`

export const SBOrderConnectionDeepNestingFragment = `fragment SBOrderConnectionDeepNesting on SBOrderConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SBOrderEdgeDeepNesting
  }
  aggregate {
    ...AggregateSBOrderDeepNesting
  }
}
`

export const AggregateReceiptParserLogDeepNestingFragment = `fragment AggregateReceiptParserLogDeepNesting on AggregateReceiptParserLog {
  count
}
`

export const SBOrderEdgeDeepNestingFragment = `fragment SBOrderEdgeDeepNesting on SBOrderEdge {
  node {
    ...SBOrderDeepNesting
  }
  cursor
}
`

export const AggregateRequestDeepNestingFragment = `fragment AggregateRequestDeepNesting on AggregateRequest {
  count
}
`

export const SBOrderPreviousValuesDeepNestingFragment = `fragment SBOrderPreviousValuesDeepNesting on SBOrderPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  number
  orderStatus
  amount
  currency
  date
  paymentAmountInfo
}
`

export const AggregateSegmentCriteriaDeepNestingFragment = `fragment AggregateSegmentCriteriaDeepNesting on AggregateSegmentCriteria {
  count
}
`

export const SBOrderSubscriptionPayloadDeepNestingFragment = `fragment SBOrderSubscriptionPayloadDeepNesting on SBOrderSubscriptionPayload {
  mutation
  node {
    ...SBOrderDeepNesting
  }
  updatedFields
  previousValues {
    ...SBOrderPreviousValuesDeepNesting
  }
}
`

export const AggregateSurveymonkeyUserDeepNestingFragment = `fragment AggregateSurveymonkeyUserDeepNesting on AggregateSurveymonkeyUser {
  count
}
`

export const SegmentConnectionDeepNestingFragment = `fragment SegmentConnectionDeepNesting on SegmentConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SegmentEdgeDeepNesting
  }
  aggregate {
    ...AggregateSegmentDeepNesting
  }
}
`

export const AggregateUserLogDeepNestingFragment = `fragment AggregateUserLogDeepNesting on AggregateUserLog {
  count
}
`

export const SegmentEdgeDeepNestingFragment = `fragment SegmentEdgeDeepNesting on SegmentEdge {
  node {
    ...SegmentDeepNesting
  }
  cursor
}
`

export const PartnerCategoryDeepNestingFragment = `fragment PartnerCategoryDeepNesting on PartnerCategory {
  id
  name
  createdAt
  updatedAt
  level
}
`

export const SegmentCriteriaConnectionDeepNestingFragment = `fragment SegmentCriteriaConnectionDeepNesting on SegmentCriteriaConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SegmentCriteriaEdgeDeepNesting
  }
  aggregate {
    ...AggregateSegmentCriteriaDeepNesting
  }
}
`

export const ReceiptParserLogDeepNestingFragment = `fragment ReceiptParserLogDeepNesting on ReceiptParserLog {
  id
  createdAt
  ParserOperator {
    ...ReceiptParserOperatorDeepNesting
  }
  Receipt {
    ...ReceiptDeepNesting
  }
}
`

export const SegmentCriteriaEdgeDeepNestingFragment = `fragment SegmentCriteriaEdgeDeepNesting on SegmentCriteriaEdge {
  node {
    ...SegmentCriteriaDeepNesting
  }
  cursor
}
`

export const PollResultAnswerDeepNestingFragment = `fragment PollResultAnswerDeepNesting on PollResultAnswer {
  id
  pollResult {
    ...PollResultDeepNesting
  }
  answer {
    ...PollQuestionItemDeepNesting
  }
}
`

export const SegmentCriteriaPreviousValuesDeepNestingFragment = `fragment SegmentCriteriaPreviousValuesDeepNesting on SegmentCriteriaPreviousValues {
  id
  from
  to
}
`

export const ForwardContractServiceDeepNestingFragment = `fragment ForwardContractServiceDeepNesting on ForwardContractService {
  id
  externalKey
  Contract {
    ...ForwardClientContractDeepNesting
  }
}
`

export const SegmentCriteriaSubscriptionPayloadDeepNestingFragment = `fragment SegmentCriteriaSubscriptionPayloadDeepNesting on SegmentCriteriaSubscriptionPayload {
  mutation
  node {
    ...SegmentCriteriaDeepNesting
  }
  updatedFields
  previousValues {
    ...SegmentCriteriaPreviousValuesDeepNesting
  }
}
`

export const BillingDeepNestingFragment = `fragment BillingDeepNesting on Billing {
  id
  k12Phone {
    ...K12PhoneDeepNesting
  }
  name
  points
  cash
  traffic
  minutes
  sms
  service {
    ...ServiceDeepNesting
  }
  createdAt
  updatedAt
  type
}
`

export const SegmentCriteriaTypeConnectionDeepNestingFragment = `fragment SegmentCriteriaTypeConnectionDeepNesting on SegmentCriteriaTypeConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SegmentCriteriaTypeEdgeDeepNesting
  }
  aggregate {
    ...AggregateSegmentCriteriaTypeDeepNesting
  }
}
`

export const ArticleConnectionDeepNestingFragment = `fragment ArticleConnectionDeepNesting on ArticleConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ArticleEdgeDeepNesting
  }
  aggregate {
    ...AggregateArticleDeepNesting
  }
}
`

export const SegmentCriteriaTypeEdgeDeepNestingFragment = `fragment SegmentCriteriaTypeEdgeDeepNesting on SegmentCriteriaTypeEdge {
  node {
    ...SegmentCriteriaTypeDeepNesting
  }
  cursor
}
`

export const PackageEdgeDeepNestingFragment = `fragment PackageEdgeDeepNesting on PackageEdge {
  node {
    ...PackageDeepNesting
  }
  cursor
}
`

export const SegmentCriteriaTypePreviousValuesDeepNestingFragment = `fragment SegmentCriteriaTypePreviousValuesDeepNesting on SegmentCriteriaTypePreviousValues {
  id
  name
  isBetween
  isAttachPartners
}
`

export const AggregatePartnerDeepNestingFragment = `fragment AggregatePartnerDeepNesting on AggregatePartner {
  count
}
`

export const SegmentCriteriaTypeSubscriptionPayloadDeepNestingFragment = `fragment SegmentCriteriaTypeSubscriptionPayloadDeepNesting on SegmentCriteriaTypeSubscriptionPayload {
  mutation
  node {
    ...SegmentCriteriaTypeDeepNesting
  }
  updatedFields
  previousValues {
    ...SegmentCriteriaTypePreviousValuesDeepNesting
  }
}
`

export const PushNotificationEdgeDeepNestingFragment = `fragment PushNotificationEdgeDeepNesting on PushNotificationEdge {
  node {
    ...PushNotificationDeepNesting
  }
  cursor
}
`

export const SegmentPreviousValuesDeepNestingFragment = `fragment SegmentPreviousValuesDeepNesting on SegmentPreviousValues {
  id
  name
  createdAt
  updatedAt
}
`

export const SettingEdgeDeepNestingFragment = `fragment SettingEdgeDeepNesting on SettingEdge {
  node {
    ...SettingDeepNesting
  }
  cursor
}
`

export const SegmentSubscriptionPayloadDeepNestingFragment = `fragment SegmentSubscriptionPayloadDeepNesting on SegmentSubscriptionPayload {
  mutation
  node {
    ...SegmentDeepNesting
  }
  updatedFields
  previousValues {
    ...SegmentPreviousValuesDeepNesting
  }
}
`

export const SurveymonkeyCollectorEdgeDeepNestingFragment = `fragment SurveymonkeyCollectorEdgeDeepNesting on SurveymonkeyCollectorEdge {
  node {
    ...SurveymonkeyCollectorDeepNesting
  }
  cursor
}
`

export const ServiceConnectionDeepNestingFragment = `fragment ServiceConnectionDeepNesting on ServiceConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ServiceEdgeDeepNesting
  }
  aggregate {
    ...AggregateServiceDeepNesting
  }
}
`

export const SurveyDeepNestingFragment = `fragment SurveyDeepNesting on Survey {
  id
  name
  description
  approximate_time
  points
  url
}
`

export const ServiceEdgeDeepNestingFragment = `fragment ServiceEdgeDeepNesting on ServiceEdge {
  node {
    ...ServiceDeepNesting
  }
  cursor
}
`

export const NewsletterConnectionDeepNestingFragment = `fragment NewsletterConnectionDeepNesting on NewsletterConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...NewsletterEdgeDeepNesting
  }
  aggregate {
    ...AggregateNewsletterDeepNesting
  }
}
`

export const ServicePreviousValuesDeepNestingFragment = `fragment ServicePreviousValuesDeepNesting on ServicePreviousValues {
  id
  name
  description
  image
  priceInPoints
  priceInCash
  priceFeePoints
  priceFeeCash
  default
  active
  createdAt
  updatedAt
}
`

export const UserEdgeDeepNestingFragment = `fragment UserEdgeDeepNesting on UserEdge {
  node {
    ...UserDeepNesting
  }
  cursor
}
`

export const ServiceSubscriptionPayloadDeepNestingFragment = `fragment ServiceSubscriptionPayloadDeepNesting on ServiceSubscriptionPayload {
  mutation
  node {
    ...ServiceDeepNesting
  }
  updatedFields
  previousValues {
    ...ServicePreviousValuesDeepNesting
  }
}
`

export const BotMailingEdgeDeepNestingFragment = `fragment BotMailingEdgeDeepNesting on BotMailingEdge {
  node {
    ...BotMailingDeepNesting
  }
  cursor
}
`

export const SettingPreviousValuesDeepNestingFragment = `fragment SettingPreviousValuesDeepNesting on SettingPreviousValues {
  id
  name
  key
  value
  group
}
`

export const ForwardClientConnectionDeepNestingFragment = `fragment ForwardClientConnectionDeepNesting on ForwardClientConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...ForwardClientEdgeDeepNesting
  }
  aggregate {
    ...AggregateForwardClientDeepNesting
  }
}
`

export const SettingSubscriptionPayloadDeepNestingFragment = `fragment SettingSubscriptionPayloadDeepNesting on SettingSubscriptionPayload {
  mutation
  node {
    ...SettingDeepNesting
  }
  updatedFields
  previousValues {
    ...SettingPreviousValuesDeepNesting
  }
}
`

export const ErrorDeepNestingFragment = `fragment ErrorDeepNesting on Error {
  key
  message
}
`

export const SurveymonkeyCollectorPreviousValuesDeepNestingFragment = `fragment SurveymonkeyCollectorPreviousValuesDeepNesting on SurveymonkeyCollectorPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  name
  hash
  url
  href
  status
  type
}
`

export const PartnerResponseDeepNestingFragment = `fragment PartnerResponseDeepNesting on PartnerResponse {
  success
  message
  data {
    ...PartnerDeepNesting
  }
}
`

export const SurveymonkeyCollectorResponseDeepNestingFragment = `fragment SurveymonkeyCollectorResponseDeepNesting on SurveymonkeyCollectorResponse {
  success
  message
  data {
    ...SurveymonkeyCollectorDeepNesting
  }
}
`

export const PaymentDeepNestingFragment = `fragment PaymentDeepNesting on Payment {
  id
  createdAt
  updatedAt
  amount
  status
  User {
    ...UserDeepNesting
  }
  K12Phone {
    ...K12PhoneDeepNesting
  }
  ForwardClient {
    ...ForwardClientDeepNesting
  }
  ForwardClientContract {
    ...ForwardClientContractDeepNesting
  }
  ForwardContractService {
    ...ForwardContractServiceDeepNesting
  }
  SBOrder {
    ...SBOrderDeepNesting
  }
}
`

export const SurveymonkeyCollectorSubscriptionPayloadDeepNestingFragment = `fragment SurveymonkeyCollectorSubscriptionPayloadDeepNesting on SurveymonkeyCollectorSubscriptionPayload {
  mutation
  node {
    ...SurveymonkeyCollectorDeepNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeyCollectorPreviousValuesDeepNesting
  }
}
`

export const ForwardCheckDeepNestingFragment = `fragment ForwardCheckDeepNesting on ForwardCheck {
  ID_CHECK
}
`

export const SurveymonkeyResponsePreviousValuesDeepNestingFragment = `fragment SurveymonkeyResponsePreviousValuesDeepNesting on SurveymonkeyResponsePreviousValues {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  collector_id
  recipient_id
  total_time
  custom_value
  edit_url
  analyze_url
  ip_address
  custom_variables
  logic_path
  metadata
  response_status
  collection_mode
  href
  respondent_id
  points
  points_applyed
}
`

export const SignContractResponseDeepNestingFragment = `fragment SignContractResponseDeepNesting on SignContractResponse {
  F_PDF_CONTRACT
}
`

export const SurveymonkeyResponseSubscriptionPayloadDeepNestingFragment = `fragment SurveymonkeyResponseSubscriptionPayloadDeepNesting on SurveymonkeyResponseSubscriptionPayload {
  mutation
  node {
    ...SurveymonkeyResponseDeepNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeyResponsePreviousValuesDeepNesting
  }
}
`

export const AggregateBotChatDeepNestingFragment = `fragment AggregateBotChatDeepNesting on AggregateBotChat {
  count
}
`

export const SurveymonkeySurveyPreviousValuesDeepNestingFragment = `fragment SurveymonkeySurveyPreviousValuesDeepNesting on SurveymonkeySurveyPreviousValues {
  id
  createdAt
  updatedAt
  date_created
  date_modified
  externalKey
  name
  nickname
  href
  custom_variables
  active
  approximate_time
  points
  description
}
`

export const AggregateNewsletterJournalDeepNestingFragment = `fragment AggregateNewsletterJournalDeepNesting on AggregateNewsletterJournal {
  count
}
`

export const SurveymonkeySurveySubscriptionPayloadDeepNestingFragment = `fragment SurveymonkeySurveySubscriptionPayloadDeepNesting on SurveymonkeySurveySubscriptionPayload {
  mutation
  node {
    ...SurveymonkeySurveyDeepNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeySurveyPreviousValuesDeepNesting
  }
}
`

export const AggregatePollQuestionDeepNestingFragment = `fragment AggregatePollQuestionDeepNesting on AggregatePollQuestion {
  count
}
`

export const SurveymonkeySurveyWebhookConnectionDeepNestingFragment = `fragment SurveymonkeySurveyWebhookConnectionDeepNesting on SurveymonkeySurveyWebhookConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SurveymonkeySurveyWebhookEdgeDeepNesting
  }
  aggregate {
    ...AggregateSurveymonkeySurveyWebhookDeepNesting
  }
}
`

export const AggregateReceiptParserResultDeepNestingFragment = `fragment AggregateReceiptParserResultDeepNesting on AggregateReceiptParserResult {
  count
}
`

export const SurveymonkeySurveyWebhookEdgeDeepNestingFragment = `fragment SurveymonkeySurveyWebhookEdgeDeepNesting on SurveymonkeySurveyWebhookEdge {
  node {
    ...SurveymonkeySurveyWebhookDeepNesting
  }
  cursor
}
`

export const AggregateServiceDeepNestingFragment = `fragment AggregateServiceDeepNesting on AggregateService {
  count
}
`

export const SurveymonkeySurveyWebhookPreviousValuesDeepNestingFragment = `fragment SurveymonkeySurveyWebhookPreviousValuesDeepNesting on SurveymonkeySurveyWebhookPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  name
  event_type
  subscription_url
  object_type
  href
}
`

export const SegmentDeepNestingFragment = `fragment SegmentDeepNesting on Segment {
  id
  name
  criterias {
    ...SegmentCriteriaDeepNesting
  }
  createdAt
  updatedAt
}
`

export const SurveymonkeySurveyWebhookSubscriptionPayloadDeepNestingFragment = `fragment SurveymonkeySurveyWebhookSubscriptionPayloadDeepNesting on SurveymonkeySurveyWebhookSubscriptionPayload {
  mutation
  node {
    ...SurveymonkeySurveyWebhookDeepNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeySurveyWebhookPreviousValuesDeepNesting
  }
}
`

export const PollResultDeepNestingFragment = `fragment PollResultDeepNesting on PollResult {
  id
  poll {
    ...PollDeepNesting
  }
  user {
    ...UserDeepNesting
  }
  startedAt
  endedAt
  answers {
    ...PollResultAnswerDeepNesting
  }
  status
  createdAt
  updatedAt
}
`

export const SurveymonkeyUserConnectionDeepNestingFragment = `fragment SurveymonkeyUserConnectionDeepNesting on SurveymonkeyUserConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...SurveymonkeyUserEdgeDeepNesting
  }
  aggregate {
    ...AggregateSurveymonkeyUserDeepNesting
  }
}
`

export const NotificationTypeDeepNestingFragment = `fragment NotificationTypeDeepNesting on NotificationType {
  id
  name
}
`

export const SurveymonkeyUserEdgeDeepNestingFragment = `fragment SurveymonkeyUserEdgeDeepNesting on SurveymonkeyUserEdge {
  node {
    ...SurveymonkeyUserDeepNesting
  }
  cursor
}
`

export const CouponConnectionDeepNestingFragment = `fragment CouponConnectionDeepNesting on CouponConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...CouponEdgeDeepNesting
  }
  aggregate {
    ...AggregateCouponDeepNesting
  }
}
`

export const SurveymonkeyUserPreviousValuesDeepNestingFragment = `fragment SurveymonkeyUserPreviousValuesDeepNesting on SurveymonkeyUserPreviousValues {
  id
  createdAt
  updatedAt
  externalKey
  first_name
  last_name
  email_address
}
`

export const PromotionConnectionDeepNestingFragment = `fragment PromotionConnectionDeepNesting on PromotionConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...PromotionEdgeDeepNesting
  }
  aggregate {
    ...AggregatePromotionDeepNesting
  }
}
`

export const SurveymonkeyUserSubscriptionPayloadDeepNestingFragment = `fragment SurveymonkeyUserSubscriptionPayloadDeepNesting on SurveymonkeyUserSubscriptionPayload {
  mutation
  node {
    ...SurveymonkeyUserDeepNesting
  }
  updatedFields
  previousValues {
    ...SurveymonkeyUserPreviousValuesDeepNesting
  }
}
`

export const SurveymonkeySurveyWebhookDeepNestingFragment = `fragment SurveymonkeySurveyWebhookDeepNesting on SurveymonkeySurveyWebhook {
  id
  createdAt
  updatedAt
  externalKey
  name
  event_type
  subscription_url
  object_type
  href
  Surveys {
    ...SurveymonkeySurveyDeepNesting
  }
}
`

export const TransactionConnectionDeepNestingFragment = `fragment TransactionConnectionDeepNesting on TransactionConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...TransactionEdgeDeepNesting
  }
  aggregate {
    ...AggregateTransactionDeepNesting
  }
}
`

export const K12PhoneConnectionDeepNestingFragment = `fragment K12PhoneConnectionDeepNesting on K12PhoneConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...K12PhoneEdgeDeepNesting
  }
  aggregate {
    ...AggregateK12PhoneDeepNesting
  }
}
`

export const TransactionEdgeDeepNestingFragment = `fragment TransactionEdgeDeepNesting on TransactionEdge {
  node {
    ...TransactionDeepNesting
  }
  cursor
}
`

export const TemplateConnectionDeepNestingFragment = `fragment TemplateConnectionDeepNesting on TemplateConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...TemplateEdgeDeepNesting
  }
  aggregate {
    ...AggregateTemplateDeepNesting
  }
}
`

export const TransactionPreviousValuesDeepNestingFragment = `fragment TransactionPreviousValuesDeepNesting on TransactionPreviousValues {
  id
  points
  comment
  status
  createdAt
  updatedAt
  deletedAt
}
`

export const TarifInfoDeepNestingFragment = `fragment TarifInfoDeepNesting on TarifInfo {
  ID_SERVICE
  V_SERVICE
  N_SERVICE_PRICE_RUB
  N_SERVICE_PRICE_BAL
  B_SERVICE_CONNECT
}
`

export const TransactionPromotionConnectionDeepNestingFragment = `fragment TransactionPromotionConnectionDeepNesting on TransactionPromotionConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...TransactionPromotionEdgeDeepNesting
  }
  aggregate {
    ...AggregateTransactionPromotionDeepNesting
  }
}
`

export const PromotionResponseDeepNestingFragment = `fragment PromotionResponseDeepNesting on PromotionResponse {
  success
  message
  data {
    ...PromotionDeepNesting
  }
}
`

export const TransactionPromotionEdgeDeepNestingFragment = `fragment TransactionPromotionEdgeDeepNesting on TransactionPromotionEdge {
  node {
    ...TransactionPromotionDeepNesting
  }
  cursor
}
`

export const UserResponseDeepNestingFragment = `fragment UserResponseDeepNesting on UserResponse {
  success
  message
  data {
    ...UserDeepNesting
  }
}
`

export const TransactionPromotionPreviousValuesDeepNestingFragment = `fragment TransactionPromotionPreviousValuesDeepNesting on TransactionPromotionPreviousValues {
  id
  createdAt
  updatedAt
  amount
  coefficient
  points
}
`

export const AggregateForwardContractServiceDeepNestingFragment = `fragment AggregateForwardContractServiceDeepNesting on AggregateForwardContractService {
  count
}
`

export const TransactionPromotionResponseDeepNestingFragment = `fragment TransactionPromotionResponseDeepNesting on TransactionPromotionResponse {
  success
  message
  data {
    ...TransactionPromotionDeepNesting
  }
}
`

export const AggregateProjectDeepNestingFragment = `fragment AggregateProjectDeepNesting on AggregateProject {
  count
}
`

export const TransactionPromotionSubscriptionPayloadDeepNestingFragment = `fragment TransactionPromotionSubscriptionPayloadDeepNesting on TransactionPromotionSubscriptionPayload {
  mutation
  node {
    ...TransactionPromotionDeepNesting
  }
  updatedFields
  previousValues {
    ...TransactionPromotionPreviousValuesDeepNesting
  }
}
`

export const AggregateTransactionPromotionDeepNestingFragment = `fragment AggregateTransactionPromotionDeepNesting on AggregateTransactionPromotion {
  count
}
`

export const TransactionSubscriptionPayloadDeepNestingFragment = `fragment TransactionSubscriptionPayloadDeepNesting on TransactionSubscriptionPayload {
  mutation
  node {
    ...TransactionDeepNesting
  }
  updatedFields
  previousValues {
    ...TransactionPreviousValuesDeepNesting
  }
}
`

export const RegistrationDeepNestingFragment = `fragment RegistrationDeepNesting on Registration {
  id
  device {
    ...DeviceDeepNesting
  }
  k12Phone {
    ...K12PhoneDeepNesting
  }
  user {
    ...UserDeepNesting
  }
  firstName
  secondName
  lastName
  name
  email
  birthday
  isUnderage
  photoPassport
  photoRegistration
  photoSelfie
  status
  comment
  isLoginAttempt
  createdAt
  updatedAt
}
`

export const UserGroupConnectionDeepNestingFragment = `fragment UserGroupConnectionDeepNesting on UserGroupConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...UserGroupEdgeDeepNesting
  }
  aggregate {
    ...AggregateUserGroupDeepNesting
  }
}
`

export const PageEdgeDeepNestingFragment = `fragment PageEdgeDeepNesting on PageEdge {
  node {
    ...PageDeepNesting
  }
  cursor
}
`

export const UserGroupEdgeDeepNestingFragment = `fragment UserGroupEdgeDeepNesting on UserGroupEdge {
  node {
    ...UserGroupDeepNesting
  }
  cursor
}
`

export const AggregateSurveymonkeySurveyDeepNestingFragment = `fragment AggregateSurveymonkeySurveyDeepNesting on AggregateSurveymonkeySurvey {
  count
}
`

export const UserGroupPreviousValuesDeepNestingFragment = `fragment UserGroupPreviousValuesDeepNesting on UserGroupPreviousValues {
  id
  createdAt
  updatedAt
  name
}
`

export const AggregateBotProfileDeepNestingFragment = `fragment AggregateBotProfileDeepNesting on AggregateBotProfile {
  count
}
`

export const UserGroupSubscriptionPayloadDeepNestingFragment = `fragment UserGroupSubscriptionPayloadDeepNesting on UserGroupSubscriptionPayload {
  mutation
  node {
    ...UserGroupDeepNesting
  }
  updatedFields
  previousValues {
    ...UserGroupPreviousValuesDeepNesting
  }
}
`

export const AuthDeviceDeepNestingFragment = `fragment AuthDeviceDeepNesting on AuthDevice {
  token
}
`

export const UserLogConnectionDeepNestingFragment = `fragment UserLogConnectionDeepNesting on UserLogConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...UserLogEdgeDeepNesting
  }
  aggregate {
    ...AggregateUserLogDeepNesting
  }
}
`

export const AggregatePartnerProductDeepNestingFragment = `fragment AggregatePartnerProductDeepNesting on AggregatePartnerProduct {
  count
}
`

export const UserLogEdgeDeepNestingFragment = `fragment UserLogEdgeDeepNesting on UserLogEdge {
  node {
    ...UserLogDeepNesting
  }
  cursor
}
`

export const CouponDeepNestingFragment = `fragment CouponDeepNesting on Coupon {
  id
  createdAt
  updatedAt
  name
  conditions
  description
  content
  url
  preview
  canComplain
  segments {
    ...SegmentDeepNesting
  }
  startAt
  endAt
  published
  partner {
    ...PartnerDeepNesting
  }
}
`

export const UserLogPreviousValuesDeepNestingFragment = `fragment UserLogPreviousValuesDeepNesting on UserLogPreviousValues {
  id
  action
  value
}
`

export const AggregatePushNotificationQueueDeepNestingFragment = `fragment AggregatePushNotificationQueueDeepNesting on AggregatePushNotificationQueue {
  count
}
`

export const CouponResponseDeepNestingFragment = `fragment CouponResponseDeepNesting on CouponResponse {
  success
  message
  data {
    ...CouponDeepNesting
  }
}
`

export const VerificationPreviousValuesDeepNestingFragment = `fragment VerificationPreviousValuesDeepNesting on VerificationPreviousValues {
  id
  hash
  attempts
  refetchAttempts
  createdAt
  updatedAt
}
`

export const VerificationEdgeDeepNestingFragment = `fragment VerificationEdgeDeepNesting on VerificationEdge {
  node {
    ...VerificationDeepNesting
  }
  cursor
}
`

export const VerificationConnectionDeepNestingFragment = `fragment VerificationConnectionDeepNesting on VerificationConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...VerificationEdgeDeepNesting
  }
  aggregate {
    ...AggregateVerificationDeepNesting
  }
}
`

export const UserLogSubscriptionPayloadDeepNestingFragment = `fragment UserLogSubscriptionPayloadDeepNesting on UserLogSubscriptionPayload {
  mutation
  node {
    ...UserLogDeepNesting
  }
  updatedFields
  previousValues {
    ...UserLogPreviousValuesDeepNesting
  }
}
`

export const UserPreviousValuesDeepNestingFragment = `fragment UserPreviousValuesDeepNesting on UserPreviousValues {
  id
  login
  email
  name
  firstName
  secondName
  lastName
  birthday
  role
  status
  password
  loginAttempts
  blockedAt
  createdAt
  updatedAt
  deletedAt
  marketplaceToken
  username
  phone
  fullname
  image
  address
  hidden
  sudo
  showEmail
  showPhone
}
`

export const FileConnectionDeepNestingFragment = `fragment FileConnectionDeepNesting on FileConnection {
  pageInfo {
    ...PageInfoDeepNesting
  }
  edges {
    ...FileEdgeDeepNesting
  }
  aggregate {
    ...AggregateFileDeepNesting
  }
}
`

export const BotMailingDeepNestingFragment = `fragment BotMailingDeepNesting on BotMailing {
  id
  createdAt
  updatedAt
  name
  message
  Messages {
    ...BotMailingMessageDeepNesting
  }
  status
}
`

export const AggregateSBOrderDeepNestingFragment = `fragment AggregateSBOrderDeepNesting on AggregateSBOrder {
  count
}
`

