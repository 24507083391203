import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'material-ui/styles/withStyles';
import Button from 'material-ui/Button';
import { Link } from 'react-router-dom';

export const styles = {
  root: {
    // "&, & *": {
    //   border: "1px solid",
    //   padding: 5,
    // },

    height: "100%",
    display: "flex",

  },
  sidebar: {
    width: 300,
    height: "100%",
    overflow: "auto",
    borderRight: "1px solid lightgrey",
  },
  mainbar: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    height: "100%",
    overflow: "auto",
    padding: 15,
  },
  appbar: {

  },
}

class MainLayoutView extends PureComponent {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired,
    appbar: PropTypes.object.isRequired,
  };

  render() {

    const {
      classes,
      sidebar,
      appbar,
      children,
    } = this.props;

    return <div
      className={classes.root}
    >
      <div
        className={classes.sidebar}
      >
        <div
          className={classes.appbar}
          style={{
            textAlign: "center",
            padding: 10,
          }}
        >
          <Link
            to="/"
          >
            <Button
              variant="raised"
              color="primary"
              size="large"
            >
              Клуб 12
            </Button>
          </Link>
        </div>

        {sidebar}
      </div>
      <div
        className={classes.mainbar}
      >
        <div
          className={classes.appbar}
        >
          {appbar}
        </div>
        <div
          className={classes.content}
        >
          {children}
        </div>
      </div>
    </div>
  }
}


export default withStyles(styles)(props => <MainLayoutView
  {...props}
/>);