

import { Component } from "react";

import { createMuiTheme } from 'material-ui/styles';

// import pink from 'material-ui/colors/pink';
// import { darken } from 'material-ui/styles/colorManipulator';
// import blue from 'material-ui/colors/blue';
// import red from 'material-ui/colors/red';
// import * as Colors from 'material-ui/colors';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { App as PrismaApp } from "@prisma-cms/front";

import Renderer from "./components/Renderer";

export {
  Renderer,
}


export const getTheme = function (uiTheme) {

  const {
    palette,
    direction,
    // paletteType,
    typography,
    ...other
  } = uiTheme;

  const theme = createMuiTheme({
    direction,
    // nprogress: {
    //   color: paletteType === 'light' ? '#000' : '#fff',
    // },
    palette: {
      ...palette,
      // primary: {
      //   ...blue,
      //   // main: "#ff0000",
      // },
      // secondary: {
      //   // Darken so we reach the AA contrast ratio level.
      //   main: darken(pink.A400, 0.08),
      // },
      // type: paletteType,
      // background: {
      //   default: "#fff",
      // },
      // textColor: Colors.darkBlack,
      // primary1Color: Colors.white,
      // primary2Color: Colors.indigo700,
      // accent1Color: Colors.redA200,
      // pickerHeaderColor: Colors.darkBlack,
      // alternateTextColor: Colors.redA200,
      primary: {
        main: '#ff8f00',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#eceff1',
      },
      // error: {
      //   main: red.A400,
      // },
      background: {
        default: '#fff',
      },
    },
    typography: {
      // fontFamily: "'Open Sans', sans-serif,Tahoma, Helvetica",
      // fontFamily: "Roboto, sans-serif,Tahoma, Helvetica",
      fontSize: 14,
      // display1: {
      //   color: "#222",
      //   fontSize: 30,
      // },
      ...typography,
    },
    ...other,
  });

  // Expose the theme as a global variable so people can play with it.
  if (process.browser) {
    global.theme = theme;
  }

  return theme;
}

export default class App extends PrismaApp {

  static defaultProps = {
    ...PrismaApp.defaultProps,
    Renderer,
    // lang: "ru",
    themeOptions: {
      direction: 'ltr',
      paletteType: 'light',
    },
    useMetamask: false,
    authProps: {
      showRegForm: false,
    },
  }


  getTheme() {

    const {
      themeOptions,
    } = this.state;

    return getTheme({
      ...themeOptions,
    });
  }

}

