import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import Page from '@prisma-cms/front-editor/lib/components/App/components/public/Page';
import View from './View';
import Login from './Login';


export class MainLayout extends EditorComponent {

  static Name = 'MainLayout';

  static defaultProps = {
    ...EditorComponent.defaultProps,
  }


  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        MainLayout
      </div>
    );
  }


  getRootElement() {

    return super.getRootElement();
  }


  canBeParent(parent) {

    return super.canBeParent(parent) && parent instanceof Page;
  }


  canBeChild(child) {

    return super.canBeChild(child);
  }


  renderChildren() {

    const {
      user: currentUser,
    } = this.context;

    // const {
    // } = this.getEditorContext();

    // const {
    //   ...other
    // } = this.getComponentProps(this);

    const {
      sudo,
      role,
    } = currentUser || {};

    if (!sudo && role !== 'ADMIN') {

      return <Login
        key="Login"
      />
    }

    const children = super.renderChildren().slice(0);

    const {
      0: sidebar,
      1: appbar,
    } = children.splice(0, 2);

    return <View
      key="MainLayout"
      sidebar={sidebar}
      appbar={appbar}
    >
      {children}
    </View>
  }

}

export default MainLayout;