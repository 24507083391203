import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';
import { CircularProgress } from 'material-ui/Progress';
import green from 'material-ui/colors/green';
import IconButton from 'material-ui/IconButton';
import CheckIcon from 'material-ui-icons/Check';
import StartIcon from 'material-ui-icons/PlayArrow';
import StopIcon from 'material-ui-icons/Stop';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  button: {
    zIndex: 2,
    width: 48,
    height: 48,
    color: "rgba(0, 0, 0, 0.54)",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    width: 48,
    height: 48,
    color: green[500],
    position: 'absolute',
    top: -10,
    left: -10,
    zIndex: 1,
  },
  // buttonProgress: {
  //   color: green[500],
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   marginTop: -12,
  //   marginLeft: -12,
  // },
});

class BotMailingButtonsView extends React.Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    canBeStarted: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    handleStart: PropTypes.func.isRequired,
    handleStop: PropTypes.func.isRequired,
  };


  // componentWillUnmount() {
  //   clearTimeout(this.timer);
  // }

  // handleButthandleStart = () => {
  //   if (!this.state.loading) {
  //     this.setState(
  //       {
  //         success: false,
  //         loading: true,
  //       },
  //       () => {
  //         this.timer = setTimeout(() => {
  //           this.setState({
  //             loading: false,
  //             success: true,
  //           });
  //         }, 200000);
  //       },
  //     );
  //   }
  // };

  // timer = undefined;

  render() {
    // const { loading, success } = this.state;
    const {
      classes,
      canBeStarted,
      loading,
      success,
      handleStart,
      handleStop,
    } = this.props;

    const buttonClassname = classNames({
      [classes.button]: true,
      [classes.buttonSuccess]: success,
    });

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          {loading
            ?
            <IconButton
              // variant="fab"
              // color="primary"
              className={buttonClassname}
              onClick={handleStop}
              title="Остановить рассылку"
            >
              <StopIcon />
            </IconButton>
            :
            <IconButton
              // variant="fab"
              // color="primary"
              className={buttonClassname}
              onClick={handleStart}
              title="Запустить рассылку"
              disabled={!canBeStarted}
            >
              {success ? <CheckIcon /> : <StartIcon />}
            </IconButton>
          }
          {loading && <CircularProgress size={68} className={classes.fabProgress} />}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BotMailingButtonsView);
