import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import View from './View';

export class SidebarMenu extends EditorComponent {

  static Name = 'SidebarMenu';

  static defaultProps = {
    ...EditorComponent.defaultProps,
    className: "SidebarMenu",
  }


  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        SidebarMenu
      </div>
    );
  }


  getRootElement() {

    return super.getRootElement();
  }


  canBeParent(parent) {

    return super.canBeParent(parent);
  }


  canBeChild(child) {

    return super.canBeChild(child);
  }


  renderChildren() {

    // const {
    // } = this.context;

    const {
      inEditMode,
    } = this.getEditorContext();

    // const {
    //   ...other
    // } = this.getComponentProps(this);

    const children = super.renderChildren();

    return inEditMode ?
      children
      :
      <View
        key="SidebarMenu"
        renderComponent={(component, index) => this.renderComponent(component, index)}
        inEditMode={inEditMode}
      >
        {children}
      </View>
  }

}

export default SidebarMenu;