import React, { Fragment } from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import { EditableObjectContext } from '@prisma-cms/front-editor/lib/components/App/context';
import BotMailingButtonsView from './BotMailingButtonsView';
import { ConnectorContext } from '@prisma-cms/front-editor/lib/components/App/components/public/Connectors/Connector';
import Refetcher from './Refetcher';

export class BotMailingButtons extends EditorComponent {

  static Name = 'BotMailingButtons';

  static defaultProps = {
    ...EditorComponent.defaultProps,
  }


  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        BotMailingButtons
      </div>
    );
  }


  getRootElement() {

    return super.getRootElement();
  }


  canBeParent(parent) {

    return super.canBeParent(parent);
  }


  canBeChild(child) {

    // return super.canBeChild(child);

    return false;
  }


  renderChildren() {

    // const {
    // } = this.context;

    // const {
    // } = this.getEditorContext();

    // const {
    //   ...other
    // } = this.getComponentProps(this);

    // return super.renderChildren();

    return <ConnectorContext.Consumer
      key="BotMailingButtons"
    >

      {connectorContext => {

        const {
          data: {
            refetch,
          },
        } = connectorContext;

        return <EditableObjectContext.Consumer
        >
          {editableObjectContext => {

            const {
              object,
              mutate,
            } = editableObjectContext;

            if (!object) {
              return null;
            }

            const {
              id: botMailingId,
              status,
              Messages,
            } = object;

            if (!botMailingId || !status || !Messages) {
              return null;
            }

            // const haveNonSendedMessages = Messages && Messages.findIndex(({ sended }) => sended === false) !== -1 ? true : false;

            let success = status === 'Completed';
            let loading = status === 'Started';
            const canBeStarted = status === 'Created' || status === 'Canceled' ? true : false;


            return <Fragment>

              <BotMailingButtonsView
                loading={loading}
                success={success}
                canBeStarted={canBeStarted}
                handleStart={() => {
                  mutate({
                    variables: {
                      where: {
                        id: botMailingId,
                      },
                      data: {
                        status: "Started",
                      },
                    },
                  });
                }}
                handleStop={(event) => {

                  mutate({
                    variables: {
                      where: {
                        id: botMailingId,
                      },
                      data: {
                        status: "Canceled",
                      },
                    },
                  });
                }}
              />

              {loading ?
                <Refetcher
                  refetch={refetch}
                />
                : null
              }

            </Fragment>
          }}
        </EditableObjectContext.Consumer>
      }}
    </ConnectorContext.Consumer>
  }

}

export default BotMailingButtons;