import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import { EditableObjectContext } from '@prisma-cms/front-editor/lib/components/App/context';
import { ObjectContext } from '@prisma-cms/front-editor/lib/components/App/components/public/Connectors/Connector/ListView';
import Switch from 'material-ui/Switch';
import Typography from 'material-ui/Typography';

export class NushNotificationPhoneButton extends EditorComponent {

  static Name = 'NushNotificationPhoneButton';

  static defaultProps = {
    ...EditorComponent.defaultProps,
  }


  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        NushNotificationPhoneButton
      </div>
    );
  }


  getRootElement() {

    return super.getRootElement();
  }


  canBeParent(parent) {

    return super.canBeParent(parent);
  }


  canBeChild(child) {

    // return super.canBeChild(child);
    return false;
  }


  /**
   * Этот компонент используется для добавления/удаления номеров в пуш-рассылку.
   * EditableObjectContext - объект рассылки.
   * ObjectContext - телефонный номер
   */
  renderChildren() {

    // const {
    // } = this.context;

    const {
      inEditMode: editorInEditMode,
    } = this.getEditorContext();

    const {
      name,
      // ...other
    } = this.getComponentProps(this);

    // return super.renderChildren();

    if (!name) {

      if (editorInEditMode) {
        return <Typography
          color="error"
        >
          name property is required
        </Typography>
      }
      else {
        return null;
      }

    }

    return <EditableObjectContext.Consumer
      key="NushNotificationPhoneButton"
    >
      {editableObjectContext => {

        const {
          getObjectWithMutations,
          updateObject,
        } = editableObjectContext;

        if (!getObjectWithMutations) {
          return null;
        }

        const {
          [name]: values = [],
        } = getObjectWithMutations();

        return <ObjectContext.Consumer>

          {objectContext => {

            const {
              object,
            } = objectContext;

            if (!object) {
              return null;
            }

            const {
              id: phoneId,
            } = object;

            const index = values.findIndex(n => n === phoneId);
            const checked = index !== -1;

            return <Switch
              checked={checked}
              color={"primary"}
              onChange={(event, checked) => {

                if (checked) {
                  values.push(phoneId);
                }
                else {
                  values.splice(index, 1);
                }

                updateObject({
                  [name]: values,
                });

              }}
            >

            </Switch>;
          }}
        </ObjectContext.Consumer>
      }}
    </EditableObjectContext.Consumer>

  }

}

export default NushNotificationPhoneButton;