import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'material-ui/styles/withStyles';
import MenuItem from './MenuItem';
import List, { ListItem, ListItemText } from 'material-ui/List';
// import Button from 'material-ui/Button';

/**
 * Этот компонент обеспечивает меню с вложенной структурой.
 * Основные уровневые принципы:
 * - Section: элемент вложенности. 
 *   Если внутри есть Section, то будет формироваться вложенность.
 * - Все остальные элементы будут просто выводиться внутри текущего пункта.
 *   Это могут быть как ссылки, так и простые текстовые элементы.
 */

export const styles = theme => {

  // console.log('theme', theme);

  const {
    palette: {
      text: {
        primary: textPrimary,
      },
    },
  } = theme;

  return {
    root: {

      "& a": {
        color: textPrimary,
      },
    },
    nested: {
      paddingLeft: theme.spacing.unit * 4,
    },
  };
}

class SidebarMenuView extends PureComponent {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    renderComponent: PropTypes.func.isRequired,
  };

  render() {

    const {
      classes,
      children,
      renderComponent,
      inEditMode,
    } = this.props;

    let output = [];

    if (children && children.length) {

      let currentItem;
      // let expander;
      // let submenu;

      children.map((n, index) => {

        const {
          props: {
            components,
            // ...props
          },
        } = n;

        const itemComponents = components ? components.slice() : [];

        let itemOutput = [];

        if (itemComponents && itemComponents.length) {

          const {
            0: current,
          } = itemComponents.splice(0, 1);


          if (current) {

            currentItem = renderComponent(current, 'current');

          }

          itemComponents.map((i, index2) => {

            itemOutput.push(
              <ListItem
                key={index2}
                // button={!inEditMode}
                className={classes.nested}
              >
                <ListItemText
                  primary={renderComponent(i, index2)}
                />
              </ListItem>
            );

            return null;
          })

        }

        // if (itemOutput.length) {

        //   submenu = itemOutput;

        //   expander = <svg
        //     className="MuiSvgIcon-root"
        //     focusable="false"
        //     viewBox="0 0 24 24"
        //     aria-hidden="true"
        //     role="presentation"
        //     style={{
        //       fontSize: "1.5rem",
        //       width: "1em",
        //       height: "1em",
        //     }}
        //   >
        //     <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
        //   </svg>
        // }

        // const item = <div
        //   key={index}
        //   style={{
        //     border: "1px solid",
        //     padding: 10,
        //   }}
        // >
        //   {currentItem} {expander}
        //   {submenu}
        // </div>

        let item;

        if (currentItem) {

          item = <MenuItem
            key={index}
            item={currentItem}
            inEditMode={inEditMode}
          >
            {itemOutput.length ?
              <List component="div" disablePadding>
                {itemOutput}
              </List>
              : null
            }
          </MenuItem>

        }


        // const clone = React.cloneElement(n, { components: [] });
        // const clone = React.cloneElement(n, {});

        // // console.log('SidebarMenuView children clone', clone);

        // const item = <div
        //   key={index}
        //   style={{
        //     border: "1px solid",
        //     padding: 10,
        //   }}
        // >
        //   <clone.type
        //     {...clone.props}
        //     components={[]}
        //   >
        //   </clone.type>
        // </div>

        // output.push(item);

        // const item = <div
        //   key={index}
        //   style={{
        //     border: "1px solid",
        //     padding: 10,
        //   }}
        // >
        //   {n}
        // </div>

        output.push(item);

        return null;
      });
    }

    return <List
      className={classes.root}
      component="nav"
    >
      {output}
    </List>
  }
}


export default withStyles(styles)(props => <SidebarMenuView
  {...props}
/>);