import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';

import MuiPaper from 'material-ui/Paper';

export class Paper extends EditorComponent {

  static Name = 'Paper';

  static defaultProps = {
    ...EditorComponent.defaultProps,
    hide_wrapper_in_default_mode: true,
    // style: {
    //   ...EditorComponent.defaultProps.style,
    //   padding: 10,
    // },
  }


  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        Paper
      </div>
    );
  }


  getRootElement() {

    return super.getRootElement();
  }


  canBeParent(parent) {

    return super.canBeParent(parent);
  }


  canBeChild(child) {

    return super.canBeChild(child);
  }


  renderChildren() {

    // const {
    // } = this.context;

    // const {
    // } = this.getEditorContext();

    const {
      ...other
    } = this.getComponentProps(this);

    return <MuiPaper
      key="Paper"
      {...other}
    >
      {super.renderChildren()}
    </MuiPaper>
  }

}

export default Paper;