import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import { ObjectContext } from '@prisma-cms/front-editor/lib/components/App/components/public/Connectors/Connector/ListView';

import TrueIcon from 'material-ui-icons/Check';
import FalseIcon from 'material-ui-icons/Close';
import Typography from 'material-ui/Typography';

export class Bool extends EditorComponent {

  static Name = 'Bool';

  static defaultProps = {
    ...EditorComponent.defaultProps,
    name: undefined,
  }


  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        Bool
      </div>
    );
  }


  // getRootElement() {

  //   return super.getRootElement();
  // }


  // canBeParent(parent) {

  //   return super.canBeParent(parent);
  // }


  canBeChild(child) {

    // return super.canBeChild(child);
    return false;
  }


  renderChildren() {

    // const {
    // } = this.context;

    const {
      inEditMode,
    } = this.getEditorContext();

    const {
      name,
      // ...other
    } = this.getComponentProps(this);

    // return super.renderChildren();

    return <ObjectContext.Consumer
      key="Bool"
    >
      {objectContext => {

        const {
          object,
        } = objectContext;

        if (!object) {
          return null;
        }

        if (!name) {

          if (inEditMode) {
            return <Typography
              color="error"
            >
              name property is required
            </Typography>
          }
          else {
            return null;
          }
        }

        let output = null;

        const {
          [name]: value,
        } = object;

        if (value) {
          output = <TrueIcon
            color="primary"
          />
        }
        else {
          output = <FalseIcon
            color="error"
          />
        }

        return output;

      }}
    </ObjectContext.Consumer>
  }

}

export default Bool;