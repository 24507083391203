import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withStyles from 'material-ui/styles/withStyles';
import Grid from 'material-ui/Grid';
import Button from 'material-ui/Button';
import TextField from 'material-ui/TextField';
import FormControlLabel from 'material-ui/Form/FormControlLabel';
import Checkbox from 'material-ui/Checkbox';


const styles = {

  root: {
    padding: 8,
  },

};


export class UsersFilterView extends PureComponent {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    queryName: PropTypes.string.isRequired,
    filters: PropTypes.object,
    setFilters: PropTypes.func.isRequired,
    children: PropTypes.array.isRequired,
    onForwardOnlyChange: PropTypes.func.isRequired,
    onChangeFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
  };

  render() {


    const {
      classes,
      queryName,
      filters,
      setFilters,
      children = [],
      onForwardOnlyChange,
      onChangeFilters,
      resetFilters,
    } = this.props;


    if (!queryName) {
      return null;
    }


    const {
      forward_only,
      search,
      // createdAt_gte,
      // createdAt_lte,
    } = filters || {};

    const hasFilters = filters && Object.keys(filters).length ? true : false;


    const forward_only_checked = forward_only ? true : false;

    return <div
      className={[classes.root, "orders-filters"].join(" ")}
    >

      <Grid
        container
        spacing={8}
        alignItems="center"
      >

        {children.reduce((current, next, index) => {

          current.push(<Grid
            key={index}
            item
          >
            {next}
          </Grid>);

          return current;
        }, [])}

        {/* <Grid
          item
        >

          <FormControlLabel
            control={
              <Checkbox
                checked={forward_only_checked}
                onChange={onForwardOnlyChange(setFilters, filters)}
              />
            }
            label="Клиенты с SIM"
          />

        </Grid> */}


        {/* <Grid
          item
          className="smDown__hidden"
        >
          <TextField
            name="createdAt_gte"
            value={createdAt_gte || "дд.мм.гггг"}
            type="date"
            onChange={onChangeFilters(setFilters, filters)}
            label="Дата создания С"
            helperText="Включая указанный день"
          />

        </Grid>


        <Grid
          item
          className="smDown__hidden"
        >
          <TextField
            name="createdAt_lte"
            value={createdAt_lte || "дд.мм.гггг"}
            type="date"
            onChange={onChangeFilters(setFilters, filters)}
            label="Дата создания До"
            helperText="Исключая указанный день"
          />

        </Grid> */}


        <Grid
          item
          xs
        >
          <TextField
            fullWidth
            name="search"
            value={search || ""}
            onChange={onChangeFilters(setFilters, filters)}
            label="Поиск"
            helperText="По номеру, ФИО и емейлу"
          />

        </Grid>

        {hasFilters ?
          <Grid
            item
          >
            <Button
              onClick={resetFilters(setFilters)}
            >
              Сброс
            </Button>
          </Grid>
          :
          null
        }

      </Grid>





    </div>
  }
}


export default withStyles(styles)(UsersFilterView);