import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

// import InboxIcon from 'material-ui-icons/MoveToInbox';
import ExpandLess from 'material-ui-icons/ExpandLess';
import ExpandMore from 'material-ui-icons/ExpandMore';
// import StarBorder from 'material-ui-icons/StarBorder';

// import IconButton from 'material-ui/IconButton';
import { ListItem, ListItemText } from 'material-ui/List';
// import { ListItemIcon } from 'material-ui'; 
import Collapse from 'material-ui/transitions/Collapse';
import withStyles from 'material-ui/styles/withStyles';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  expander: {
    cursor: "pointer",
  },
});

class MenuItem extends PureComponent {

  static propTypes = {
    item: PropTypes.object.isRequired,
    children: PropTypes.object,
    classes: PropTypes.object.isRequired,
    inEditMode: PropTypes.bool.isRequired,
  };

  state = {
    open: false,
  }


  handleOpen = (event) => {

    event.preventDefault();
    event.stopPropagation();

    const {
      open,
    } = this.state;

    this.setState({
      open: !open,
    });
  }

  render() {

    const {
      item,
      children,
      classes,
      inEditMode,
    } = this.props;

    const {
      open,
    } = this.state;

    let expander;

    if (children) {
      if (open) {
        expander = <ExpandLess
          className={classes.expander}
          onClick={this.handleOpen}
        />
      }
      else {
        expander = <ExpandMore
          className={classes.expander}
          onClick={this.handleOpen}
        />
      }
    }


    return <Fragment>

      <ListItem
        button={!inEditMode}
      // onClick={this.handleClick}
      >
        {/* <ListItemIcon>
          <InboxIcon />
        </ListItemIcon> */}
        <ListItemText
          // inset
          primary={item}
        />
        {expander}
      </ListItem>
      <Collapse
        in={this.state.open}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>

    </Fragment>

    // return (
    //   <div
    //     style={{
    //       ...style,
    //       border: "1px solid",
    //       padding: 10,
    //     }}
    //   >
    //     {item} {expander ? <IconButton
    //       onClick={() => {

    //         this.setState({
    //           open: !open,
    //         });

    //       }}
    //     >
    //       <svg
    //         className="MuiSvgIcon-root"
    //         focusable="false"
    //         viewBox="0 0 24 24"
    //         aria-hidden="true"
    //         role="presentation"
    //         style={{
    //           fontSize: "1.5rem",
    //           width: "1em",
    //           height: "1em",
    //         }}
    //       >
    //         {expander}
    //       </svg>
    //     </IconButton>
    //       : null
    //     }

    //     {children && open ? <div>
    //       {children}
    //     </div> : null}
    //   </div>
    // );
  }
}


export default withStyles(styles)(props => <MenuItem
  {...props}
/>);