import React from 'react';

import EditorComponent from '@prisma-cms/front-editor/lib/components/App/components/';
import { EditableObjectContext } from '@prisma-cms/front-editor/lib/components/App/context';
import EditableObject from '@prisma-cms/front-editor/lib/components/App/components/public/form/EditableObject';
import Typography from 'material-ui/Typography';

export class Wysiwig extends EditorComponent {

  static Name = 'Wysiwig';

  static defaultProps = {
    ...EditorComponent.defaultProps,
  }


  renderPanelView(content) {

    const {
      classes,
    } = this.getEditorContext();

    return super.renderPanelView(
      content ||
      <div
        className={classes.panelButton}
      >
        Wysiwig
      </div>
    );
  }


  // getRootElement() {

  //   return super.getRootElement();
  // }


  canBeParent(parent) {

    return super.canBeParent(parent) && this.findInParent(parent, parent => parent instanceof EditableObject);
  }


  canBeChild(child) {

    // return super.canBeChild(child);
    return false;
  }


  renderChildren() {

    // const {
    // } = this.context;

    const {
      inEditMode,
    } = this.getEditorContext();

    const {
      name,
      // ...other
    } = this.getComponentProps(this);

    // return super.renderChildren();

    if (!global.window) {
      return null;
    }


    if (!name) {

      if (inEditMode) {
        return <Typography
          color="error"
        >
          name property is required
        </Typography>
      }
      else {
        return null;
      }
    }


    const CKEditor = require('@ckeditor/ckeditor5-react');
    const ClassicEditor = require('@ckeditor/ckeditor5-build-classic');

    return <EditableObjectContext.Consumer>
      {editableObjectContext => {

        const {
          inEditMode: objectInEditMode,
          getObjectWithMutations,
          updateObject,
        } = editableObjectContext;

        if (!getObjectWithMutations || !name) {
          return null;
        }

        const {
          [name]: value,
        } = getObjectWithMutations();

        return <CKEditor
          ssr={false}
          editor={ClassicEditor}
          data={value || ''}
          disabled={!objectInEditMode}
          onChange={(event, editor) => {
            const data = editor.getData();
            // setFieldValue(name, data);
            // console.log('onChange', data);
            updateObject({
              [name]: data,
            });
          }}
        />
      }}
    </EditableObjectContext.Consumer>
  }

}

export default Wysiwig;