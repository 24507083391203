
import './styles/styles.scss';

import React from 'react';
// import PropTypes from 'prop-types';

import PrismaCmsComponent from "@prisma-cms/component";
import RootConnector from '@prisma-cms/front-editor/lib/components/Root';
import UserPage from './components/pages/Users/User';
// import PdfView from './components/PdfView';
import CreateUserPage from './components/pages/Users/User/Create';
import ChatRooms from './components/pages/ChatRooms';
import NoticesMenu from './components/society/NoticesMenu';
import ChatRoom from './components/pages/ChatRooms/ChatRoom';
import Bool from './components/Bool';
import Image from './components/Image';
import Paper from './components/Paper';
import MainLayout from './components/MainLayout';
import SidebarMenu from './components/SidebarMenu';
import PhonesFilter from './components/PhonesFilter';
import UsersFilter from './components/UsersFilter';
import BotProfilesFilter from './components/BotProfilesFilter';
import NushNotificationPhoneButton from './components/pushNotifications/NushNotificationPhoneButton';
import PushNotificationPhonesConnector from './components/pushNotifications/PushNotificationPhonesConnector';
import K12Phones from './components/K12Phones';
import Wysiwig from './components/Wysiwig';
import BotMailingButtons from './components/BotMailingButtons';
// import DatePicker from './components/DatePicker';



class RootPage extends PrismaCmsComponent {

  render() {

    const {
      CustomComponents = [],
      ...other
    } = this.props;

    const {
      user: currentUser,
    } = this.context;

    const {
      sudo,
    } = currentUser || {};

    return <RootConnector
      CustomComponents={CustomComponents.concat([
        NoticesMenu,
        UserPage,
        CreateUserPage,
        ChatRooms,
        ChatRoom,
        // PdfView,
        Bool,
        Image,
        Paper,
        MainLayout,
        SidebarMenu,
        PhonesFilter,
        UsersFilter,
        BotProfilesFilter,
        NushNotificationPhoneButton,
        PushNotificationPhonesConnector,
        BotMailingButtons,
        K12Phones,
        Wysiwig,
        // DatePicker,
      ])}
      {...other}
      currentProjectOnly={false}
      clonable={sudo === true}
    />
  }

}

export default RootPage;